import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
    private readonly timezone: string;

    public constructor() {
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // set the language
        req = req.clone({
            headers: req.headers.set('timezone', this.timezone),
        });

        return next.handle(req);
    }
}
