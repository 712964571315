export const getIconName = (contentType: string): string => {
    const list = [
        { icon: 'url', types: ['url'] },
        { icon: 'image', types: ['image'] },
        { icon: 'music', types: ['audio'] },
        { icon: 'video', types: ['video'] },
        { icon: 'pdf', types: ['application/pdf'] },
        {
            icon: 'doc',
            types: [
                'application/msword', // cspell:disable-line
                'application/vnd.openxmlformats-officedocument.wordprocessingm', // cspell:disable-line
            ],
        },
        {
            icon: 'ppt',
            types: [
                'application/vnd.ms-powerpoint', // cspell:disable-line
                'application/vnd.openxmlformats-officedocument.presentationml.presentation', // cspell:disable-line
            ],
        },
        {
            icon: 'xls',
            types: [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // cspell:disable-line
            ],
        },
        { icon: 'zip', types: ['application/zip', 'application/vnd.rar'] },
        { icon: 'txt', types: ['text/plain'] },
        { icon: 'mail', types: ['application/vnd.ms-outlook'] },
    ];

    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        const isTheSameType = item.types.some(v => contentType.startsWith(v));
        if (isTheSameType) return item.icon;
    }

    return 'broken';
};
