<div class="flex flex-row">
    <input
        class="flex-grow disabled:!bg-white"
        disabled
        type="text"
        value="{{
            libraryFile?.name || ('translate_no_file_selected' | translate)
        }}"
    />

    <button
        (click)="showCreateLibraryFileDialog()"
        class="btn btn-sm btn-primary ms-2"
        [disabled]="isDisabled"
    >
        <i class="fa-light fa-plus"></i>
    </button>
    <button
        (click)="showLibraryDialog()"
        [disabled]="isDisabled"
        class="btn btn-sm btn-warning ms-2"
    >
        <i class="fa-light fa-paperclip"></i>
    </button>
</div>

<!-- Template for select button -->
<ng-template let-item="item" #selectButtonTemplate>
    <button
        (click)="dismissLibraryFileListModal(); updateFile(item)"
        [disabled]="isDisabled"
        class="btn btn-sm btn-info"
    >
        <i class="fa fa-check"></i>
    </button>
</ng-template>
