import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    ViewChild,
} from '@angular/core';
import * as Chart from 'chart.js';
import { KpiResultPeriodBreakdown } from '@masar/common/models';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-period-breakdown-chart',
    templateUrl: './period-breakdown-chart.component.html',
    styles: [
        `
            :host {
                display: block;
                width: 100%;
                height: 100%;
            }
        `,
    ],
})
export class PeriodBreakdownChartComponent implements AfterViewInit {
    @Input() public breakdown: KpiResultPeriodBreakdown;
    @ViewChild('barChartCanvasRef') private barChartCanvasRef: ElementRef;
    @ViewChild('pieChartCanvasRef') private pieChartCanvasRef: ElementRef;

    public constructor(@Inject(DOCUMENT) private document: Document) {}

    public ngAfterViewInit(): void {
        const style = getComputedStyle(this.document.documentElement);
        const primaryColor = style.getPropertyValue('--primary-500');
        new Chart(this.barChartCanvasRef.nativeElement.getContext('2d'), {
            type: 'bar',
            data: {
                labels: this.breakdown.values.map(x => x.subcategory.name),
                datasets: [
                    {
                        label: this.breakdown.category.name,
                        data: this.breakdown.values.map(x => x.value),
                        borderColor: primaryColor,
                        backgroundColor: primaryColor,
                        barThickness: 30,
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    labels: { render: () => {} }, // eslint-disable-line @typescript-eslint/no-empty-function
                },
            },
        });

        new Chart(this.pieChartCanvasRef.nativeElement, {
            type: 'pie',
            data: {
                labels: this.breakdown.values.map(x => x.subcategory.name),
                datasets: [
                    {
                        label: this.breakdown.category.name,
                        data: this.breakdown.values.map(x => x.value),
                        backgroundColor: this.breakdown.values.map(x =>
                            this.stringToColor(x.subcategory.name)
                        ),
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Chart.js Pie Chart',
                    },
                },
            },
        });
    }

    private stringToColor(str): string {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        const red = ((hash & 0xff) % 150) + 100;
        const green = (((hash >> 8) & 0xff) % 150) + 100;
        const blue = (((hash >> 16) & 0xff) % 150) + 100;

        const color = ((blue << 16) | (green << 8) | red)
            .toString(16)
            .toUpperCase();

        return '#' + '00000'.substring(0, 6 - color.length) + color;
    }
}
