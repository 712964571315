import { Component, Input, OnDestroy } from '@angular/core';
import { Kpi } from '@masar/common/models';
import { takeUntil } from 'rxjs/operators';
import { AppSettingFetcherService } from '@masar/core/services';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-kpi-code-badge',
    templateUrl: './kpi-code-badge.component.html',
})
export class KpiCodeBadgeComponent implements OnDestroy {
    @Input() public kpi: Kpi;
    public kpiPrefix: string = '';

    private unsubscribeAll = new Subject();

    public constructor(kpiSettingService: AppSettingFetcherService) {
        kpiSettingService.get$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ appId }) => (this.kpiPrefix = appId));
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public copyToClipboard(text: string): void {
        window.navigator.clipboard.writeText(text).then();
    }
}
