<div class="grid gap-4 text-sm" *ngIf="items?.length">
    <div
        *ngFor="let item of items; let i = index"
        class="flex flex-col gap-4 rounded-md bg-gray-100 p-4 shadow transition hover:shadow-md"
    >
        <!-- Top -->
        <div class="flex w-full justify-between gap-1">
            <!-- Right -->
            <div class="flex items-center gap-2">
                <!-- Status -->
                <div
                    class="flex items-center gap-2 font-semibold text-gray-500"
                >
                    <i class="fas fa-exchange-alt text-green-500"></i>
                    <span>
                        {{ 'translate_status_changed' | translate }}
                    </span>

                    <!-- From -->
                    <p *ngIf="items[i + 1]">
                        {{ 'translate_from' | translate }}
                        <span class="rounded-md bg-gray-200 px-2 py-1">
                            <ng-container *ngIf="useTranslate">
                                {{
                                    'translate_' + items[i + 1].value
                                        | translate
                                }}
                            </ng-container>

                            <ng-container *ngIf="!useTranslate">
                                {{ items[i + 1].value }}
                            </ng-container>
                        </span>
                    </p>

                    <!-- To -->
                    <p>
                        <span>
                            {{ 'translate_to' | translate }}
                        </span>
                        <span class="rounded-md bg-green-100 px-2 py-1">
                            <ng-container *ngIf="useTranslate">
                                {{ 'translate_' + item.value | translate }}
                            </ng-container>

                            <ng-container *ngIf="!useTranslate">
                                {{ item.value }}
                            </ng-container>
                        </span>
                    </p>
                </div>

                <!-- Time -->
                <span class="text-xs text-gray-400">
                    {{ item.creationTime | date : 'yyyy-MM-dd hh:mm a' }}
                </span>
            </div>

            <!-- Left -->
            <div class="flex flex-col gap-2 text-xs text-gray-400">
                <!-- User -->
                <div class="flex items-center gap-2" *ngIf="item.user">
                    <i class="fas fa-user"></i>
                    <app-user-link [item]="item.user"></app-user-link>
                </div>

                <!-- Department -->
                <div *ngIf="item.department" class="flex items-center gap-2">
                    <i class="fas fa-building"></i>
                    <app-department-link
                        [item]="item.department"
                    ></app-department-link>
                </div>
            </div>
        </div>

        <!-- Bottom -->
        <div
            class="w-full border-t pt-2 text-sm text-gray-500"
            *ngIf="item.note"
        >
            <div [innerHTML]="item.note"></div>
        </div>
    </div>
</div>
