import { Pipe, PipeTransform } from '@angular/core';
import { MiscApiService } from '@masar/core/services';
import { Observable } from 'rxjs';
import { MiscApiEndPoint } from '@masar/common/types';

@Pipe({ name: 'translateItem' })
export class TranslateItemPipe implements PipeTransform {
    public constructor(private miscApiService: MiscApiService) {}

    public transform(
        value: string,
        type: MiscApiEndPoint,
        noCache?: boolean
    ): Observable<string> {
        return this.miscApiService.getItemName(type, value, undefined, noCache);
    }
}
