<app-content
    contentTitle="{{ 'translate_approval_and_suggestions' | translate }}"
>
    <!-- Tools -->
    <ng-container tools>
        <button
            (click)="period.isApproved ? disapprove() : approve()"
            class="btn btn-sm btn-outline-white"
            [disabled]="loadingStatus.approval"
        >
            <app-loading-ring
                *ngIf="loadingStatus.approval"
                class="me-2"
            ></app-loading-ring>
            <span>
                {{
                    (period.isApproved
                        ? 'translate_disapprove'
                        : 'translate_approve'
                    ) | translate
                }}
            </span>
        </button>
    </ng-container>

    <!-- Content -->
    <div content class="flex flex-col items-stretch gap-2 md:flex-row">
        <div class="flex flex-1 flex-col gap-2">
            <label>{{ 'translate_supervisor_notes' | translate }}</label>
            <textarea
                [(ngModel)]="period.supervisorNote"
                name="supervisorNote"
                cols="30"
                rows="10"
            ></textarea>
            <button
                (click)="saveSupervisorNote()"
                class="btn btn-primary"
                [disabled]="loadingStatus.notes"
            >
                <app-loading-ring
                    *ngIf="loadingStatus.notes"
                    class="me-2"
                ></app-loading-ring>
                <span>
                    {{ 'translate_save' | translate }}
                </span>
            </button>
        </div>
        <div class="flex flex-1 flex-col gap-2">
            <label>{{ 'translate_leadership_directives' | translate }}</label>
            <textarea
                [(ngModel)]="period.leadershipDirective"
                name="leadershipDirective"
                cols="30"
                rows="10"
            ></textarea>
            <button
                (click)="saveLeadershipDirective()"
                class="btn btn-primary"
                [disabled]="loadingStatus.directives"
            >
                <app-loading-ring
                    *ngIf="loadingStatus.directives"
                    class="me-2"
                ></app-loading-ring>
                <span>
                    {{ 'translate_save' | translate }}
                </span>
            </button>
        </div>
    </div>
</app-content>
