<app-content-loading [isLoading]="isLoading">
    <app-fieldset
        *ngFor="let category of items$ | async"
        [legend]="'translate_category_' + category.id | translate"
    >
        <div class="grid grid-cols-1 gap-2 md:grid-cols-3">
            <div
                *ngFor="let item of category.permissions"
                class="flex flex-col gap-1 rounded border border-gray-300 bg-white p-3"
            >
                <div>
                    <input
                        type="checkbox"
                        id="{{ item.permission.id }}"
                        [ngModel]="item.checked"
                        (ngModelChange)="onToggle(item, $event)"
                        [disabled]="item.disabled"
                    />
                    <label for="{{ item.permission.id }}">
                        {{ item.permission.name }}
                    </label>
                </div>

                <small class="text-gray-500">
                    {{ item.permission.description }}
                </small>
            </div>
        </div>
    </app-fieldset>
</app-content-loading>
