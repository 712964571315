import { Component, Input } from '@angular/core';
import { Plan } from '@masar/common/models';
import { permissionList } from '@masar/common/constants';

@Component({
    selector: 'app-plan-link',
    templateUrl: './plan-link.component.html',
})
export class PlanLinkComponent {
    @Input() public item: Plan;
    public permissionList = permissionList;
}
