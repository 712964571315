<app-content contentTitle="{{ 'translate_capabilities' | translate }}">
    <!-- Tools -->
    <ng-container
        tools
        *appHasPermissionId="
            type === 'kpi-result'
                ? permissionList.kpiResultEntry
                : permissionList.kpiResultFlow
        "
    >
        <button
            *ngIf="mode !== 'view'"
            (click)="showCreateCapabilityDialog()"
            class="btn btn-sm btn-outline-white"
        >
            <i class="fa-light fa-plus"></i>
            {{ 'translate_add_new' | translate }}
        </button>
    </ng-container>

    <!-- Content -->
    <app-list-loading
        content
        [items]="items"
        class="d-block"
        [loadingClasses]="
            'text-center text-primary h4 d-block font-weight-bold'
        "
        [loadingStyles]="{ fontSize: '1.2rem' }"
        [itemsAreUnavailableClasses]="'text-center text-muted h4 d-block'"
    >
        <div class="table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>{{ 'translate_capability' | translate }}</th>
                        <th>{{ 'translate_description' | translate }}</th>
                        <th>
                            {{ 'translate_type' | translate }}
                        </th>
                        <ng-container
                            *appHasPermissionId="
                                type === 'kpi-result'
                                    ? permissionList.kpiResultEntry
                                    : permissionList.kpiResultFlow
                            "
                        >
                            <th></th>
                        </ng-container>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of items">
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            {{ item.description }}
                        </td>
                        <td>
                            {{ item.type.name }}
                        </td>

                        <ng-container
                            *appHasPermissionId="
                                type === 'kpi-result'
                                    ? permissionList.kpiResultEntry
                                    : permissionList.kpiResultFlow
                            "
                        >
                            <td style="width: 1%; white-space: nowrap">
                                <!-- Edit capability -->
                                <button
                                    *ngIf="mode !== 'view'"
                                    (click)="
                                        showCreateCapabilityDialog(item.id)
                                    "
                                    [appTooltip]="'translate_edit' | translate"
                                    class="btn btn-sm btn-info me-2"
                                >
                                    <i class="fa-light fa-edit"></i>
                                </button>

                                <!-- Link file  -->
                                <button
                                    (click)="showLinkedLibrary(item)"
                                    [appTooltip]="
                                        'translate_attachments' | translate
                                    "
                                    class="btn btn-sm btn-info me-2"
                                >
                                    <i class="fa-light fa-paperclip"></i>
                                </button>

                                <!-- Delete -->
                                <button
                                    *ngIf="mode !== 'view'"
                                    class="btn btn-sm btn-danger"
                                    [disabled]="
                                        currentlyDeleting.includes(item.id)
                                    "
                                    [appTooltip]="
                                        'translate_delete' | translate
                                    "
                                    (confirm)="delete(item)"
                                    [swal]="{
                                        title:
                                            'translate_delete_this_item_question_mark'
                                            | translate,
                                        confirmButtonText:
                                            'translate_yes' | translate,
                                        cancelButtonText:
                                            'translate_cancel' | translate,
                                        showCancelButton: true,
                                        showCloseButton: true
                                    }"
                                >
                                    <i class="fas fa-trash"></i>
                                </button>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </app-list-loading>
</app-content>
