import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    KpiResultPeriod,
    KpiResultPeriodBreakdown,
} from '@masar/common/models';
import { finalize } from 'rxjs/operators';
import { KpiResultBreakdownService } from '../../../kpi-result-breakdown.service';
import { NotificationService } from 'mnm-webapp';

@Component({
    selector: 'app-period-breakdown-update',
    templateUrl: './period-breakdown-update.component.html',
})
export class PeriodBreakdownUpdateComponent implements OnInit {
    @Input() public period: KpiResultPeriod;

    @Input() public categoryId: string;

    @Input() public parameter: 'a' | 'b';

    @Output() public update = new EventEmitter();

    public searchText?: string;

    public item: KpiResultPeriodBreakdown;

    public actualValue: number = null;

    public aggregatedValue: number = null;

    public isSubmitting = false;

    public constructor(
        private kpiResultBreakdownService: KpiResultBreakdownService,
        private notificationService: NotificationService
    ) {}

    public ngOnInit(): void {
        this.actualValue =
            this.parameter === 'a' ? this.period.a : this.period.b;

        this.kpiResultBreakdownService
            .getPeriodBreakdown(this.period.id, this.categoryId, this.parameter)
            .subscribe(item => {
                this.item = item;
                this.recalculateAggregatedValue();
            });
    }

    public submit(): void {
        this.isSubmitting = true;

        const observable = this.kpiResultBreakdownService.updatePeriodBreakdown(
            this.period.id,
            this.categoryId,
            this.parameter,
            this.item.values
        );

        observable
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.update.emit();
            });
    }

    public recalculateAggregatedValue(): void {
        this.aggregatedValue = this.item.values.every(
            x => x.value === null || x.value === undefined
        )
            ? null
            : this.item.values.reduce((a, b) => a + b.value, 0);
    }
}
