import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@masar/env/environment';
import { TableResult } from '@masar/common/misc/table';
import { Capability, Kpi, LibraryFile } from '@masar/common/models';

@Injectable()
export class SharedCapabilityService {
    public constructor(private httpClient: HttpClient) {}

    public list(
        keyword: string,
        tournamentIds: string[],
        pillarIds: string[],
        standardIds: string[],
        pageNumber: number,
        pageSize: number = 20
    ): Observable<TableResult<Capability>> {
        let params = new HttpParams();
        params = params.append('keyword', keyword);

        tournamentIds?.forEach(
            x => (params = params.append('tournamentIds', x))
        );
        pillarIds?.forEach(x => (params = params.append('pillarIds', x)));
        standardIds?.forEach(x => (params = params.append('standardIds', x)));

        params = params.append('pageNumber', `${pageNumber}`);
        params = params.append('pageSize', `${pageSize}`);

        return this.httpClient
            .get<Result<TableResult<Capability>>>(
                environment.apiUrl + '/capability',
                {
                    params,
                }
            )
            .pipe(map(result => result.extra));
    }

    public delete(id: string): Observable<string> {
        return this.httpClient
            .delete<Result>(environment.apiUrl + '/capability/' + id)
            .pipe(map(result => result.messages[0]));
    }

    // Kpi linking methods.
    public listLinkedKpis(
        id: string,
        keyword: string,
        resultFromYear: number,
        resultToYear: number,
        pageNumber: number = 0,
        pageSize: number = 20
    ): Observable<TableResult<Kpi>> {
        return this.httpClient
            .get<Result<TableResult<Kpi>>>(
                `${environment.apiUrl}/capability/kpi/${id}`,
                {
                    params: new HttpParams()
                        .append('keyword', keyword)
                        .append('resultFromYear', `${resultFromYear}`)
                        .append('resultToYear', `${resultToYear}`)
                        .append('pageNumber', `${pageNumber}`)
                        .append('pageSize', `${pageSize}`),
                }
            )
            .pipe(map(res => res.extra));
    }

    // File linking methods.
    public listLinkedLibraryFiles(
        id: string,
        keyword: string,
        pageNumber: number = 0,
        pageSize: number = 20
    ): Observable<TableResult<LibraryFile>> {
        return this.httpClient
            .get<Result<TableResult<LibraryFile>>>(
                `${environment.apiUrl}/capability/library-file/${id}`,
                {
                    params: new HttpParams()
                        .append('keyword', keyword)
                        .append('pageNumber', `${pageNumber}`)
                        .append('pageSize', `${pageSize}`),
                }
            )
            .pipe(map(res => res.extra));
    }
}
