<form
    *ngIf="state"
    novalidate
    autocomplete="off"
    class="grid gap-4"
    [formGroup]="state.group"
>
    <ng-template #sectionTemplate let-field="field">
        <fieldset
            class="mb-4 rounded border border-gray-200 bg-gray-100 p-5 shadow-lg"
        >
            <legend
                class="w-auto gap-2 rounded border border-gray-400 bg-gray-300 py-1 px-2 text-gray-900"
            >
                <span>
                    {{
                        translateLabels
                            ? (asField(field).sectionTitle | translate)
                            : asField(field).sectionTitle
                    }}
                </span>

                <span
                    class="text-red-500"
                    *ngIf="asField(field).isSectionRequired"
                >
                    *
                </span>
            </legend>

            <div class="mb-2 text-gray-600">
                {{ asField(field).sectionNote | translate }}
            </div>

            <div class="grid gap-2 md:gap-4">
                <ng-container *ngFor="let childField of asField(field).fields">
                    <ng-container
                        *ngIf="!asField(childField).hide"
                        [ngTemplateOutlet]="
                            $any(
                                asField(childField).fields
                                    ? rowTemplate
                                    : asField(childField).customField
                                    ? asField(childField).customField
                                    : fieldTemplate
                            )
                        "
                        [ngTemplateOutletContext]="{
                            fields: asField(childField).fields,
                            field: childField
                        }"
                    ></ng-container>
                </ng-container>
            </div>
        </fieldset>
    </ng-template>

    <!-- Row Template -->
    <ng-template #rowTemplate let-fields="fields">
        <div class="items-top flex flex-col gap-2 md:flex-row">
            <ng-container *ngFor="let field of fields">
                <ng-container
                    *ngIf="!asField(field).hide"
                    [ngTemplateOutlet]="
                        $any(
                            asField(field).fields
                                ? rowTemplate
                                : asField(field).customField
                                ? asField(field).customField
                                : fieldTemplate
                        )
                    "
                    [ngTemplateOutletContext]="{
                        fields: asField(field).fields,
                        field: field
                    }"
                ></ng-container>
            </ng-container>
        </div>
    </ng-template>

    <!-- Field Template -->
    <ng-template #fieldTemplate let-field="field">
        <div
            [ngClass]="{
                'self-center': asField(field).type === 'checkbox'
            }"
            [ngStyle]="{
                'flex': asField(field).size ? asField(field).size : 'auto'
            }"
        >
            <label
                *ngIf="
                    asField(field).type !== 'checkbox' && asField(field).label
                "
                class="mb-2 inline-block"
            >
                {{
                    translateLabels
                        ? (asField(field).label | translate)
                        : asField(field).label
                }}
            </label>
            <span
                *ngIf="field?.help"
                class="fa-light fa-info-circle mx-2 cursor-pointer"
                data-toggle="tooltip"
                [title]="field?.help | translate"
                data-placement="top"
            ></span>
            <!-- <span class="text-red-500" *ngIf="asField(field).validators?.length > 0"> -->
            <span
                class="text-red-500"
                *ngIf="isRequired(field) && asField(field).label"
            >
                *
            </span>
            <ng-container
                [ngTemplateOutlet]="
                    $any(
                        asField(field).customInputField
                            ? asField(field).customInputField
                            : ['text', 'password', 'number'].includes(
                                  asField(field).type
                              )
                            ? textInputTemplate
                            : asField(field).type === 'email'
                            ? emailInputTemplate
                            : asField(field).type === 'textarea'
                            ? textareaTemplate
                            : asField(field).type === 'date'
                            ? dateTemplate
                            : asField(field).type === 'select'
                            ? selectTemplate
                            : asField(field).type === 'file'
                            ? fileTemplate
                            : asField(field).type === 'checkbox'
                            ? checkboxTemplate
                            : asField(field).type === 'color'
                            ? colorTemplate
                            : defaultTemplate
                    )
                "
                [ngTemplateOutletContext]="{ field: field }"
            >
            </ng-container>
            <small *ngIf="asField(field).note" class="text-xs text-gray-500">
                {{
                    translateLabels
                        ? (asField(field).note | translate)
                        : asField(field).note
                }}
            </small>
            <ng-container *ngIf="state.isInvalid(asField(field).name)">
                <div class="mt-1 text-xs text-red-500" style="display: block">
                    {{ getErrorMessage(asField(field).name) | async }}
                </div>
            </ng-container>
        </div>
    </ng-template>

    <!-- Text email Input Template -->
    <ng-template #emailInputTemplate let-field="field">
        <input
            type="{{ asField(field).type }}"
            name="{{ asField(field).name }}"
            formControlName="{{ asField(field).name }}"
            [ngClass]="{
                'invalid': state.isInvalid(asField(field).name)
            }"
            class="w-full"
            dir="ltr"
        />
    </ng-template>

    <!-- Text Input Template -->
    <ng-template #textInputTemplate let-field="field">
        <input
            [dir]="
                asField(field).forceRtl
                    ? 'rtl'
                    : asField(field).forceLtr
                    ? 'ltr'
                    : ''
            "
            type="{{ asField(field).type }}"
            name="{{ asField(field).name }}"
            formControlName="{{ asField(field).name }}"
            [ngClass]="{
                'invalid': state.isInvalid(asField(field).name)
            }"
            class="w-full"
        />
    </ng-template>

    <!-- Textarea Template -->
    <ng-template #textareaTemplate let-field="field">
        <textarea
            class="w-full"
            formControlName="{{ asField(field).name }}"
            [ngClass]="{
                'invalid': state.isInvalid(asField(field).name)
            }"
        >
        </textarea>
    </ng-template>

    <!-- Date Template -->
    <ng-template #dateTemplate let-field="field">
        <input
            type="date"
            appFlatpickr
            class="w-full"
            formControlName="{{ asField(field).name }}"
            [config]="{
                enableTime: asField(field).withTime,
                dateFormat: asField(field).withTime ? 'Y-m-d h:i K' : 'Y-m-d',
                enable: asField(field).enable || [],
                disable: asField(field).disable || []
            }"
            [ngClass]="{
                'invalid': state.isInvalid(asField(field).name)
            }"
        />
    </ng-template>

    <!-- Select Template -->
    <ng-template #selectTemplate let-field="field">
        <ng-select
            (open)="asField(field).loader?.loadInitialList()"
            [addTag]="
                asField(field).loader?.canCreateTagsWithBackend()
                    ? asField(field).loader.addTagPromise.bind(
                          asField(field).loader
                      )
                    : asField(field).canAddTags
            "
            [bindLabel]="asField(field).bindLabel"
            [bindValue]="
                asField(field).bindValue ? asField(field).bindValue : null
            "
            [clearable]="!asField(field).notClearable"
            [compareWith]="asField(field).compareWith || defaultCompareWith"
            [formControlName]="asField(field).name"
            [hideSelected]="asField(field).hideSelected"
            [items]="
                (asField(field).loader?.items$ | async) || asField(field).items
            "
            [loading]="
                asField(field).loader?.itemsLoading ||
                asField(field).loader?.addingTags.length > 0
            "
            [minTermLength]="asField(field).minTermLength || 0"
            [multiple]="asField(field).multiple"
            [ngClass]="{
                'invalid': state.isInvalid(asField(field).name)
            }"
            [searchable]="!asField(field).notSearchable"
            [typeahead]="asField(field).loader?.itemInput$"
            [groupBy]="asField(field).groupBy"
            [selectableGroup]="asField(field).selectableGroup"
            [selectableGroupAsModel]="asField(field).selectableGroupAsModel"
            [closeOnSelect]="!asField(field).multiple"
        ></ng-select>
    </ng-template>

    <!-- File Template -->
    <ng-template #fileTemplate let-field="field">
        <div class="custom-file">
            <input
                type="file"
                class="custom-file-input"
                id="{{ asField(field).name }}"
                accept="{{ asField(field).mime || '*/*' }}"
                [ngClass]="{
                    'invalid': state.isInvalid(asField(field).name)
                }"
                (change)="
                    loadFile($event, state.group.controls[asField(field).name])
                "
            />
            <label
                class="custom-file-label"
                for="{{ asField(field).name }}"
                [ngClass]="{
                    'invalid': state.isInvalid(asField(field).name)
                }"
            >
                {{
                    (state.group.controls[asField(field).name].value
                        ? 'translate_file_has_been_selected'
                        : 'translate_select_file'
                    ) | translate
                }}
            </label>
        </div>
    </ng-template>

    <!-- Checkbox Template -->
    <ng-template #checkboxTemplate let-field="field">
        <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="{{ asField(field).name }}"
            formControlName="{{ asField(field).name }}"
            [ngClass]="{ 'is-invalid': state.isInvalid(asField(field).name) }"
        />
        <label class="form-check-label" for="{{ asField(field).name }}">
            {{
                translateLabels
                    ? (asField(field).label | translate)
                    : asField(field).label
            }}
        </label>
    </ng-template>

    <!-- Checkbox Template -->
    <ng-template #colorTemplate let-field="field">
        <div class="flex flex-col gap-2">
            <!-- Label -->

            <input
                formControlName="{{ asField(field).name }}"
                [colorPicker]
                (colorPickerChange)="
                    state.group.controls[asField(field).name].patchValue($event)
                "
                [style.background]="
                    state.group.controls[asField(field).name].value
                "
                cpOutputFormat="hex"
                cpAlphaChannel="disabled"
                readonly
                style="
                    width: 100%;
                    display: block;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    line-height: 1.7rem;
                "
            />
        </div>
    </ng-template>

    <!-- Default Template -->
    <ng-template #defaultTemplate></ng-template>

    <ng-container *ngFor="let field of state.fields">
        <ng-container
            *ngIf="!asField(field).hide"
            [ngTemplateOutlet]="
                $any(
                    asField(field).fields && asField(field).sectionTitle
                        ? sectionTemplate
                        : asField(field).fields
                        ? rowTemplate
                        : asField(field).customField
                        ? asField(field).customField
                        : fieldTemplate
                )
            "
            [ngTemplateOutletContext]="{
                fields: asField(field).fields,
                field: field
            }"
        ></ng-container>
    </ng-container>

    <!-- In case of custom fields they need to be inside the form group -->
    <ng-content></ng-content>
</form>
