import { Component, Input } from '@angular/core';
import { TableOrderButton } from '@masar/common/types';
import { TableController } from '@masar/common/misc/table';

@Component({
    selector: 'app-table-order-column',
    templateUrl: './table-order-column.component.html',
})
export class TableOrderColumnComponent {
    @Input() public label: string;

    @Input() public orderByColumnName: string;

    @Input() public tableController: TableController<unknown>;

    public onChange(direction: TableOrderButton[string]): void {
        this.tableController.filter.orderBy[this.orderByColumnName] = direction;
        this.tableController.filter$.next(true);
    }
}
