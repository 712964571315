import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from 'mnm-webapp';
import { KpiResultPeriod } from '@masar/common/models';
import { finalize } from 'rxjs/operators';
import { KpiResultPeriodService } from '../../../kpi-result-period.service';

@Component({
    selector: 'app-approval',
    templateUrl: './approval.component.html',
})
export class ApprovalComponent {
    @Input() public period: KpiResultPeriod;
    @Output() public approved = new EventEmitter<any>();
    @Output() public disapproved = new EventEmitter<any>();

    public loadingStatus = {
        approval: false,
        notes: false,
        directives: false,
    };

    public constructor(
        private kpiResultPeriodService: KpiResultPeriodService,
        private notificationService: NotificationService
    ) {}

    public approve(): void {
        if (this.loadingStatus.approval) {
            return;
        }
        this.loadingStatus.approval = true;
        this.kpiResultPeriodService
            .approve(this.period.id)
            .pipe(finalize(() => (this.loadingStatus.approval = false)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.period.isApproved = true;
                this.approved.emit();
            });
    }

    public disapprove(): void {
        if (this.loadingStatus.approval) {
            return;
        }
        this.loadingStatus.approval = true;
        this.kpiResultPeriodService
            .disapprove(this.period.id)
            .pipe(finalize(() => (this.loadingStatus.approval = false)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.period.isApproved = false;
                this.disapproved.emit();
            });
    }

    public saveSupervisorNote(): void {
        if (this.loadingStatus.notes) {
            return;
        }
        this.loadingStatus.notes = true;
        this.kpiResultPeriodService
            .supervisorNote(this.period.id, this.period.supervisorNote)
            .pipe(finalize(() => (this.loadingStatus.notes = false)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
            });
    }

    public saveLeadershipDirective(): void {
        if (this.loadingStatus.directives) {
            return;
        }
        this.loadingStatus.directives = true;
        this.kpiResultPeriodService
            .leadershipDirective(
                this.period.id,
                this.period.leadershipDirective
            )
            .pipe(finalize(() => (this.loadingStatus.directives = false)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
            });
    }
}
