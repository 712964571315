import { Component, Input } from '@angular/core';
import { StrategicGoal } from '@masar/common/models';
import { permissionList } from '@masar/common/constants';

@Component({
    selector: 'app-strategic-goal-link',
    templateUrl: './strategic-goal-link.component.html',
})
export class StrategicGoalLinkComponent {
    @Input() public item: StrategicGoal;
    public permissionList = permissionList;
}
