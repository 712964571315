import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const units = [
    'translate_bytes',
    'translate_KB',
    'translate_MB',
    'translate_GB',
    'translate_TB',
] as const;

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
    public constructor(private readonly translateService: TranslateService) {}

    public transform(value?: number): string {
        if (!value) return '';

        for (let i = 0; i < units.length; i++) {
            if (value <= 1024 ** (i + 1)) {
                const translatedUnit = this.translateService.instant(units[i]);
                return `${(value / 1024 ** i).toFixed(2)} ${translatedUnit}`;
            }
        }

        const translatedUnit = this.translateService.instant(
            units[units.length - 1]
        );
        return `${(value / 1024 ** 6).toFixed(2)} ${translatedUnit}`;
    }
}
