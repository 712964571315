import { Component, EventEmitter, Input, Output } from '@angular/core';
import { permissionList } from '@masar/common/constants';
import { KpiResultDataEntryResponseTransfer } from '@masar/common/models';

interface ImprovementProcedures {
    value: string;
    percentage: number;
    expectedCompletionDate: Date;
}

@Component({ templateUrl: './improvement-procedures-dialog.component.html' })
export class ImprovementProceduresDialogComponent {
    @Input() public type: 'kpi-result' | 'data-entry-response';

    @Input() public improvementProcedures: ImprovementProcedures;

    // Fields Only For Kpi Result
    @Input() public mode: 'new' | 'edit';
    @Input() public isApproved = true;

    // Fields Only For Data Entry Response
    @Input()
    public currentTransferAssignee: KpiResultDataEntryResponseTransfer['assignee'];
    @Input() public readOnly: boolean = false;

    @Output() public itemUpdated = new EventEmitter<ImprovementProcedures>();

    public readonly permissionList = permissionList;

    public submit(): void {
        this.itemUpdated.emit(this.improvementProcedures);
    }
}
