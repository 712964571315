<div class="flex flex-col gap-4">
    <!-- Selected evaluation -->
    <div
        class="flex flex-col gap-1 rounded border bg-gray-100 p-4"
        *ngIf="evaluations?.length > 1"
    >
        <label>{{ 'translate_select_the_evaluation' | translate }}</label>
        <ng-select
            [items]="evaluations"
            [ngModel]="selectedEvaluation"
            [clearable]="false"
            [searchable]="false"
            (ngModelChange)="updateSelectedEvaluation($event)"
            bindLabel="name"
        ></ng-select>
    </div>

    <!-- Overall -->
    <app-content
        contentTitle="{{ 'translate_overall_statistics' | translate }}"
    >
        <app-content-loading content [isLoading]="!overallStatistics">
            <div
                *ngIf="overallStatistics"
                [@flippingCardGrid]="overallStatistics"
                class="grid grid-cols-4 gap-2"
            >
                <!-- Overall count -->
                <ng-container
                    [ngTemplateOutlet]="itemTemplate"
                    [ngTemplateOutletContext]="{
                        title: 'translate_overall_count',
                        value: overallStatistics?.count,
                        bg: 'bg-blue-400'
                    }"
                ></ng-container>

                <!-- Evaluated count -->
                <ng-container
                    [ngTemplateOutlet]="itemTemplate"
                    [ngTemplateOutletContext]="{
                        title: 'translate_evaluated_count',
                        value: overallStatistics?.evaluatedCount,
                        bg: 'bg-green-400'
                    }"
                ></ng-container>

                <!-- Note evaluated count -->
                <ng-container
                    [ngTemplateOutlet]="itemTemplate"
                    [ngTemplateOutletContext]="{
                        title: 'translate_not_evaluated_count',
                        value: overallStatistics?.notEvaluatedCount,
                        bg: 'bg-red-400'
                    }"
                ></ng-container>

                <!-- Average -->
                <ng-container
                    [ngTemplateOutlet]="itemTemplate"
                    [ngTemplateOutletContext]="{
                        title: 'translate_average_evaluation',
                        value:
                            overallStatistics?.average !== null
                                ? (overallStatistics?.average * 100 | round) +
                                  '%'
                                : 'N/A',
                        bg: 'bg-yellow-400'
                    }"
                ></ng-container>

                <!-- Template -->
                <ng-template
                    #itemTemplate
                    let-title="title"
                    let-value="value"
                    let-bg="bg"
                >
                    <div
                        [@flippingCard]
                        class="flex flex-col items-center justify-center gap-4 rounded bg-gray-400 p-4"
                        [ngClass]="bg"
                    >
                        <span class="text-lg">{{ title | translate }}</span>
                        <span class="text-3xl font-bold">{{ value }}</span>
                    </div>
                </ng-template>
            </div>
        </app-content-loading>
    </app-content>

    <!-- Score bands -->
    <app-content
        contentTitle="{{ 'translate_score_bands_statistics' | translate }}"
    >
        <app-list-loading content [items]="scoreBandStatistics">
            <div
                [@verticalFlippingCardGrid]="scoreBandStatistics?.length"
                class="flex flex-row gap-2"
            >
                <div
                    [@verticalFlippingCard]
                    *ngFor="let item of scoreBandStatistics"
                    class="flex flex-1 flex-col items-center justify-center gap-3 rounded bg-gray-400 p-4"
                    [style.background-color]="item.color"
                >
                    <span class="text-lg font-bold">{{ item.name }}</span>

                    <div class="flex flex-row gap-5">
                        <!-- Count -->
                        <ng-container
                            [ngTemplateOutlet]="detailsTemplate"
                            [ngTemplateOutletContext]="{
                                title: 'translate_count',
                                value: item.count
                            }"
                        ></ng-container>

                        <!-- Average -->
                        <ng-container
                            [ngTemplateOutlet]="detailsTemplate"
                            [ngTemplateOutletContext]="{
                                title: 'translate_average',
                                value:
                                    item.average !== null
                                        ? (item.average * 100 | round) + '%'
                                        : 'N/A'
                            }"
                        ></ng-container>

                        <!-- Template -->
                        <ng-template
                            #detailsTemplate
                            let-title="title"
                            let-value="value"
                        >
                            <div class="flex flex-col items-center">
                                <span class="text-sm">
                                    {{ title | translate }}
                                </span>
                                <span class="text-3xl font-bold">
                                    {{ value }}
                                </span>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </app-list-loading>
    </app-content>
</div>
