import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'callFunction' })
export class CallFunctionPipe implements PipeTransform {
    public transform<T>(
        _function: (...args: unknown[]) => T,
        ...args: unknown[]
    ): T {
        return _function(...args);
    }
}
