<app-tab-list class="mb-5" *ngIf="periods.length > 1; else firstPeriodTemplate">
    <app-tab
        *ngFor="let item of periods"
        [tabTitle]="
            ((measurementCycle === 'month'
                ? 'translate_month'
                : measurementCycle === 'quarter'
                ? 'translate_quarter'
                : 'translate_year_half'
            ) | translate) +
            ' ' +
            (item.period + 1)
        "
    >
        <ng-container
            [ngTemplateOutlet]="contentTemplate"
            [ngTemplateOutletContext]="{ period: item }"
        ></ng-container>
    </app-tab>
</app-tab-list>

<ng-template #firstPeriodTemplate>
    <ng-container
        [ngTemplateOutlet]="contentTemplate"
        [ngTemplateOutletContext]="{ period: periods[0] }"
    ></ng-container>
</ng-template>
