import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SafeModeService {
    private static shortcut = 'msr';
    private static monitorPeriodInMs = 5000;

    private isSafeModeEnabled = false;
    private userInput = '';

    public constructor(@Inject(DOCUMENT) private document: Document) {}

    public monitorHotKey(): void {
        const callback = (event: KeyboardEvent): void => {
            this.userInput += event.key;
            if (this.userInput.includes(SafeModeService.shortcut)) {
                this.userInput = '';
                this.isSafeModeEnabled = true;
            }
        };

        this.document.body.addEventListener('keyup', callback);

        setTimeout(() => {
            this.document.body.removeEventListener('keyup', callback);
        }, SafeModeService.monitorPeriodInMs);
    }

    public get isEnabled(): boolean {
        return this.isSafeModeEnabled;
    }
}
