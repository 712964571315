import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styles: [':host { display: block}'],
})
export class ContentComponent {
    @Input() public contentTitle: string = '';

    @Input() public noPadding = false;
}
