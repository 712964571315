import {
    Component,
    EventEmitter,
    Input,
    NgModuleRef,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { KpiResultBreakdownService } from '../../../kpi-result-breakdown.service';
import {
    KpiResultPeriod,
    KpiResultPeriodBreakdown,
} from '@masar/common/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcasterService, ModalService } from 'mnm-webapp';
import { TranslateService } from '@ngx-translate/core';
import { PeriodBreakdownUpdateComponent } from '../period-breakdown-update/period-breakdown-update.component';
import { permissionList } from '@masar/common/constants';
import { PeriodBreakdownChartComponent } from '@masar/pages/kpi/update-result/components';

@Component({
    selector: 'app-period-breakdown',
    templateUrl: './period-breakdown.component.html',
})
export class PeriodBreakdownComponent implements OnInit, OnDestroy {
    @Input() public period: KpiResultPeriod;

    @Output() public update = new EventEmitter<void>();

    public items: KpiResultPeriodBreakdown[];
    public currentlyToggled = new Set<string>();

    public permissionList = permissionList;

    private unsubscribeAll = new Subject();

    public constructor(
        private kpiResultBreakdownService: KpiResultBreakdownService,
        private modalService: ModalService,
        private moduleRef: NgModuleRef<any>,
        private translateService: TranslateService,
        private broadcasterService: BroadcasterService
    ) {}

    public ngOnInit(): void {
        this.reload();

        this.broadcasterService
            .on('kpi-result-breakdown-update')
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.update.emit();
                this.reload();
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public async showUpdateDialog(
        categoryId: string,
        parameter: 'a' | 'b'
    ): Promise<void> {
        const subject = new Subject();

        const component = await this.modalService.show(
            PeriodBreakdownUpdateComponent,
            {
                title: this.translateService.instant(
                    'translate_update_breakdown'
                ),
                beforeInit: c => {
                    c.period = this.period;
                    c.categoryId = categoryId;
                    c.parameter = parameter;
                },
                moduleRef: this.moduleRef,
                onDismiss: () => {
                    subject.next();
                    subject.complete();
                },
            }
        );

        component.update.pipe(takeUntil(subject)).subscribe(() => {
            this.modalService.dismiss(component);
            this.update.emit();
            this.reload();
        });
    }

    public toggle(item: KpiResultPeriodBreakdown): void {
        const id = item.parameter + item.category.id;
        if (this.currentlyToggled.has(id)) {
            this.currentlyToggled.delete(id);
            return;
        }
        this.currentlyToggled.add(id);
    }

    public async showChart(item: KpiResultPeriodBreakdown): Promise<void> {
        await this.modalService.show(PeriodBreakdownChartComponent, {
            title: this.translateService.instant('translate_chart'),
            beforeInit: c => {
                c.breakdown = item;
            },
            moduleRef: this.moduleRef,
            size: {
                width: '100%',
            },
        });
    }

    private reload(): void {
        this.kpiResultBreakdownService
            .listPeriodBreakdown(this.period.id)
            .subscribe(items => {
                this.items = items;
            });
    }
}
