import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result, miscFunctions } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@masar/env/environment';
import { KpiResultAttachment } from '@masar/common/models';

@Injectable()
export class KpiResultDataEntryAttachmentService {
    public constructor(private httpClient: HttpClient) {}

    // Attachment methods.
    public listAttachments(
        id: string,
        period?: number
    ): Observable<KpiResultAttachment[]> {
        let params = new HttpParams();

        if (period !== undefined) params = params.set('period', `${period}`);

        return this.httpClient
            .get<Result<KpiResultAttachment[]>>(
                `${environment.apiUrl}/kpi-result-data-entry-response/attachment/${id}`,
                { params }
            )
            .pipe(map(res => res.extra));
    }

    public getAttachment(
        _: string,
        __: boolean = false
    ): Observable<KpiResultAttachment> {
        return null;
    }

    public createAttachment(
        attachment: KpiResultAttachment
    ): Observable<KpiResultAttachment> {
        return this.httpClient
            .post<Result<KpiResultAttachment>>(
                `${environment.apiUrl}/kpi-result-data-entry-response/attachment`,
                miscFunctions.objectToURLParams({
                    attachment: JSON.stringify(attachment),
                })
            )
            .pipe(map(res => res.extra));
    }

    public updateAttachment(
        _: KpiResultAttachment
    ): Observable<KpiResultAttachment> {
        return null;
    }

    public deleteAttachment(attachmentId: string): Observable<string> {
        return this.httpClient
            .delete<Result<any>>(
                `${environment.apiUrl}/kpi-result-data-entry-response/attachment?attachmentId=${attachmentId}`
            )
            .pipe(map(res => res.messages[0]));
    }

    public downloadAttachment(attachmentId: string): Observable<Blob> {
        return this.httpClient
            .get(
                `${environment.apiUrl}/kpi-result-data-entry-response/attachment/download?attachmentId=${attachmentId}`,
                { responseType: 'blob' }
            )
            .pipe(map(res => res));
    }
}
