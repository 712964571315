import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Result } from 'mnm-webapp';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '@masar/env/environment';
import { messageTypeList } from '@masar/common/misc/message-type-list';
import { SignalrService } from './signalr.service';

@Injectable()
export class NotificationUnreadCountService implements OnDestroy {
    private subject = new BehaviorSubject<number>(0);
    private unsubscribeAll = new Subject();

    public constructor(
        private httpClient: HttpClient,
        signalrService: SignalrService
    ) {
        // Get the initial state.
        this.update();

        // Setup a link to receive any further
        // updates regarding the count.
        signalrService
            .messages()
            .pipe(
                takeUntil(this.unsubscribeAll),
                filter(x => x.type === messageTypeList.notificationCountUpdate)
            )
            .subscribe(() => this.update());
    }

    public get count$(): Observable<number> {
        return this.subject.asObservable();
    }

    public get count(): number {
        return this.subject.value;
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();

        this.subject.complete();
    }

    private update(): void {
        this.httpClient
            .get<Result<{ count: number }>>(
                `${environment.apiUrl}/notification/user/unread-count`
            )
            .subscribe(res => this.subject.next(res.extra.count));
    }
}
