import {
    trigger,
    transition,
    query,
    stagger,
    animateChild,
    style,
    animate,
} from '@angular/animations';

export const flippingCard = [
    trigger('flippingCardGrid', [
        transition('* => *', [
            query('@flippingCard', stagger('50ms', animateChild()), {
                optional: true,
            }),
        ]),
    ]),

    trigger('flippingCard', [
        transition(':enter', [
            style({
                transform: 'perspective(30cm) rotateX(-75deg)',
                opacity: 0.0,
            }),
            animate(
                '500ms cubic-bezier(.17,.67,.15,1.57)',
                style({
                    transform: 'perspective(30cm) rotateX(0deg)',
                    opacity: 1.0,
                })
            ),
        ]),
    ]),
];
