import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
} from '@angular/core';

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective implements OnDestroy {
    @Input() public appTooltip = ''; // The text for the tooltip to display
    @Input() public delay? = 0; // Optional delay input, in ms

    private myPopup?: HTMLDivElement;
    private timer?: NodeJS.Timeout;

    public constructor(private el: ElementRef) {}

    @HostListener('mouseenter') public onMouseEnter(): void {
        this.timer = setTimeout(() => {
            let x =
                this.el.nativeElement.getBoundingClientRect().left +
                this.el.nativeElement.offsetWidth / 2; // Get the middle of the element
            let y =
                this.el.nativeElement.getBoundingClientRect().bottom -
                this.el.nativeElement.offsetHeight -
                6; // Get the bottom of the element, plus a little extra
            this.createTooltipPopup(x, y);
        }, this.delay);
    }

    @HostListener('mouseleave') public onMouseLeave(): void {
        if (this.timer) clearTimeout(this.timer);
        if (this.myPopup) {
            this.myPopup.remove();
        }
    }

    public ngOnDestroy(): void {
        if (this.myPopup) {
            this.myPopup.remove();
        }
    }

    private createTooltipPopup(x: number, y: number): void {
        if (!this.appTooltip) return;
        const popup = document.createElement('div');
        const arrow = document.createElement('span');
        popup.innerHTML = this.appTooltip;
        popup.setAttribute('class', 'tooltip-container');
        popup.style.top = (y - 30).toString() + 'px';
        popup.style.left = x.toString() + 'px';
        popup.appendChild(arrow);
        document.body.appendChild(popup);
        this.myPopup = popup;

        setTimeout(() => {
            if (this.myPopup) this.myPopup.remove();
        }, 5000); // Remove tooltip after 5 seconds
    }
}
