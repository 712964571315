import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@masar/env/environment';
import { TableResult } from '@masar/common/misc/table';
import { LibraryFile, Resource } from '@masar/common/models';

@Injectable()
export class SharedLibraryFileService {
    public constructor(private httpClient: HttpClient) {}

    public list(
        keyword: string,
        teamIds: string[],
        tagIds: string[],
        onlyMyFiles: boolean = false,
        pageNumber: number = 0,
        pageSize: number = 20,
        ownerIds: string[],
        contentTypes: string[],
        orderBy: string
    ): Observable<TableResult<LibraryFile>> {
        let params = new HttpParams();
        params = params.append('keyword', keyword);
        teamIds.forEach(item => (params = params.append('teamIds', item)));
        tagIds.forEach(item => (params = params.append('tagIds', item)));
        params = params.append('onlyMyFiles', `${onlyMyFiles}`);
        params = params.append('pageNumber', `${pageNumber}`);
        params = params.append('pageSize', `${pageSize}`);
        params = params.append('orderBy', `${orderBy}`);

        ownerIds?.forEach(item => (params = params.append('ownerIds', item)));

        contentTypes?.forEach(
            item => (params = params.append('contentTypes', item))
        );

        return this.httpClient
            .get<Result<TableResult<LibraryFile>>>(
                environment.apiUrl + '/library-file',
                { params }
            )
            .pipe(map(result => result.extra));
    }

    public delete(id: string): Observable<string> {
        return this.httpClient
            .delete<Result>(environment.apiUrl + '/library-file/' + id)
            .pipe(map(result => result.messages[0]));
    }

    public getLinkedResources(id: string): Observable<Resource[]> {
        return this.httpClient
            .get<Result<Resource[]>>(
                environment.apiUrl + '/library-file/linked-resource/' + id
            )
            .pipe(map(result => result.extra));
    }
}
