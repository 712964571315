import {
    animate,
    animateChild,
    query,
    stagger,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const verticalFlippingCard = [
    trigger('verticalFlippingCardGrid', [
        transition('* => *', [
            query('@verticalFlippingCard', stagger('50ms', animateChild()), {
                optional: true,
            }),
        ]),
    ]),

    trigger('verticalFlippingCard', [
        transition(':enter', [
            style({
                transform: 'perspective(30cm) rotateY(-45deg)',
                opacity: 0.0,
            }),
            animate(
                '500ms cubic-bezier(.17,.67,.15,1.57)',
                style({
                    transform: 'perspective(30cm) rotateY(0deg)',
                    opacity: 1.0,
                })
            ),
        ]),
    ]),
];
