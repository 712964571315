import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-edit-button',
    template: `
        <button
            class="btn btn-sm btn-info"
            [appTooltip]="'translate_edit' | translate"
            (click)="onClick()"
            [disabled]="isDisabled"
        >
            <i class="fas fa-edit"></i>
        </button>
    `,
})
export class EditButtonComponent {
    @Input() public link?: string[];

    @Input() public isDisabled?: boolean;

    @Output() public edit = new EventEmitter<void>();

    public constructor(private readonly router: Router) {}

    public onClick(): void {
        if (this.link?.length) this.router.navigate(this.link).then();
        else this.edit.emit();
    }
}
