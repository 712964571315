import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@masar/env/environment';
import { TableResult } from '@masar/common/misc/table';
import { Innovation } from '@masar/common/models';

@Injectable()
export class SharedInnovationService {
    public constructor(private httpClient: HttpClient) {}

    public list(
        keyword: string,
        employeeNumber: string,
        suggestionNumber: string,
        title: string,
        year: number,
        pageNumber: number,
        pageSize: number = 20
    ): Observable<TableResult<Innovation>> {
        let params = new HttpParams();

        params = params.append('keyword', keyword);
        params = params.append('employeeNumber', employeeNumber);
        params = params.append('suggestionNumber', suggestionNumber);
        params = params.append('title', title);
        params = params.append('year', `${year}`);

        params = params.append('pageNumber', `${pageNumber}`);
        params = params.append('pageSize', `${pageSize}`);

        return this.httpClient
            .get<Result<TableResult<Innovation>>>(
                environment.apiUrl + '/innovation',
                { params }
            )
            .pipe(map(result => result.extra));
    }

    public delete(id: string): Observable<string> {
        return this.httpClient
            .delete<Result<any>>(environment.apiUrl + '/innovation/' + id)
            .pipe(map(result => result.messages[0]));
    }
}
