import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Item } from '@masar/common/models';

@Injectable()
export class ItemTreeCacheService {
    private cache: { parent: Item; children: Item[] }[] = [];

    private parentLoaderObservables: Record<
        string,
        Observable<{
            parent: Item;
            children: Item[];
        }>
    > = {};

    private childrenFetcher: (parentId: string) => Observable<Item[]>;
    private parentFetcher: (
        childId: string
    ) => Observable<{ parent: Item; children: Item[] }>;

    private childrenLoaderObservables: Record<string, Observable<Item[]>> = {};

    public setChildrenFetcher(
        fetcher: (parentId: string) => Observable<Item[]>
    ): void {
        this.childrenFetcher = fetcher;
    }

    public setParentFetcher(
        fetcher: (
            childId: string
        ) => Observable<{ parent: Item; children: Item[] }>
    ): void {
        this.parentFetcher = fetcher;
    }

    public getItemParent(
        item: Item
    ): Observable<{ parent: Item; children: Item[] }> {
        if (!this.parentFetcher) {
            return of<{ parent: Item; children: Item[] }>(null);
        }

        const data = this.cache.find(x =>
            x.children.some(y => y.id === item.id)
        );

        if (data) {
            return of(data);
        }

        if (!this.parentLoaderObservables[item.id]) {
            this.parentLoaderObservables[item.id] = this.parentFetcher(
                item.id
            ).pipe(
                map(data => {
                    // Cache parent.
                    this.storeInCache(data.parent, data.children);

                    return data;
                }),
                finalize(() => (this.parentLoaderObservables[item.id] = null))
            );
        }

        return this.parentLoaderObservables[item.id];
    }

    public getItemChildren(item: Item): Observable<Item[]> {
        // if(!this.childrenFetcher) {
        //     return of([])
        // }

        const parentItemId = item === null ? null : item.id;

        const children = this.cache.find(
            x =>
                (parentItemId === null && x.parent === null) || // in case the root items were requested.
                x.parent?.id === parentItemId
        )?.children;

        if (children) {
            return of(children);
        }

        if (!this.childrenLoaderObservables[parentItemId]) {
            this.childrenLoaderObservables[parentItemId] = this.childrenFetcher(
                parentItemId
            ).pipe(
                map(items => {
                    this.storeInCache(item, items);
                    return items;
                }),
                finalize(
                    () => (this.childrenLoaderObservables[parentItemId] = null)
                )
            );
        }

        return this.childrenLoaderObservables[parentItemId];
    }

    private storeInCache(parent: Item, children: Item[]): void {
        // Cache children.
        this.cache.push({ parent, children });
    }
}
