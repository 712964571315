import { MnmFormField } from '@masar/shared/components';
import { Validators } from '@angular/forms';

export const fields: () => MnmFormField[] = () => [
    {
        name: 'id',
        hide: true,
    },
    {
        name: 'nameAr',
        type: 'text',
        label: 'translate_name_in_arabic',
        validators: [
            Validators.required,
            Validators.maxLength(256),
            Validators.minLength(3),
        ],
    },

    {
        name: 'nameEn',
        type: 'text',
        label: 'translate_name_in_english',
        validators: [Validators.maxLength(256), Validators.minLength(3)],
    },

    {
        name: 'type',
        type: 'select',
        label: 'translate_type',
        bindLabel: 'name',
        validators: [Validators.required],
    },

    {
        name: 'descriptionAr',
        type: 'textarea',
        label: 'translate_description_in_arabic',
        validators: [Validators.minLength(3)],
    },

    {
        name: 'descriptionEn',
        type: 'textarea',
        label: 'translate_description_in_english',
        validators: [Validators.minLength(3)],
    },
];
