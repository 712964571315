<div class="flex flex-row flex-wrap items-center justify-center gap-2 py-2">
    <ul class="flex flex-grow flex-row gap-2">
        <!-- Previous button -->
        <li>
            <a
                class="rounded px-2 py-1 no-underline hover:no-underline"
                [ngClass]="{
                    'bg-gray-200 text-gray-700 hover:text-gray-900':
                        tableController.filter.pageNumber !== 0,
                    'bg-gray-100 text-gray-400':
                        tableController.filter.pageNumber === 0
                }"
                (click)="
                    changePageNumber(tableController.filter.pageNumber - 1)
                "
                href="#"
                onclick="return false;"
            >
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>

        <!-- Number button -->
        <li *ngFor="let item of getShownPageNumbers()">
            <a
                class="rounded px-2 py-1 no-underline hover:no-underline"
                [ngClass]="{
                    'bg-primary text-white hover:text-white':
                        item === tableController.filter.pageNumber,
                    'bg-gray-200 text-gray-700 hover:text-gray-900':
                        item !== tableController.filter.pageNumber
                }"
                (click)="changePageNumber(item)"
                href="#"
                onclick="return false;"
                >{{ item + 1 }}</a
            >
        </li>

        <!-- Next button -->
        <li>
            <a
                class="rounded bg-gray-200 px-2 py-1 no-underline hover:no-underline"
                [ngClass]="{
                    'bg-gray-200 text-gray-700 hover:text-gray-900':
                        tableController.filter.pageNumber !==
                        tableController.totalPages() - 1,
                    'bg-gray-100 text-gray-400':
                        tableController.filter.pageNumber ===
                        tableController.totalPages() - 1
                }"
                (click)="
                    changePageNumber(tableController.filter.pageNumber + 1)
                "
                href="#"
                onclick="return false;"
            >
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
    </ul>

    <div class="flex flex-row items-center gap-2">
        <small class="font-bold">
            <ng-container
                *ngIf="tableController.count === tableController.filteredCount"
            >
                {{
                    'total_results_count'
                        | translate : { count: tableController.count }
                }}
            </ng-container>

            <ng-container
                *ngIf="tableController.count !== tableController.filteredCount"
            >
                {{
                    'showing_filteredCount_results_from_count_total_results'
                        | translate
                            : {
                                  filteredCount: tableController.filteredCount,
                                  count: tableController.count
                              }
                }}
            </ng-container>
        </small>

        <ng-select
            [items]="pageSizes"
            [searchable]="false"
            [clearable]="false"
            [ngModel]="tableController.filter.pageSize"
            (ngModelChange)="changePageSize($event)"
        >
        </ng-select>
    </div>
</div>
