import { Component, Input, OnInit } from '@angular/core';
import { getIconName } from '@masar/common/utils';

@Component({
    selector: 'app-file-icon',
    template: `<div
        class="mx-auto"
        style="width: 50px; height: 50px; background: no-repeat center; background-size: cover"
        [ngStyle]="{
            'background-image': 'url(/assets/img/file-icon/' + icon + '.png)'
        }"
    ></div>`,
})
export class FileIconComponent implements OnInit {
    @Input() public contentType?: string;

    @Input() public icon?: string;

    public ngOnInit(): void {
        if (this.contentType) this.icon = getIconName(this.contentType);
    }
}
