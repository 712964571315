<div class="rounded bg-white shadow">
    <!-- Title -->
    <div class="flex flex-row items-center rounded-t bg-secondary p-3 px-4">
        <span class="flex-grow font-bold leading-[38px] text-white">
            {{ contentTitle || '&#8203;' }}
        </span>
        <ng-content select="[tools]"></ng-content>
    </div>

    <!-- Content -->
    <div [class.p-4]="!noPadding">
        <ng-content select="[content]"></ng-content>
    </div>
</div>
