import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { guardProviders } from './guards/guard-providers';
import { interceptorProviders } from './interceptors/interceptor-providers';
import { serviceProviders } from './services/service-providers';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from '@masar/shared/shared.module';
import { MnmWebappModule } from 'mnm-webapp';
import { MasarModule } from '@masar/features/masar/masar.module';
import { TranslationModule } from '@ng-omar/translation';
import { EasterModule } from '@masar/features/easter/easter.module';
import { translationConfig, mnmWebAppConfig } from '@masar/config';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import { EvaluateModule } from '@masar/features/evaluate/evaluate.module';

registerLocaleData(localeAr, 'ar');

@NgModule({
    imports: [
        TranslateModule,
        RouterModule,
        SweetAlert2Module.forRoot(),
        SharedModule.forRoot(),
        MnmWebappModule.forRoot(mnmWebAppConfig),
        MasarModule.forRoot(),
        TranslateModule.forRoot(),
        TranslationModule.forRoot(translationConfig),
        EasterModule.forRoot(),
        EvaluateModule.forRoot(),
    ],
    providers: [
        { provide: LOCALE_ID, useValue: localStorage.getItem('lang') || 'ar' },
        guardProviders,
        interceptorProviders,
        serviceProviders,
    ],
})
export class CoreModule {}
