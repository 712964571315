import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { OrganizationOrigin } from '@masar/common/enums';
import { isOrigin } from '@masar/common/utils/is-origin.util';

@Directive({ selector: '[appIsOrigin]' })
export class IsOriginDirective {
    private organizationOrigins: OrganizationOrigin[];

    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    @Input()
    public set appIsOrigin(origins: OrganizationOrigin[]) {
        this.organizationOrigins = origins;
        this.updateView();
    }

    private updateView(): void {
        this.viewContainer.clear();
        if (isOrigin(this.organizationOrigins)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
