import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    finalize,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

export class Loader<T> {
    public items$: Observable<T[]>;
    public itemInput$ = new Subject<string>();
    public itemsLoading = false;

    public readonly addingTags: string[] = [];

    protected itemsSubject = new BehaviorSubject<T[]>([]);

    public constructor(
        private fetcher: (keyword: string) => Observable<T[]>,
        protected defaultList: T[] = [],
        private createTagFunc?: (
            item: T
        ) => Observable<T & { id: string; name: string }>
    ) {
        this.initLoader();
    }

    public addItems(items: T[]): void {
        this.itemsSubject.next(items);
    }

    public loadInitialList(keyword: string = ''): void {
        this.itemInput$.next(keyword);
    }

    public dispose(): void {
        this.itemInput$.complete();
    }

    public canCreateTagsWithBackend(): boolean {
        return !!this.createTagFunc;
    }

    public addTagPromise(name): Promise<any> {
        if (!this.createTagFunc) return new Promise<void>(res => res());

        this.addingTags.push('');

        return this.createTagFunc({ nameEn: name, nameAr: name } as any)
            .pipe(
                finalize(() => this.addingTags.pop()),
                map(tag => ({ id: tag.id, name: tag.name, valid: true }))
            )
            .toPromise();
    }

    protected initLoader(): void {
        this.items$ = merge(
            of(this.defaultList), // default items
            this.itemsSubject.asObservable(),
            this.itemInput$.pipe(
                debounceTime(100),
                tap(() => this.itemsSubject.next([])),
                tap(() => (this.itemsLoading = true)),
                switchMap(keyword =>
                    this.fetcher(keyword).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.itemsLoading = false))
                    )
                )
            )
        );
    }
}
