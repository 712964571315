import { TableOrderButton } from '@masar/common/types';

export const getOrderByAsString = (orderBy?: TableOrderButton): string => {
    return !orderBy
        ? ''
        : Object.entries(orderBy)
              .filter(x => x[1] !== undefined)
              .map(([column, order]) => `${column} ${order}`)
              .join(',');
};
