import {
    Component,
    EventEmitter,
    Input,
    NgModuleRef,
    OnDestroy,
    Output,
} from '@angular/core';
import {
    KpiResult,
    KpiResultDataEntryResponsePeriod,
    KpiResultDataEntryResponseTransfer,
    KpiResultPeriod,
} from '@masar/common/models';
import { KpiMeasurementCycleType } from '@masar/common/types';
import { permissionList } from '@masar/common/constants';
import { Subject } from 'rxjs';
import {
    ResultAnalysisDialogComponent,
    ImprovementProceduresDialogComponent,
    CapabilityListComponent,
} from '@masar/pages/kpi/update-result/components';
import { takeUntil } from 'rxjs/operators';
import { ApprovalComponent } from '../approval/approval.component';
import { PeriodBreakdownComponent } from '../period-breakdown/period-breakdown.component';
import { ModalService } from 'mnm-webapp';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingFetcherService } from '@masar/core/services';
import { KpiResultAttachmentListComponent } from '../../../helpers';

@Component({
    selector: 'app-periods-table',
    templateUrl: './periods-table.component.html',
})
export class PeriodsTableComponent implements OnDestroy {
    @Input() public mode!: 'new' | 'edit';

    @Input() public type!: 'kpi-result' | 'data-entry-response';

    @Input() public targetId!: string;

    @Input() public kpiResult!: KpiResult;

    @Input() public periods!:
        | KpiResultPeriod[]
        | KpiResultDataEntryResponsePeriod[];

    @Input() public measurementCycle: KpiMeasurementCycleType;

    @Input() public formula: string;

    @Input() public isSubmitting: boolean = false;

    // Fields Only For Kpi Result
    @Input() public target: number;
    @Input() public units: KpiResult['units'];
    @Input() public unitsDescription: string;
    @Input() public inputModeA: string;
    @Input() public inputModeB: string;

    // Fields Only For Data Entry Response
    @Input()
    public currentTransferAssignee: KpiResultDataEntryResponseTransfer['assignee'];
    @Input() public readOnly: boolean = false;
    @Input() public actionMode: 'save-and-move' | 'move-and-reject' | 'none' =
        'none';

    @Output() public forceSave = new EventEmitter<void>();

    @Output() public forceRefresh = new EventEmitter<void>();

    public isBreakdownEnabled = false;

    public readonly permissionList = permissionList;

    private unsubscribeAll = new Subject();

    public constructor(
        private readonly modalService: ModalService,
        private readonly moduleRef: NgModuleRef<any>,
        private readonly translateService: TranslateService,
        appSettingFetcherService: AppSettingFetcherService
    ) {
        appSettingFetcherService.get$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(setting => {
                this.isBreakdownEnabled =
                    setting.kpiSetting.isKpiResultValueBreakdownEnabled;
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public trackByIndex(idx: number): number {
        return idx;
    }

    public async showResultAnalysisDialog(
        period: KpiResultPeriod
    ): Promise<void> {
        const subject = new Subject();
        const component = await this.modalService.show(
            ResultAnalysisDialogComponent,
            {
                title: this.translateService.instant(
                    'translate_result_analysis'
                ),
                beforeInit: c => {
                    c.type = this.type;
                    c.resultAnalysis = period.resultAnalysis;

                    c.mode = this.mode;
                    c.isApproved = period.isApproved;

                    c.currentTransferAssignee = this.currentTransferAssignee;
                    c.readOnly = this.readOnly;
                },
                moduleRef: this.moduleRef,
                onDismiss: () => {
                    subject.next();
                    subject.complete();
                },
            }
        );

        component.itemUpdated.pipe(takeUntil(subject)).subscribe(value => {
            this.modalService.dismiss(component);
            period.resultAnalysis = value;
            this.forceSave.emit();
        });
    }

    public async showImprovementProceduresDialog(
        period: KpiResultPeriod
    ): Promise<void> {
        const subject = new Subject();
        const component = await this.modalService.show(
            ImprovementProceduresDialogComponent,
            {
                title: this.translateService.instant(
                    'translate_improvement_procedures'
                ),
                beforeInit: c => {
                    c.type = this.type;
                    c.improvementProcedures = {
                        value: period.improvementProcedure,
                        percentage:
                            period.improvementProcedureCompletionPercentage,
                        expectedCompletionDate:
                            period.improvementProcedureExpectedCompletionDate,
                    };

                    c.isApproved = period.isApproved;
                    c.mode = this.mode;

                    c.currentTransferAssignee = this.currentTransferAssignee;
                    c.readOnly = this.readOnly;
                },
                moduleRef: this.moduleRef,
                onDismiss: () => {
                    subject.next();
                    subject.complete();
                },
            }
        );

        component.itemUpdated.pipe(takeUntil(subject)).subscribe(value => {
            this.modalService.dismiss(component);
            period.improvementProcedure = value.value;
            period.improvementProcedureCompletionPercentage = value.percentage;
            period.improvementProcedureExpectedCompletionDate =
                value.expectedCompletionDate;
            this.forceSave.emit();
        });
    }

    public async showAttachmentListDialog(
        period: KpiResultPeriod
    ): Promise<void> {
        const subject = new Subject();

        const component = await this.modalService.show(
            KpiResultAttachmentListComponent,
            {
                title: this.translateService.instant('translate_attachments'),
                beforeInit: c => {
                    c.type = this.type;
                    c.targetId = this.targetId;
                    c.period = period;

                    if (this.type === 'data-entry-response') {
                        c.mode =
                            this.actionMode === 'save-and-move'
                                ? 'default'
                                : 'view';
                    } else {
                        c.mode = period.isApproved ? 'view' : 'default';
                    }
                },
                moduleRef: this.moduleRef,
            }
        );

        component.update
            .pipe(takeUntil(subject))
            .subscribe(() => this.forceRefresh.emit());
    }

    public async showCapabilityListDialog(
        period: KpiResultPeriod
    ): Promise<void> {
        const subject = new Subject();

        const component = await this.modalService.show(
            CapabilityListComponent,
            {
                title: this.translateService.instant('translate_capabilities'),
                beforeInit: c => {
                    c.periodId = period.id;
                    c.type = this.type;

                    if (this.type === 'data-entry-response') {
                        c.mode =
                            this.actionMode === 'save-and-move'
                                ? 'default'
                                : 'view';
                    } else {
                        c.mode = period.isApproved ? 'view' : 'default';
                    }
                },
                moduleRef: this.moduleRef,
            }
        );

        component.update
            .pipe(takeUntil(subject))
            .subscribe(() => this.forceRefresh.emit());
    }

    public async showApprovalDialog(period: KpiResultPeriod): Promise<void> {
        const subject = new Subject();

        const component = await this.modalService.show(ApprovalComponent, {
            title: this.translateService.instant(
                'translate_approval_and_suggestions'
            ),
            beforeInit: c => {
                c.period = period;
            },
            moduleRef: this.moduleRef,
        });

        component.approved
            .pipe(takeUntil(subject))
            .subscribe(() => this.forceRefresh.emit());

        component.disapproved
            .pipe(takeUntil(subject))
            .subscribe(() => this.forceRefresh.emit());
    }

    public async showBreakdownDialog(period: KpiResultPeriod): Promise<void> {
        const subject = new Subject();

        const component = await this.modalService.show(
            PeriodBreakdownComponent,
            {
                title: this.translateService.instant(
                    'translate_period_breakdown'
                ),
                beforeInit: c => {
                    c.period = period;
                },
                moduleRef: this.moduleRef,
            }
        );

        component.update
            .pipe(takeUntil(subject))
            .subscribe(() => this.forceRefresh.emit());
    }
}
