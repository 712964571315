import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
    public visible: boolean = false;
    public constructor(public translateService: TranslateService) {}
}
