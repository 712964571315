<div
    class="flex h-full flex-col items-center justify-center rounded-md bg-gray-900 text-white"
>
    <h1 class="mb-4 text-3xl font-bold">
        {{ 'translate_coming_soon' | translate }}
    </h1>
    <p class="mb-8 text-xl">
        {{ 'translate_coming_soon_description' | translate }}
    </p>
</div>
