<app-page pageTitle="{{ 'translate_innovations_list' | translate }}">
    <!-- Tools -->
    <a
        *ngIf="mode === 'default'"
        tools
        [routerLink]="['', 'innovation', 'new']"
        class="btn btn-sm btn-outline-white"
    >
        <i class="fa-light fa-plus me-2"></i>
        <span>{{ 'translate_add_new' | translate }}</span>
    </a>

    <app-innovation-list
        content
        [tableController]="tableController"
        [mode]="mode"
        (innovationSelected)="innovationSelected.emit($event)"
    ></app-innovation-list>
</app-page>
