import { Component, EventEmitter, Input, Output } from '@angular/core';
import { permissionList } from '@masar/common/constants';
import { KpiResultDataEntryResponseTransfer } from '@masar/common/models';

@Component({ templateUrl: './result-analysis-dialog.component.html' })
export class ResultAnalysisDialogComponent {
    @Input() public type: 'kpi-result' | 'data-entry-response';

    @Input() public resultAnalysis: string;

    // Fields Only For Kpi Result
    @Input() public mode: 'new' | 'edit';
    @Input() public isApproved = true;

    // Fields Only For Data Entry Response
    @Input()
    public currentTransferAssignee: KpiResultDataEntryResponseTransfer['assignee'];
    @Input() public readOnly: boolean = false;

    @Output() public itemUpdated: EventEmitter<string> =
        new EventEmitter<string>();

    public readonly permissionList = permissionList;

    public submit(): void {
        this.itemUpdated.emit(this.resultAnalysis);
    }
}
