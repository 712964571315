import {
    Directive,
    ElementRef,
    Output,
    EventEmitter,
    Input,
    NgZone,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({ selector: '[appClickOutside]' })
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
    @Input() public appClickOutside?: HTMLElement;

    @Output() public clickOutside = new EventEmitter<void>();

    public isFirstTime = true;

    private subscription = new Subscription();

    public constructor(
        private readonly elementReference: ElementRef<HTMLElement>,
        private readonly zone: NgZone
    ) {}

    public ngAfterViewInit(): void {
        const element =
            this.appClickOutside || this.elementReference.nativeElement;

        this.zone.runOutsideAngular(() => {
            const documentClick$ = fromEvent<MouseEvent>(document, 'click');
            const subscription = documentClick$
                .pipe(
                    filter(
                        () => !this.isFirstTime || (this.isFirstTime = false)
                    ),
                    filter(
                        clickEvent =>
                            !element.contains(clickEvent.target as Node)
                    )
                )
                .subscribe(() => this.zone.run(() => this.clickOutside.emit()));
            this.subscription.add(subscription);
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
