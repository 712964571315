import {
    Directive,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionService } from '@masar/core/services';
import { PermissionValue } from '@masar/common/types';

@Directive({
    selector: '[appHasPermissionId]',
})
export class HasPermissionIdDirective implements OnDestroy {
    @Input() public set appHasPermissionId(permissionId: PermissionValue) {
        // End previous subscription.
        this.permissionChangesSubscription?.unsubscribe();

        // Start a new one.
        this.permissionChangesSubscription = combineLatest([
            this.permissionService.ensureUserHasPermission(permissionId),
            this.alternateTemplate$,
        ])
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(([hasPermission, alternateTemplate]) => {
                if (hasPermission && this.mode !== 'visible') {
                    this.viewContainer.clear();
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    this.mode = 'visible';
                } else if (
                    !hasPermission &&
                    alternateTemplate &&
                    this.mode !== 'alternate'
                ) {
                    this.viewContainer.clear();
                    this.viewContainer.createEmbeddedView(alternateTemplate);
                    this.mode = 'alternate';
                } else if (
                    !hasPermission &&
                    !alternateTemplate &&
                    this.mode !== 'invisible'
                ) {
                    this.viewContainer.clear();
                    this.mode = 'invisible';
                }
            });
    }

    @Input() public set appHasPermissionIdElse(templateRef: TemplateRef<any>) {
        this.alternateTemplate$.next(templateRef);
    }

    private permissionChangesSubscription: Subscription;

    // A fallback template subject in case the user
    // does not have the permission.
    private alternateTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);

    private unsubscribeAll = new Subject();

    // Visible: the highlighted element is shown.
    // Invisible: the highlighted element and alternate
    // template are not shown.
    // Alternate: the alternative template when the the
    // user does not have permissions is shown.
    private mode: 'visible' | 'invisible' | 'alternate' = 'invisible';

    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.permissionChangesSubscription?.unsubscribe();
        this.alternateTemplate$.complete();
    }
}
