<div class="grid gap-4">
    <div
        *ngFor="let item of wrappedItems; let index = index"
        class="relative min-h-[40px] rounded-md border-2 bg-slate-200 p-4 py-[30px]"
    >
        <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{
                item: item.item,
                emitChange,
                isDisabled,
                index
            }"
        ></ng-container>

        <button
            *ngIf="!isDisabled"
            (click)="removeItem(item)"
            [appTooltip]="'translate_delete' | translate"
            type="button"
            class="btn btn-sm absolute top-0 rounded-full p-2 text-xs end-4"
        >
            <i class="fas fa-trash text-red-500"></i>
        </button>
    </div>

    <div>
        <button
            *ngIf="!isDisabled"
            (click)="addItem()"
            type="button"
            class="btn btn-primary flex items-center justify-center gap-2"
        >
            <i class="fas fa-plus"></i>
            <span>{{ 'translate_add' | translate }}</span>
        </button>
    </div>
</div>
