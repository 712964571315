import { environment } from '@masar/env/environment';
import { MNMConfig } from 'mnm-webapp/lib/config/mnm-config';
import { animate, style } from '@angular/animations';

export const mnmWebAppConfig: MNMConfig = {
    oauthConfig: {
        claimsUrl: environment.apiUrl + '/claims',
        oauthUrl: environment.apiUrl,
    },
    modal: {
        animations: {
            dialogEnterAnimation: [
                style({
                    transform: 'scale(70%, 70%)',
                    opacity: 0.5,
                }),
                animate(
                    '150ms cubic-bezier(.19,1.33,.46,.98)',
                    style({
                        transform: 'scale(100%, 100%)',
                        opacity: 1,
                    })
                ),
            ],

            dialogLeaveAnimation: [
                style({
                    transform: 'scale(100%, 100%)',
                    opacity: 1.0,
                }),
                animate(
                    '150ms cubic-bezier(.19,1.33,.46,.98)',
                    style({
                        transform: 'scale(98%, 98%)',
                        opacity: 0.5,
                    })
                ),
            ],
        },
    },
};
