import { Pipe, PipeTransform } from '@angular/core';
import { getNestedProperty } from '@masar/common/utils';

@Pipe({ name: 'filter', pure: false })
export class FilterPipe implements PipeTransform {
    public transform<T>(
        array: T[],
        value?: string,
        nestedProperty?: string
    ): T[] {
        if (!array) return [];
        if (!value) return array;

        const lowerCaseValue = value.toLowerCase();

        return array.filter(item => {
            const itemValue = nestedProperty
                ? getNestedProperty(item, nestedProperty)
                : item;

            return this.matchValue(itemValue, lowerCaseValue);
        });
    }

    private matchValue(itemValue: unknown, lowerCaseValue: string): boolean {
        // Check for string match
        if (typeof itemValue === 'string') {
            return itemValue.toLowerCase().includes(lowerCaseValue);
        }

        // Check for number match
        if (typeof itemValue === 'number' && !isNaN(Number(lowerCaseValue))) {
            return itemValue === Number(lowerCaseValue);
        }

        // Check for boolean match
        if (typeof itemValue === 'boolean') {
            return itemValue.toString() === lowerCaseValue;
        }

        // Check for array match
        if (Array.isArray(itemValue)) {
            return itemValue.some(subItem =>
                this.matchValue(subItem, lowerCaseValue)
            );
        }

        // Check for object match
        if (itemValue !== null && typeof itemValue === 'object') {
            return Object.values(itemValue).some(subItem =>
                this.matchValue(subItem, lowerCaseValue)
            );
        }

        return false;
    }
}
