<!-- Content-->
<ng-container>
    <!-- Filter -->
    <app-filter-result-box>
        <!-- Innovation Title -->
        <input
            type="text"
            placeholder="{{
                'translate_search_by_innovation_title' | translate
            }}"
            [(ngModel)]="tableController.filter.data.title"
            (keyup)="tableController.filter$.next()"
        />
        <ng-container *ngIf="mode === 'default'">
            <!-- Employee Number -->
            <input
                type="text"
                placeholder="{{
                    'translate_search_by_employee_number' | translate
                }}"
                [(ngModel)]="tableController.filter.data.employeeNumber"
                (keyup)="tableController.filter$.next()"
            />

            <!-- Employee Name -->
            <input
                type="text"
                placeholder="{{
                    'translate_search_by_innovator_name' | translate
                }}"
                [(ngModel)]="tableController.filter.data.keyword"
                (keyup)="tableController.filter$.next()"
            />

            <!-- Year -->
            <input
                type="text"
                placeholder="{{ 'translate_search_by_year' | translate }}"
                [(ngModel)]="tableController.filter.data.year"
                (keyup)="tableController.filter$.next()"
            />

            <!-- Suggestion Number -->
            <input
                type="text"
                placeholder="{{
                    'translate_search_by_suggestion_number' | translate
                }}"
                [(ngModel)]="tableController.filter.data.suggestionNumber"
                (keyup)="tableController.filter$.next()"
            />
        </ng-container>
    </app-filter-result-box>

    <!-- Table -->
    <app-list-loading [items]="tableController.items">
        <table>
            <thead>
                <tr>
                    <th>
                        {{ 'translate_innovation_title' | translate }}
                    </th>
                    <th>
                        {{ 'translate_year' | translate }}
                    </th>
                    <th>
                        {{ 'translate_innovator' | translate }}
                    </th>
                    <th>
                        {{ 'translate_rank' | translate }}
                    </th>
                    <th *ngIf="mode === 'default' || mode === 'linked'">
                        {{ 'translate_suggestion_number' | translate }}
                    </th>
                    <th *ngIf="mode === 'default' || mode === 'linked'">
                        {{ 'translate_activities' | translate }}
                    </th>
                    <th style="width: 0">
                        <i class="fa-light fa-gear"></i>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of tableController.items">
                    <!-- Title -->
                    <td>
                        <a
                            *ngIf="
                                mode === 'default' || mode === 'linked';
                                else titleWhenModeIsSelection
                            "
                            [routerLink]="['', 'innovation', 'detail', item.id]"
                            >{{ item.title }}</a
                        >
                        <ng-template #titleWhenModeIsSelection>
                            {{ item.title }}
                        </ng-template>
                    </td>

                    <!-- Year -->
                    <td class="text-center">{{ item.year }}</td>

                    <!-- Employee -->
                    <td>
                        <a
                            [routerLink]="[
                                '',
                                'innovator',
                                'detail',
                                item.innovator.id
                            ]"
                        >
                            {{ item.innovator.employeeNumber }} -
                            {{ item.innovator.name }}
                        </a>
                    </td>

                    <!-- Rank -->
                    <td>{{ item.innovator.rank }}</td>

                    <!-- Suggestion Number -->
                    <td
                        class="text-center"
                        *ngIf="mode === 'default' || mode === 'linked'"
                    >
                        {{ item.suggestionNumber }}
                    </td>

                    <!-- Activities -->
                    <td *ngIf="mode === 'default' || mode === 'linked'">
                        <div
                            *ngFor="let activity of item.activities"
                            class="badge badge-info mx-1 px-2 text-base"
                        >
                            {{ activity.name }}
                        </div>
                    </td>
                    <!-- Controls-->
                    <td [ngSwitch]="mode">
                        <ng-container *ngSwitchCase="'selection'">
                            <button
                                class="btn btn-sm btn-info"
                                (click)="innovationSelected.emit(item)"
                            >
                                <i class="fa-light fa-check"></i>
                            </button>
                        </ng-container>
                        <ng-container
                            *ngSwitchCase="'linked'"
                            [ngTemplateOutlet]="unLinkingButtonTemplate"
                            [ngTemplateOutletContext]="{ item }"
                        >
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <app-dropdown>
                                <a
                                    class="btn btn-sm btn-info"
                                    [routerLink]="[
                                        '',
                                        'innovation',
                                        'edit',
                                        item.id
                                    ]"
                                >
                                    <i class="fa-light fa-edit fa-fw"></i>
                                    <!--                                    {{ 'translate_edit' | translate }}-->
                                </a>
                                <button
                                    class="btn btn-sm btn-danger"
                                    [disabled]="
                                        currentlyDeleting.includes(item.id)
                                    "
                                    (confirm)="delete(item)"
                                    [swal]="{
                                        title:
                                            'translate_delete_this_item_question_mark'
                                            | translate,
                                        confirmButtonText:
                                            'translate_yes' | translate,
                                        cancelButtonText:
                                            'translate_cancel' | translate,
                                        showCancelButton: true,
                                        showCloseButton: true
                                    }"
                                >
                                    <i class="fas fa-trash fa-fw"></i>
                                    <!--                                    {{ 'translate_delete' | translate }}-->
                                </button>
                            </app-dropdown>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
        <app-table-pagination
            [tableController]="tableController"
        ></app-table-pagination>
    </app-list-loading>
</ng-container>
