<form (ngSubmit)="submit()">
    <!-- Improvement procedure values -->
    <div class="mb-2">
        <p class="mb-1">
            {{ 'translate_improvement_procedures_description' | translate }}
        </p>
        <textarea
            dir="auto"
            class="w-full"
            [disabled]="
                type === 'kpi-result'
                    ? isApproved ||
                      (permissionList.kpiResultEntry
                          | hasPermissionId
                          | async) !== true
                    : currentTransferAssignee !== 'data_entry' || readOnly
            "
            [(ngModel)]="improvementProcedures.value"
            [ngModelOptions]="{ standalone: true }"
        ></textarea>
    </div>

    <!-- Improvement procedure date -->
    <div class="mb-2">
        <p class="mb-1">
            {{ 'translate_expected_completion_date' | translate }}
        </p>
        <input
            appFlatpickr
            type="date"
            [config]="{
                clickOpens:
                    type === 'kpi-result'
                        ? !isApproved &&
                          (permissionList.kpiResultEntry
                              | hasPermissionId
                              | async) === true
                        : currentTransferAssignee === 'data_entry' && !readOnly
            }"
            [attr.disabled]="
                (
                    type === 'kpi-result'
                        ? isApproved ||
                          (permissionList.kpiResultEntry
                              | hasPermissionId
                              | async) !== true
                        : currentTransferAssignee !== 'data_entry' || readOnly
                )
                    ? 'disabled'
                    : null
            "
            class="w-full"
            [(ngModel)]="improvementProcedures.expectedCompletionDate"
            [ngModelOptions]="{ standalone: true }"
        />
    </div>

    <!-- Improvement procedure percentages -->
    <div class="mb-2">
        <p class="text-center text-2xl font-bold">
            {{ improvementProcedures.percentage ?? 0 }}%
        </p>
        <input
            type="range"
            class="w-full"
            step="1"
            min="0"
            max="100"
            [disabled]="
                type === 'kpi-result'
                    ? isApproved ||
                      (permissionList.kpiResultEntry
                          | hasPermissionId
                          | async) !== true
                    : currentTransferAssignee !== 'data_entry' || readOnly
            "
            [ngModel]="improvementProcedures.percentage ?? 0"
            (ngModelChange)="improvementProcedures.percentage = $event"
            [ngModelOptions]="{ standalone: true }"
        />
        <p class="text-center text-sm text-gray-500">
            {{ 'translate_completion_percentage' | translate }}
        </p>
    </div>

    <div
        *ngIf="
            type === 'kpi-result'
                ? !isApproved &&
                  (permissionList.kpiResultEntry | hasPermissionId | async) ===
                      true
                : currentTransferAssignee === 'data_entry' && !readOnly
        "
        class="text-center"
    >
        <ng-container *ngIf="type === 'kpi-result'">
            <ng-container
                *appHasPermissionId="
                    mode === 'new'
                        ? permissionList.kpiResultWrite
                        : permissionList.kpiResultEntry
                "
            >
                <ng-container
                    [ngTemplateOutlet]="buttonTemplate"
                ></ng-container>
            </ng-container>
        </ng-container>

        <ng-container
            *ngIf="type === 'data-entry-response'"
            [ngTemplateOutlet]="buttonTemplate"
        ></ng-container>
    </div>
</form>

<ng-template #buttonTemplate>
    <button type="submit" class="btn-lg btn btn-primary">
        <i class="fa-light fa-save me-2"></i>
        <span>{{ 'translate_save' | translate }}</span>
    </button>
</ng-template>
