import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { TableController } from '@masar/common/misc/table';
import { Innovation } from '@masar/common/models';
import { SharedInnovationService } from '@masar/shared/services/shared-innovation.service';

@Component({ templateUrl: './list.component.html' })
export class ListComponent implements OnDestroy {
    @Input() public mode: 'default' | 'selection' | 'linked' = 'default';

    @Output() public innovationSelected = new EventEmitter<Innovation>();

    public tableController: TableController<
        Innovation,
        {
            keyword?: string;
            employeeNumber?: string;
            suggestionNumber?: string;
            title?: string;
            year?: number;
        }
    >;

    //public currentlyDeleting: string[] = [];

    public constructor(innovationService: SharedInnovationService) {
        this.tableController = new TableController<
            Innovation,
            {
                keyword?: string;
                employeeNumber?: string;
                suggestionNumber?: string;
                title?: string;
                year?: number;
            }
        >(
            filter =>
                innovationService.list(
                    filter.data.keyword,
                    filter.data.employeeNumber,
                    filter.data.suggestionNumber,
                    filter.data.title,
                    filter.data.year,
                    filter.pageNumber,
                    filter.pageSize
                ),
            {
                data: {
                    keyword: '',
                    employeeNumber: '',
                    suggestionNumber: '',
                    title: '',
                    year: null,
                },
            }
        );
        this.tableController.start();
    }

    public ngOnDestroy(): void {
        this.tableController.stop();
    }
}
