import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { OauthService } from 'mnm-webapp';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MandatoryPasswordChangeGuard
    implements CanActivate, CanActivateChild
{
    public constructor(
        protected router: Router,
        protected oauthService: OauthService
    ) {}

    public canActivate(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        return this.canActivateHandler(state);
    }

    public canActivateChild(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        return this.canActivateHandler(state);
    }

    private canActivateHandler(
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (state.url === '/profile') return true;

        return this.oauthService.userInfo$.pipe(
            map(x => {
                const hasMandatoryPasswordChangeClaim =
                    x.claims.findIndex(
                        y => y.type === 'mandatory_password_change'
                    ) !== -1;

                if (hasMandatoryPasswordChangeClaim)
                    this.router.navigate(['', 'profile']).then();

                return !hasMandatoryPasswordChangeClaim;
            })
        );
    }
}
