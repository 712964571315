import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-kpi-result-attachment-period-label',
    template: `
        <ng-container [ngSwitch]="periodType">
            <ng-container *ngSwitchCase="'annual'"> - </ng-container>
            <ng-container *ngSwitchCase="'semi_annual'">
                {{ 'translate_half_year' | translate }} {{ period + 1 }}
            </ng-container>
            <ng-container *ngSwitchCase="'quarter'">
                {{ 'translate_quarter' | translate }} {{ period + 1 }}
            </ng-container>
            <ng-container *ngSwitchCase="'month'">
                {{ 'translate_month' | translate }} {{ period + 1 }}
            </ng-container>
        </ng-container>
    `,
})
export class KpiResultAttachmentPeriodLabelComponent {
    @Input() public period: number;
    @Input() public periodType: string;
}
