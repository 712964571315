import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-delete-button',
    template: `
        <button
            [appTooltip]="'translate_delete' | translate"
            class="btn btn-sm btn-danger"
            [disabled]="isDisabled"
            (confirm)="delete.emit()"
            [swal]="{
                title: 'translate_delete_this_item_question_mark' | translate,
                confirmButtonText: 'translate_yes' | translate,
                cancelButtonText: 'translate_cancel' | translate,
                showCancelButton: true,
                showCloseButton: true
            }"
        >
            <i class="fas fa-trash"></i>
        </button>
    `,
})
export class DeleteButtonComponent {
    @Input() public isDisabled = false;

    @Output() public delete = new EventEmitter<void>();
}
