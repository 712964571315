import { Component, Input, NgModuleRef, NgZone } from '@angular/core';
import { ModalService } from 'mnm-webapp';
import { Subject } from 'rxjs';
import { functions } from '@masar/common/misc/functions';
import { LibraryFile } from '@masar/common/models';
import { FileViewerComponent } from '@masar/features/masar/components';
import { LibraryFileNameService } from './library-file-name.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-library-file-name',
    providers: [LibraryFileNameService],
    template: `
        <div
            *ngIf="isLoading"
            class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
            <div>
                <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                </div>
            </div>
        </div>

        <a
            href="#"
            onclick="return false"
            *ngIf="libraryFile.hasFile"
            (click)="
                download(
                    libraryFile.id,
                    libraryFile.contentType,
                    libraryFile.name
                )
            "
        >
            {{ libraryFile.name }}
        </a>

        <a
            href="{{ libraryFile.link }}"
            target="_blank"
            *ngIf="libraryFile.link && !libraryFile.hasFile"
        >
            {{ libraryFile.name }}
        </a>

        <ng-container *ngIf="!libraryFile.link && !libraryFile.hasFile">
            {{ libraryFile.name }}
        </ng-container>
    `,
})
export class LibraryFileNameComponent {
    @Input() public libraryFile: LibraryFile;

    public isLoading = false;

    public constructor(
        private libraryFileNameService: LibraryFileNameService,
        private ngZone: NgZone,
        private moduleRef: NgModuleRef<any>,
        private modalService: ModalService
    ) {}

    public download(id: string, type: string, fileName: string): void {
        this.isLoading = true;

        this.libraryFileNameService
            .download(id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(file => {
                if (
                    type === 'application/pdf' ||
                    type.split('/')[0] === 'image'
                ) {
                    const src = URL.createObjectURL(file);
                    this.showFileModal(src, fileName);
                } else {
                    this.ngZone.runOutsideAngular(() => {
                        functions.downloadBlobIntoFile(file, fileName);
                    });
                }
            });
    }

    public showFileModal(src: string, title: string): void {
        let subject = new Subject();
        this.modalService.show(FileViewerComponent, {
            moduleRef: this.moduleRef,
            title,
            onDismiss: () => {
                window.URL.revokeObjectURL(src);
                subject.next();
                subject.complete();
            },
            beforeInit: c => {
                c.src = src;
            },
            size: {
                width: '100%',
                height: '100%',
            },
        });
    }
}
