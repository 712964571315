import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'mnm-webapp';
import {
    KpiResultAttachment,
    KpiResultDataEntryResponsePeriod,
    KpiResultPeriod,
} from '@masar/common/models';
import { MnmFormField, MnmFormState } from '@masar/shared/components';
import { finalize } from 'rxjs/operators';
import { KpiResultDataEntryAttachmentService } from '../../../kpi-result-data-entry-attachment.service';
import { KpiResultService } from '../../../kpi-result.service';
import { fields } from './fields';

@Component({ templateUrl: './kpi-result-attachment-new.component.html' })
export class KpiResultAttachmentNewComponent implements OnInit {
    @Input() public type!: 'kpi-result' | 'data-entry-response';
    @Input() public mode: 'new' | 'edit' = 'new';
    @Input() public period!: KpiResultPeriod | KpiResultDataEntryResponsePeriod;
    @Input() public attachmentId: string;

    @Output()
    public attachmentCreated: EventEmitter<KpiResultAttachment> =
        new EventEmitter<KpiResultAttachment>();

    @Output()
    public attachmentUpdated: EventEmitter<KpiResultAttachment> =
        new EventEmitter<KpiResultAttachment>();

    public formState: MnmFormState;
    public isSubmitting = false;
    public mainService: KpiResultService | KpiResultDataEntryAttachmentService;

    public constructor(
        private kpiResultService: KpiResultService,
        private kpiResultDataEntryAttachmentService: KpiResultDataEntryAttachmentService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        fb: FormBuilder
    ) {
        this.formState = new MnmFormState(fields(), fb);
    }

    public ngOnInit(): void {
        this.formState.group.controls['period'].setValue(this.period);

        this.mainService =
            this.type === 'kpi-result'
                ? this.kpiResultService
                : this.kpiResultDataEntryAttachmentService;

        switch (this.mode) {
            case 'new':
                if (this.type === 'kpi-result') {
                    const sendCopyToLibraryField: MnmFormField = {
                        name: 'sendCopyToLibrary',
                        label: 'translate_send_copy_to_library',
                        type: 'checkbox',
                    };

                    this.formState.addField(sendCopyToLibraryField, 5);
                }

                break;

            case 'edit':
                this.mainService
                    .getAttachment(this.attachmentId, true)
                    .subscribe(item => this.fillForm(item));
                break;
        }
    }

    public reset(): void {
        this.formState.reset();
        this.attachmentId = '';
    }

    public submit(): boolean | Promise<boolean> {
        this.formState.setTriedToSubmit();

        if (this.formState.group.invalid) {
            return false;
        }

        this.isSubmitting = true;

        const observable =
            this.mode === 'new'
                ? this.type === 'data-entry-response'
                    ? this.mainService.createAttachment(
                          this.formState.group.getRawValue()
                      )
                    : // Sending copies to library is only
                      // available when linking to kpi result.
                      this.mainService.createAttachment(
                          this.formState.group.getRawValue(),
                          this.formState.group.controls['sendCopyToLibrary']
                              .value
                      )
                : this.mainService.updateAttachment(
                      this.formState.group.getRawValue()
                  );

        return observable
            .pipe(finalize(() => (this.isSubmitting = false)))
            .toPromise()
            .then(item => {
                this.translateService
                    .get('translate_item_added_successfully')
                    .subscribe(str => {
                        this.notificationService.notifySuccess(str);
                    });

                if (this.mode === 'new') {
                    this.attachmentCreated.emit(item);
                } else if (this.mode === 'edit') {
                    this.attachmentUpdated.emit(item);
                }
                return true;
            })
            .catch(() => false);
    }

    private fillForm(item: KpiResultAttachment): void {
        for (const key of Object.keys(this.formState.group.controls)) {
            this.formState.group.controls[key].setValue(item[key]);
        }
    }
}
