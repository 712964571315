import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AuthorizeGuard,
    MandatoryPasswordChangeGuard,
    NotAuthorizeGuard,
    PermissionGuard,
} from '@masar/core/guards';

const routes: Routes = [
    {
        path: 'auth',
        canActivateChild: [NotAuthorizeGuard],
        loadChildren: () =>
            import('@masar/layouts/auth-layout/auth-layout.module').then(
                m => m.AuthLayoutModule
            ),
    },

    {
        path: '',
        canActivateChild: [
            AuthorizeGuard,
            MandatoryPasswordChangeGuard,
            PermissionGuard,
        ],
        loadChildren: () =>
            import('@masar/layouts/default-layout/default-layout.module').then(
                m => m.DefaultLayoutModule
            ),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
