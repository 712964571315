<div class="table-responsive mb-4">
    <table>
        <thead class="thead-dark">
            <tr>
                <th>
                    <app-table-order-column
                        label="translate_type"
                        orderByColumnName="contentType"
                        [tableController]="tableController"
                    ></app-table-order-column>
                </th>
                <th>
                    <app-table-order-column
                        label="translate_name"
                        orderByColumnName="name"
                        [tableController]="tableController"
                    ></app-table-order-column>
                </th>
                <th>
                    <app-table-order-column
                        label="translate_file_size"
                        orderByColumnName="fileSize"
                        [tableController]="tableController"
                    ></app-table-order-column>
                </th>
                <th>{{ 'translate_usage_count' | translate }}</th>
                <th>{{ 'translate_tags' | translate }}</th>
                <th>
                    <app-table-order-column
                        label="translate_creation_time"
                        orderByColumnName="creationTime"
                        [tableController]="tableController"
                    ></app-table-order-column>
                </th>
                <th>
                    <app-table-order-column
                        label="translate_last_update"
                        orderByColumnName="latestModificationTime"
                        [tableController]="tableController"
                    ></app-table-order-column>
                </th>
                <th *ngFor="let field of customFields">
                    {{ field.name | translate }}
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of tableController.items">
                <!-- Type -->
                <td class="align-middle">
                    <app-library-file-icon
                        [libraryFile]="item"
                    ></app-library-file-icon>
                </td>

                <!-- Name -->
                <td class="align-middle">
                    <app-library-file-name
                        [libraryFile]="item"
                    ></app-library-file-name>
                </td>

                <!-- File size -->
                <td class="text-center align-middle">
                    <span *ngIf="item.fileSize" class="text-sm text-gray-400">
                        {{ item.fileSize | fileSize }}
                    </span>
                </td>

                <!-- Usage count -->
                <td class="text-center align-middle">
                    <a
                        class="cursor-pointer"
                        (click)="showLinkedResourcesDialog(item)"
                        *ngIf="item.usageCount !== 0"
                    >
                        {{ item.usageCount }}
                    </a>

                    <ng-container *ngIf="item.usageCount === 0">
                        {{ item.usageCount }}
                    </ng-container>
                </td>

                <!-- Tags -->
                <td class="w-40">
                    <div
                        class="flex flex-row flex-wrap items-center justify-center gap-1"
                    >
                        <button
                            *ngFor="let tag of item.tags"
                            (click)="tagClick.emit(tag)"
                            class="badge badge-success rounded-md p-1 text-sm"
                        >
                            {{ tag.name }}
                        </button>
                    </div>
                </td>

                <!-- Creation time -->
                <td class="text-center align-middle text-gray-500">
                    {{ item.creationTime | toDate | dfnsFormatDistanceToNow }}

                    <!-- Owner -->
                    <p class="text-sm text-gray-400">
                        {{
                            'translate_by_name'
                                | translate
                                    : {
                                          name: item.owner.name
                                      }
                        }}
                    </p>
                </td>

                <!-- Latest update -->
                <td class="text-center align-middle text-gray-500">
                    {{
                        item.latestModificationTime
                            | toDate
                            | dfnsFormatDistanceToNow
                    }}
                    <!-- Username -->
                    <ng-container *ngIf="item.latestModificationBy">
                        <div class="text-sm text-gray-400">
                            {{
                                'translate_by_name'
                                    | translate
                                        : {
                                              name: item.latestModificationBy
                                          }
                            }}
                        </div>
                    </ng-container>
                </td>

                <!-- Custom fields -->
                <td *ngFor="let field of customFields" class="text-center">
                    <ng-container
                        [ngTemplateOutlet]="field.template"
                        [ngTemplateOutletContext]="{item}"
                    ></ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>
