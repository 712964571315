import { Injectable, OnDestroy } from '@angular/core';
import { mnmHttpInterceptorParams, OauthService, Result } from 'mnm-webapp';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { MiscApiService } from './misc-api.service';
import { Permission } from '@masar/common/models';
import { SignalrService } from './signalr.service';
import { messageTypeList } from '@masar/common/misc/message-type-list';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@masar/env/environment';

@Injectable()
export class PermissionService implements OnDestroy {
    private allPermissionList$: BehaviorSubject<Permission[]> =
        new BehaviorSubject(null);
    private userPermissionIdList$: BehaviorSubject<string[]> =
        new BehaviorSubject(null);

    private unsubscribeAll = new Subject();

    public constructor(
        private httpClient: HttpClient,
        miscApiService: MiscApiService,
        signalrService: SignalrService,
        oauthService: OauthService
    ) {
        // Load all user's permissions.
        oauthService.userInfo$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(userInfo => {
                if (userInfo.isLoggedIn) {
                    this.reloadPermissions()
                        .pipe(
                            catchError(() => {
                                return miscApiService.permissions(); // Switch to the second observable on error
                            }),
                            switchMap((res: any) => {
                                this.userPermissionIdList$.next(res);
                                return miscApiService.permissions();
                            })
                        )
                        .subscribe(items => {
                            this.allPermissionList$.next(items); // Emitting the items received from miscApiService.permissions()
                        });
                }
            });

        // Subscribe to permission changes.
        signalrService
            .messages()
            .pipe(takeUntil(this.unsubscribeAll))
            .pipe(filter(x => x.type === messageTypeList.permissionUpdate))
            .subscribe(() => {
                this.reloadPermissions().subscribe(res => {
                    this.userPermissionIdList$.next(res);
                });
            });

        // When user logs out, flush
        // the user permission list:
        oauthService.status$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(status => {
                if (status === 'logged_in') {
                    this.reloadPermissions().subscribe(res => {
                        this.userPermissionIdList$.next(res);
                    });
                } else {
                    this.userPermissionIdList$.next([]);
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.allPermissionList$.complete();
        this.userPermissionIdList$.complete();
    }

    public ensureUserHasPermission(permissionId: string): Observable<boolean> {
        return combineLatest([
            this.allPermissionList$.pipe(filter(x => x !== null)),
            this.userPermissionIdList$.pipe(filter(x => x !== null)),
        ]).pipe(
            map(([allPermissions, userPermissionIds]) => {
                const targetPermission = allPermissions.find(
                    x => x.id === permissionId
                );

                // If unknown permission, return authorized
                if (!targetPermission) return true;

                return this.isAllowed(userPermissionIds, targetPermission);
            })
        );
    }

    private isAllowed(
        userPermissionIds: string[],
        targetPermission: Permission
    ): boolean {
        return (
            // Either the exact permission is assigned to the user...
            userPermissionIds.includes(targetPermission.id) ||
            // ...or the target permission is overriden by one or more of
            // the user's permissions
            targetPermission.overriders.some(x => userPermissionIds.includes(x))
        );
    }

    private reloadPermissions(): Observable<string[]> {
        return this.httpClient
            .get<Result<string[]>>(`${environment.apiUrl}/permission`, {
                params: new HttpParams()
                    .append(mnmHttpInterceptorParams.stealth, `${true}`)
                    .append(
                        mnmHttpInterceptorParams.sustainOnNavigation,
                        `${true}`
                    ),
            })
            .pipe(map(result => result.extra));
    }
}
