<div class="flex flex-row overflow-x-auto rounded-t bg-primary-50">
    <div
        *ngFor="let item of tabList; let idx = index"
        class="first whitespace-nowrap py-3 px-4 first:ms-auto last:me-auto"
        [ngClass]="{
            'cursor-default border-b-4 border-secondary font-bold  text-primary':
                item.isActive,
            'cursor-pointer text-primary-300': !item.isActive
        }"
        (click)="selectTab(item)"
    >
        {{ item.tabTitle }}
    </div>
</div>

<div class="rounded-b border bg-white p-4">
    <ng-content select="app-tab"></ng-content>
</div>
