<app-content contentTitle="{{ 'translate_evaluations_list' | translate }}">
    <button
        tools
        *ngIf="item.evaluateActionAbility.canWrite"
        (click)="showDetailDialog()"
        class="btn btn-sm btn-outline-white flex flex-row items-center gap-2"
    >
        <i class="fa fa-plus"></i>
        <span>{{ 'translate_add_new' | translate }}</span>
    </button>

    <app-list-loading content [items]="instances">
        <div class="table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>{{ 'translate_name' | translate }}</th>
                        <th>{{ 'translate_creation_time' | translate }}</th>
                        <th>{{ 'translate_score' | translate }}</th>
                        <th class="w-0"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let instance of instances">
                        <td>{{ instance.name }}</td>
                        <td class="text-center">
                            {{ instance.creationTime | date : 'yyyy-MM-dd' }}
                        </td>
                        <td class="text-center">
                            %{{ instance.score * 100 | round : 2 }}
                        </td>
                        <td>
                            <app-dropdown>
                                <button
                                    class="btn btn-sm btn-info"
                                    [disabled]="
                                        currentlyProcessing.has(instance.id)
                                    "
                                    [appTooltip]="
                                        'translate_details' | translate
                                    "
                                    (click)="showDetailDialog(instance.id)"
                                >
                                    <i class="fa fa-eye"></i>
                                </button>

                                <button
                                    *ngIf="item.evaluateActionAbility.canWrite"
                                    class="btn btn-sm btn-danger"
                                    [appTooltip]="
                                        'translate_delete' | translate
                                    "
                                    [disabled]="
                                        currentlyProcessing.has(instance.id)
                                    "
                                    (confirm)="delete(instance)"
                                    [swal]="{
                                        title:
                                            'translate_delete_this_item_question_mark'
                                            | translate,
                                        confirmButtonText:
                                            'translate_yes' | translate,
                                        cancelButtonText:
                                            'translate_cancel' | translate,
                                        showCancelButton: true,
                                        showCloseButton: true
                                    }"
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </app-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </app-list-loading>
</app-content>
