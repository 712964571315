import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OauthService } from 'mnm-webapp';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class NotAuthorizeGuard implements CanActivate, CanActivateChild {
    public constructor(
        private oauthService: OauthService,
        private router: Router
    ) {}

    public canActivate(): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivateHandler();
    }

    public canActivateChild():
        | boolean
        | Observable<boolean>
        | Promise<boolean> {
        return this.canActivateHandler();
    }

    private canActivateHandler():
        | boolean
        | Observable<boolean>
        | Promise<boolean> {
        return this.oauthService.userInfo$.pipe(
            map(x => {
                if (x.isLoggedIn) this.router.navigate(['']).then();
                return !x.isLoggedIn;
            })
        );
    }
}
