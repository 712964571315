import { Pipe, PipeTransform } from '@angular/core';
import { KpiResult } from '@masar/common/models';

@Pipe({
    name: 'formatKpiResult',
})
export class FormatKpiResultPipe implements PipeTransform {
    public transform(
        value: number,
        units: KpiResult['units'],
        unitDescription: string
    ): string {
        if (value === null || value === undefined) return value as any;

        switch (units) {
            case 'currency':
                const formatted = value
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
                return `${formatted} ${unitDescription}`;
            case 'time':
                return `${value} ${unitDescription}`;
            case 'percentage':
                return `${value}%`;
            case 'minute':
                return this.timeToFormattedString(value * 60);
            case 'hour':
                return this.timeToFormattedString(value * 60 * 60);
            default:
                return `${value}`;
        }
    }

    private timeToFormattedString(totalSeconds: number): string {
        const hours = Math.floor(totalSeconds / (60 * 60));
        totalSeconds = totalSeconds % (60 * 60);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);

        return `${hours < 10 ? '0' : ''}${hours}:${
            minutes < 10 ? '0' : ''
        }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
}
