import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'concatArrays' })
export class ConcatArraysPipe implements PipeTransform {
    public transform(array1: any[], array2: any[]): any[] {
        return !Array.isArray(array1) || !Array.isArray(array2)
            ? []
            : [...array1, ...array2];
    }
}
