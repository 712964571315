import { NotificationHandler } from 'mnm-webapp';
import { Injectable } from '@angular/core';
import { Notyf } from 'notyf';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastrNotificationHandler implements NotificationHandler {
    // cspell:ignore notyf
    private notyf = new Notyf({
        duration: 5000,
        types: [
            {
                type: 'success',
                background: '#3dc763',
                icon: {
                    className: 'fa-light fa-check',
                    tagName: 'em',
                    color: 'white',
                },
                position: { x: 'left', y: 'top' },
            },
            {
                type: 'warning',
                background: 'orange',
                icon: {
                    className: 'fa-light fa-triangle-exclamation',
                    tagName: 'em',
                    color: 'white',
                },
                position: { x: 'left', y: 'top' },
            },
            {
                type: 'info',
                background: '#719eaf',
                icon: {
                    className: 'fa-light fa-info',
                    tagName: 'em',
                    color: 'white',
                },
                position: { x: 'left', y: 'top' },
            },
            {
                type: 'error',
                background: '#ef4444',
                icon: {
                    className: 'fa-light fa-xmark',
                    tagName: 'em',
                    color: 'white',
                },
                position: { x: 'left', y: 'top' },
            },
        ],
    });

    public constructor(private translateService: TranslateService) {}

    public notifySuccess(message: string): void {
        this.notyf.open({
            type: 'success',
            message: this.translateService.instant(message),
        });
    }

    public notifyInfo(message: string): void {
        this.notyf.open({
            type: 'info',
            message: this.translateService.instant(message),
        });
    }

    public notifyWarn(message: string): void {
        this.notyf.open({
            type: 'warning',
            message: this.translateService.instant(message),
        });
    }

    public notifyError(message: string): void {
        this.notyf.open({
            type: 'error',
            message: this.translateService.instant(message),
        });
    }
}
