import { Component, Input } from '@angular/core';
import { KpiResultPeriod } from '@masar/common/models';
import { KpiMeasurementCycleType } from '@masar/common/types';

@Component({
    selector: 'app-kpi-result-periods-breakdown-dialog',
    templateUrl: './kpi-result-periods-breakdown-dialog.component.html',
})
export class KpiResultPeriodsBreakdownDialogComponent {
    @Input() public periods: KpiResultPeriod[];
    @Input() public measurementCycle: KpiMeasurementCycleType;
}
