import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pushToArray' })
export class PushToArrayPipe implements PipeTransform {
    public transform<T, Y, Z>(
        array?: T[],
        firstElements?: Y[],
        lastElements?: Z[]
    ): (T | Y | Z)[] {
        let result: (T | Y | Z)[] = [];

        if (Array.isArray(array) && array.length) {
            result = [...array];
        }

        if (Array.isArray(firstElements) && firstElements.length) {
            result = [...firstElements, ...result];
        }

        if (Array.isArray(lastElements) && lastElements.length) {
            result = [...result, ...lastElements];
        }

        return result;
    }
}
