import { Component, Input } from '@angular/core';
import { Operation } from '@masar/common/models';
import { permissionList } from '@masar/common/constants';

@Component({
    selector: 'app-operation-link',
    templateUrl: './operation-link.component.html',
})
export class OperationLinkComponent {
    @Input() public item: Operation;
    public permissionList = permissionList;
}
