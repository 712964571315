import { Component, Input } from '@angular/core';
import { Service } from '@masar/common/models';
import { permissionList } from '@masar/common/constants';

@Component({
    selector: 'app-service-link',
    templateUrl: './service-link.component.html',
})
export class ServiceLinkComponent {
    @Input() public item: Service;
    public permissionList = permissionList;
}
