import { Component, Input, OnInit } from '@angular/core';
import { TableController } from '@masar/common/misc/table';

@Component({
    selector: 'app-table-pagination',
    templateUrl: './table-pagination.component.html',
})
export class TablePaginationComponent implements OnInit {
    @Input() public maxShownPageNumbers = 7;
    @Input() public tableController: TableController<any>;
    public pageSizes = [10, 20, 50, 100];

    public ngOnInit(): void {
        this.loadPageSize();
    }

    public getShownPageNumbers(): number[] {
        const shownPageNumbers = Math.min(
            this.tableController.totalPages(),
            this.maxShownPageNumbers
        );
        const center = Math.floor(shownPageNumbers / 2);
        return new Array(shownPageNumbers).fill(0).map((_, idx) => {
            const nominated =
                idx - center + this.tableController.filter.pageNumber;
            if (-center + this.tableController.filter.pageNumber < 0) {
                return idx;
            } else if (
                center + this.tableController.filter.pageNumber >
                this.tableController.totalPages() - 1
            ) {
                return (
                    this.tableController.totalPages() - (shownPageNumbers - idx)
                );
            } else {
                return nominated;
            }
        });
    }

    public changePageNumber(number: number): void {
        if (0 > number || number >= this.tableController.totalPages()) {
            return;
        }

        this.tableController.filter.pageNumber = number;
        this.tableController.filter$.next();
    }

    public changePageSize(size: number): void {
        const didSizeChange = this.tableController.filter.pageSize !== size;
        this.tableController.filter.pageSize = size;
        this.tableController.filter$.next(didSizeChange);
        this.savePageSize();
    }

    private loadPageSize(): void {
        const pageSizeStr = localStorage.getItem('table-pagination-page-size');
        if (!pageSizeStr) return;

        const pageSize = parseInt(pageSizeStr) || 10;
        this.changePageSize(pageSize);
    }

    private savePageSize(): void {
        localStorage.setItem(
            'table-pagination-page-size',
            `${this.tableController.filter.pageSize}`
        );
    }
}
