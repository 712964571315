import {
    Component,
    EventEmitter,
    Input,
    NgModuleRef,
    NgZone,
    OnInit,
    Output,
} from '@angular/core';
import { ModalService } from 'mnm-webapp';
import { FileViewerComponent } from '@masar/features/masar/components';
import { functions } from '@masar/common/misc/functions';
import { permissionList } from '@masar/common/constants';
import {
    KpiResultAttachment,
    KpiResultDataEntryResponsePeriod,
    KpiResultPeriod,
} from '@masar/common/models';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { KpiResultAttachmentNewComponent } from '../kpi-result-attachment-new/kpi-result-attachment-new.component';
import { KpiResultDataEntryAttachmentService } from '../../../kpi-result-data-entry-attachment.service';
import { KpiResultService } from '../../../kpi-result.service';

@Component({ templateUrl: './kpi-result-attachment-list.component.html' })
export class KpiResultAttachmentListComponent implements OnInit {
    @Input() public type!: 'kpi-result' | 'data-entry-response';

    @Input() public targetId!: string;

    @Input() public mode: 'default' | 'view' = 'default';

    @Input() public period?: KpiResultPeriod | KpiResultDataEntryResponsePeriod;

    @Output() public update = new EventEmitter<void>();

    public items: KpiResultAttachment[];
    public currentlyDeleting: string[] = [];
    public mainService: KpiResultService | KpiResultDataEntryAttachmentService;

    public permissionList = permissionList;

    public constructor(
        private kpiResultService: KpiResultService,
        private kpiResultDataEntryAttachmentService: KpiResultDataEntryAttachmentService,
        private modalService: ModalService,
        private moduleRef: NgModuleRef<any>,
        private ngZone: NgZone
    ) {}

    public ngOnInit(): void {
        this.mainService =
            this.type === 'kpi-result'
                ? this.kpiResultService
                : this.kpiResultDataEntryAttachmentService;

        this.mainService
            .listAttachments(this.targetId, this.period?.period)
            .subscribe(items => (this.items = items));
    }

    public async showCreateAttachmentDialog(): Promise<void> {
        let subscription: Subscription;

        const component = await this.modalService.show(
            KpiResultAttachmentNewComponent,
            {
                beforeInit: c => {
                    c.period = this.period;
                    c.type = this.type;
                },
                onDismiss: () => subscription.unsubscribe(),
                moduleRef: this.moduleRef,
            }
        );

        subscription = component.attachmentCreated.subscribe(file => {
            this.items.push(file);
            this.modalService.dismiss(component);
            this.update.emit();
        });
    }

    public download(id: string, type: string): void {
        this.mainService.downloadAttachment(id).subscribe(file => {
            if (type === 'application/pdf' || type.split('/')[0] === 'image') {
                const src = URL.createObjectURL(file);
                this.showFileModal(src);
            } else {
                this.ngZone.runOutsideAngular(() => {
                    functions.downloadBlobIntoFile(file);
                });
            }
        });
    }

    public showFileModal(src: string): void {
        let subject = new Subject();
        this.modalService.show(FileViewerComponent, {
            moduleRef: this.moduleRef,
            onDismiss: () => {
                subject.next();
                subject.complete();
            },
            beforeInit: c => {
                c.src = src;
            },
            size: {
                width: '100%',
                height: '100%',
            },
        });
    }

    public delete(item: KpiResultAttachment): void {
        // add the id of the item to the being deleted array
        // to disable the delete button in the list.
        this.currentlyDeleting.push(item.id);
        this.mainService
            .deleteAttachment(item.id)
            .pipe(
                finalize(() => {
                    // remove the deleted item id from the being deleted
                    // list when the deletion is complete.
                    this.currentlyDeleting = this.currentlyDeleting.filter(
                        x => x !== item.id
                    );
                })
            )
            .subscribe(() => {
                // this.notificationService.notifySuccess(message);
                this.items = this.items.filter(x => x.id !== item.id);
                this.update.emit();
            });
    }

    public isPeriodApproved(
        period: KpiResultPeriod | KpiResultDataEntryResponsePeriod
    ): boolean {
        return (period as KpiResultPeriod)?.isApproved;
    }
}
