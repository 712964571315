import { Component, Input } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
    selector: 'app-export-pdf',
    template: ` <button
        [disabled]="isLoading"
        (click)="exportPdf()"
        class="btn btn-sm btn-info cursor-pointer"
    >
        <app-loading-ring *ngIf="isLoading"></app-loading-ring>
        <i class="fa-light fa-download fa-fw"></i>
        {{ 'translate_export' | translate }}
    </button>`,
})
export class ExportPdfComponent {
    @Input() public elementReferences?: HTMLElement[];

    @Input() public getElementReferences?: () => HTMLElement[];

    @Input() public pdfName: string = 'new-file';

    public isLoading: boolean;

    public exportPdf(): void {
        this.isLoading = true;

        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        let imgWidth = 208;

        const addCanvasToPdf = (canvas: HTMLCanvasElement): void => {
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            const contentDataUrl = canvas.toDataURL('image/jpeg', 0.5);
            pdf.addImage(
                contentDataUrl,
                'JPEG',
                0,
                position,
                imgWidth,
                imgHeight
            );
            position += imgHeight;
        };

        const elements =
            this.elementReferences || this.getElementReferences?.() || [];

        const promises = elements.map(element => html2canvas(element));

        Promise.all(promises).then(canvases => {
            canvases.forEach(addCanvasToPdf);
            pdf.save(`${this.pdfName}.pdf`);
            this.isLoading = false;
        });
    }
}
