import { Component } from '@angular/core';

@Component({
    selector: 'app-loading',
    template: `
        <app-loading-ring class="me-2"></app-loading-ring>
        <span>{{ 'translate_loading' | translate }}</span>
    `,
})
export class LoadingComponent {}
