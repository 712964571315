import { MnmFormField } from './mnm-form-field.model';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export class MnmFormState {
    private formGroup: FormGroup;
    private flattenFields: MnmFormField[];
    private isSubmitted: boolean;

    public constructor(
        public fields: MnmFormField[],
        private formBuilder: FormBuilder
    ) {
        this.computeFlattenFields();

        this.createForm();
    }

    public get(name: string): MnmFormField {
        return this.flattenFields.find(x => x.name === name);
    }

    public hideField(fieldName: string): void {
        const field = this.searchInFields(fieldName);
        field.hide = true;
    }

    public showField(fieldName: string): void {
        const field = this.searchInFields(fieldName);
        field.hide = false;
    }

    public addField(field: MnmFormField, ...positions: number[]): void {
        let currentFieldsList = this.fields;

        while (positions.length > 1) {
            const fieldsListIndex = positions.splice(0, 1)[0];
            currentFieldsList = currentFieldsList[fieldsListIndex].fields;
        }

        currentFieldsList.splice(positions[0], 0, field);

        const adder = (field: MnmFormField): void => {
            if (field.fields) {
                field.fields.forEach(adder);
            } else {
                this.formGroup.addControl(
                    field.name,
                    new FormControl(
                        {
                            value: field.defaultValue,
                            disabled: field.isDisabled,
                        },
                        field.validators
                    )
                );
            }
        };
        adder(field);

        this.computeFlattenFields();
    }

    public removeField(fieldName: string): void {
        const remover = (fields: MnmFormField[]): void => {
            if (!fields) {
                return;
            }

            const fieldIndex = fields.findIndex(x => x.name === fieldName);
            const field = fields.find(x => x.name === fieldName);

            if (fieldIndex !== -1) {
                fields.splice(fieldIndex, 1);
                this.computeFlattenFields();

                // Removes the field from the form group
                const remover = (field: MnmFormField): void => {
                    if (field.fields) {
                        field.fields.forEach(remover);
                    } else {
                        this.formGroup.removeControl(field.name);
                    }
                };

                remover(field);

                return;
            }

            fields.forEach(x => remover(x.fields));
        };

        remover(this.fields);
    }

    public get group(): FormGroup {
        return this.formGroup;
    }

    public setTriedToSubmit(): void {
        this.isSubmitted = true;
    }

    public reset(
        value: any = null,
        options: { onlySelf?: boolean; emitEvents?: boolean } = null
    ): void {
        if (value) {
            this.group.reset(value, options);
        } else {
            this.group.reset();
        }
        this.setPristine();
    }

    public setPristine(): void {
        this.group.markAsPristine();
        this.group.markAsUntouched();
        this.isSubmitted = false;
    }

    public setValidators(fieldName: string, validators: ValidatorFn[]): void {
        this.group.controls[fieldName].setValidators(validators);
        this.group.controls[fieldName].updateValueAndValidity();

        this.get(fieldName).validators = validators;
    }

    public get triedToSubmit(): boolean {
        return this.isSubmitted;
    }

    public isInvalid(fieldName: string): boolean | ValidationErrors {
        return (
            (this.isSubmitted && this.formGroup.controls[fieldName].errors) ||
            (this.formGroup.controls[fieldName].errors &&
                this.formGroup.controls[fieldName].dirty &&
                this.formGroup.controls[fieldName].touched)
        );
    }

    private searchInFields(fieldName: string): MnmFormField {
        const searcher = (fields: MnmFormField[]): MnmFormField => {
            if (!fields) {
                return null;
            }

            const field = fields.find(x => x.name === fieldName);

            if (field) return field;

            for (const f of fields) {
                const result = searcher(f.fields);
                if (result) return result;
            }
        };

        return searcher(this.fields);
    }

    private computeFlattenFields(): void {
        this.flattenFields = this.performFieldsFlattening(this.fields);
    }

    private createForm(): void {
        const controlsConfig = {};
        this.flattenFields.forEach(field => {
            controlsConfig[field.name] = new FormControl(
                { value: field.defaultValue, disabled: field.isDisabled },
                field.validators
            );
        });
        this.formGroup = this.formBuilder.group(controlsConfig);
    }

    private performFieldsFlattening(fields: MnmFormField[]): MnmFormField[] {
        return fields.flatMap(y => {
            if (y.fields) {
                return this.performFieldsFlattening(y.fields);
            } else {
                return [y];
            }
        });
    }
}
