import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'rangeFromTo',
})
export class RangeFromToPipe implements PipeTransform {
    public transform(from: number, to: number): number[] {
        return new Array(to - from).fill(0).map((_, i) => from + i);
    }
}
