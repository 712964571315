import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-file-viewer',
    template: `
        <div class="flex h-full w-full">
            <iframe
                *ngIf="src"
                [src]="src | safe"
                [width]="contentWidth"
                [height]="contentHeight"
                (load)="afterIframeLoaded()"
                class="m-auto"
                #iframe
            ></iframe>
        </div>
    `,
})
export class FileViewerComponent {
    @ViewChild('iframe') public iframe!: ElementRef<HTMLIFrameElement>;
    @Input() public src: string;
    public contentWidth = '100%';
    public contentHeight = '100%';

    public afterIframeLoaded(): void {
        if (!this.iframe) return;
        const iframe = this.iframe.nativeElement;
        let doc = iframe.contentDocument;

        const img = doc.getElementsByTagName('img');
        if (img[0]) {
            this.contentWidth = img[0].width + 10 + 'px';
            this.contentHeight = img[0].height + 10 + 'px';
        }
    }
}
