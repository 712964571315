<!-- Selected standards -->
<h3 class="font-weight-bold text-primary">
    {{ 'translate_search_by_keyword' | translate }}
</h3>

<div class="input-group">
    <input
        type="text"
        class="form-control"
        style="font-size: 1.25rem"
        [(ngModel)]="keyword"
    />
    <div class="input-group-append">
        <button
            class="btn btn-primary"
            style="font-size: 1.25rem"
            (click)="performSearch()"
        >
            {{ 'translate_search' | translate }}
            <i class="fa-light fa-search ml-2"></i>
        </button>
    </div>
</div>

<!-- Selected standards -->
<h3 class="font-weight-bold mt-3 text-primary">
    {{ 'translate_select_standards' | translate }}
</h3>
<app-content-loading [isLoading]="!standards">
    <!-- Select all option -->
    <div
        *ngIf="standards?.length !== 0"
        class="d-inline-block font-weight-bold m-1 cursor-pointer rounded px-2 py-1"
        (click)="addOrRemoveStandard('-1')"
        [ngClass]="{
            'bg-success': selectedStandardIds.length === standards?.length,
            'bg-muted': selectedStandardIds.length !== standards?.length
        }"
    >
        {{ 'translate_select_all' | translate }}
    </div>

    <!-- Standard options -->
    <div
        *ngFor="let item of standards"
        class="d-inline-block font-weight-bold m-1 cursor-pointer rounded px-2 py-1"
        (click)="addOrRemoveStandard(item.id)"
        [ngClass]="{
            'bg-success': selectedStandardIds.includes(item.id),
            'bg-muted': !selectedStandardIds.includes(item.id)
        }"
    >
        {{ item.name }}
    </div>
</app-content-loading>
<!-- Selected domains -->
<h3 class="font-weight-bold mt-3 text-primary">
    {{ 'translate_select_search_domain' | translate }}
</h3>

<!-- Options -->
<div
    *ngFor="let item of domains"
    class="d-inline-block font-weight-bold m-1 cursor-pointer rounded px-2 py-1"
    (click)="item.selected = !item.selected"
    [ngClass]="{
        'bg-success': item.selected,
        'bg-muted': !item.selected
    }"
>
    {{ item.name | translate }}
</div>
