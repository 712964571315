import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    EvaluationInstance,
    EvaluationType,
} from '@masar/features/evaluate/types';
import { EvaluateService } from '@masar/features/evaluate/services/evaluate.service';
import { finalize } from 'rxjs/operators';
import { Evaluation, EvaluationStandard } from '@masar/common/models';

@Component({
    selector: 'app-evaluation-instance-detail',
    templateUrl: './evaluation-instance-detail.component.html',
})
export class EvaluationInstanceDetailComponent implements OnInit {
    @Input() public entityId?: string;

    @Input() public savedEntityId?: string;

    @Input() public itemId?: string;

    @Input() public type: EvaluationType;

    @Input() public mode: 'default' | 'view' = 'default';

    @Output() public update = new EventEmitter<EvaluationInstance>();

    public item?: Partial<EvaluationInstance>;

    public isSubmitting: boolean = false;

    public hasEmptyValue = false;

    public evaluations: Evaluation[] = [];

    public selectedEvaluation: Evaluation = null;

    private standards?: EvaluationStandard[];

    public constructor(private readonly evaluateService: EvaluateService) {}

    public ngOnInit(): void {
        if (this.itemId) this.getItem();
        else {
            this.evaluateService
                .listEvaluations(this.type, this.savedEntityId)
                .subscribe(items => {
                    this.evaluations = items;
                    if (this.evaluations.length > 0)
                        this.setSelectedEvaluation(
                            items.find(x => x.isDefault) ?? items[0]
                        );
                });
        }
    }

    public setSelectedEvaluation(selectedEvaluation: Evaluation): void {
        this.selectedEvaluation = selectedEvaluation;

        this.standards = null;

        if (!this.selectedEvaluation) {
            this.standards = [];
            this.createItem();
            return;
        }

        this.evaluateService
            .getEvaluationStandards(this.type, this.selectedEvaluation.id)
            .subscribe(items => {
                this.standards = items;
                this.createItem();
            });
    }

    public processUpdate(): void {
        if (this.isSubmitting) return;

        this.isSubmitting = true;

        const observable = this.itemId
            ? this.evaluateService.updateInstance(
                  this.itemId,
                  this.type,
                  this.item.records.map(x => ({
                      id: x.id,
                      value: x.value,
                      note: x.note,
                  })),
                  this.item.note
              )
            : this.evaluateService.createInstance(
                  this.entityId,
                  this.type,
                  this.selectedEvaluation.id,
                  this.item.records.map(x => ({
                      standardId: x.standardId,
                      value: x.value,
                      note: x.note,
                  })),
                  this.item.note
              );

        observable
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe((item: EvaluationInstance) => {
                this.item = item;
                this.update.emit(item);
            });
    }

    public checkForEmptyValues(): void {
        this.hasEmptyValue = this.item.records.some(x => x.value === undefined);
    }

    private getItem(): void {
        this.evaluateService
            .getInstance(this.itemId, this.type)
            .subscribe(item => {
                this.item = item;
                this.checkForEmptyValues();
            });
    }

    private createItem(): void {
        this.item = {
            records: this.standards?.map(x => ({
                standardId: x.id,
                name: x.name,
                target: x.target,
            })),
        };

        this.checkForEmptyValues();
    }
}
