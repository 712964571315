import { HttpParams } from '@angular/common/http';
import { HttpQueryParameters } from '../http-query-parameters.interface';

export const getHttpParameters = (
    crudParameters?: HttpQueryParameters
): HttpParams => {
    let params = new HttpParams();

    if (!crudParameters) return params;

    const keys = Object.keys(crudParameters);

    keys.filter(
        key => crudParameters[key] !== null && crudParameters[key] !== undefined
    ).forEach(key => {
        const value = crudParameters[key];
        if (Array.isArray(value))
            value.forEach(v => (params = params.append(key, v?.toString())));
        else params = params.append(key, value?.toString());
    });

    return params;
};
