import { Validators } from '@angular/forms';
import { MnmFormField } from '@masar/shared/components';

export const fields: () => MnmFormField[] = () => [
    {
        name: 'id',
        hide: true,
    },

    {
        name: 'content',
        type: 'textarea',
        label: 'translate_content',
        validators: [Validators.required, Validators.minLength(3)],
    },

    {
        name: 'type',
        hide: true,
    },
];
