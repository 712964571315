import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-permission-link',
    template: `
        <a
            [routerLink]="link"
            *appHasPermissionId="permissionId; else noPermissionTemplate"
            [ngClass]="{ 'no-underline hover:no-underline': noUnderline }"
        >
            <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
        </a>

        <ng-template #noPermissionTemplate>
            <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
        </ng-template>

        <ng-template #contentTemplate><ng-content></ng-content></ng-template>
    `,
    styles: [':host {display: inline;}'],
})
export class PermissionLinkComponent {
    @Input() public permissionId: string;
    @Input() public link: string | string[];
    @Input() public noUnderline: boolean = false;
}
