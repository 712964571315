<a
    *appHasPermissionId="
        permissionList.departmentRead;
        else justNameTemplateRef
    "
    [routerLink]="['', 'department', 'detail', item.id]"
>
    {{ item.name }}
</a>
<ng-template #justNameTemplateRef>
    {{ item.name }}
</ng-template>
