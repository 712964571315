import {
    animate,
    query,
    stagger,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const animations = [
    trigger('title', [
        transition('* => *', [
            style({ opacity: 0, transform: 'translateX(-10px)' }),
            animate('150ms', style({ opacity: 1, transform: 'translateX(0)' })),
        ]),
    ]),

    trigger('tools', [
        transition('* => *', [
            query(
                ':enter',
                [
                    style({ opacity: 0 }),
                    stagger('50ms', [
                        animate(
                            '250ms',
                            style({
                                opacity: 1,
                            })
                        ),
                    ]),
                ],
                // )
                { optional: true }
            ),
        ]),
    ]),
];
