import { animate, style, transition, trigger } from '@angular/animations';

export const animations = [
    trigger('slide', [
        transition(
            '* <=> *',
            [style({ height: '{{height}}px' }), animate('150ms ease-in-out')],
            { params: { height: 0 } }
        ),
    ]),
];
