import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'mnm-webapp';
import { KpiResultCapability } from '@masar/common/models';
import { MiscApiService } from '@masar/core/services';
import { MnmFormState } from '@masar/shared/components';
import { finalize } from 'rxjs/operators';
import { KpiResultCapabilityService } from '../../../kpi-result-capability.service';
import { KpiResultDataEntryCapabilityService } from '../../../kpi-result-data-entry-capability.service';
import { fields } from './fields';

@Component({
    selector: 'app-capability-new',
    templateUrl: './capability-new.component.html',
})
export class CapabilityNewComponent implements OnInit {
    @Input() public mode: 'new' | 'edit' = 'new';
    @Input() public type!: 'kpi-result' | 'data-entry-response';
    @Input() public capabilityId: string;
    @Input() public periodId: string;

    @Output()
    public capabilityCreated: EventEmitter<KpiResultCapability> =
        new EventEmitter<KpiResultCapability>();

    @Output()
    public capabilityUpdated: EventEmitter<KpiResultCapability> =
        new EventEmitter<KpiResultCapability>();

    public formState: MnmFormState;
    public isSubmitting = false;

    public mainService:
        | KpiResultCapabilityService
        | KpiResultDataEntryCapabilityService;

    public constructor(
        private capabilityService: KpiResultCapabilityService,
        private kpiResultDataEntryCapabilityService: KpiResultDataEntryCapabilityService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        miscApiService: MiscApiService,
        fb: FormBuilder
    ) {
        this.formState = new MnmFormState(fields(), fb);

        miscApiService.kpiResultCapabilityTypes().subscribe(items => {
            this.formState.get('type').items = items;
        });
    }

    public ngOnInit(): void {
        this.mainService =
            this.type === 'kpi-result'
                ? this.capabilityService
                : this.kpiResultDataEntryCapabilityService;

        switch (this.mode) {
            case 'edit':
                this.mainService
                    .get(this.capabilityId, true)
                    .subscribe(item => this.fillForm(item));
                break;
        }
    }

    public reset(): void {
        this.formState.reset();
        this.capabilityId = '';
    }

    public submit(): boolean | Promise<boolean> {
        this.formState.setTriedToSubmit();

        if (this.formState.group.invalid) {
            return false;
        }

        this.isSubmitting = true;

        const observable =
            this.mode === 'new'
                ? this.mainService.create(
                      this.formState.group.getRawValue(),
                      this.periodId
                  )
                : this.mainService.update(this.formState.group.getRawValue());

        return observable
            .pipe(finalize(() => (this.isSubmitting = false)))
            .toPromise()
            .then(item => {
                this.translateService
                    .get('translate_item_added_successfully')
                    .subscribe(str => {
                        this.notificationService.notifySuccess(str);
                    });

                if (this.mode === 'new') {
                    this.capabilityCreated.emit(item);
                } else if (this.mode === 'edit') {
                    this.capabilityUpdated.emit(item);
                }
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    private fillForm(item: KpiResultCapability): void {
        for (const key of Object.keys(this.formState.group.controls)) {
            this.formState.group.controls[key].setValue(item[key]);
        }
    }
}
