import { ModuleWithProviders, NgModule } from '@angular/core';
import { EvaluationInstanceDetailComponent } from '@masar/features/evaluate/components/evaluation-instance-detail/evaluation-instance-detail.component';
import { EvaluationInstanceListComponent } from '@masar/features/evaluate/components/evaluation-instance-list/evaluation-instance-list.component';
import { EvaluateService } from '@masar/features/evaluate/services/evaluate.service';
import { CommonModule } from '@angular/common';
import { MasarModule } from '@masar/features/masar/masar.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@masar/shared/shared.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    EvaluationDashboardPageComponent,
    EvaluationStatisticsComponent,
} from '@masar/features/evaluate/components';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [
        EvaluationInstanceDetailComponent,
        EvaluationInstanceListComponent,
        EvaluationStatisticsComponent,
        EvaluationDashboardPageComponent,
    ],
    imports: [
        CommonModule,
        MasarModule,
        TranslateModule,
        SharedModule,
        SweetAlert2Module,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgSelectModule,
    ],
    exports: [
        EvaluationInstanceListComponent,
        EvaluationStatisticsComponent,
        EvaluationDashboardPageComponent,
    ],
})
export class EvaluateModule {
    public static forRoot(): ModuleWithProviders<EvaluateModule> {
        return { ngModule: SharedModule, providers: [EvaluateService] };
    }
}
