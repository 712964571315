import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-progress-bar-input',
    template: `
        <div class="flex items-center gap-2">
            <button (click)="decrease()" class="btn btn-sm btn-danger">
                {{ percentStep }}
                <i class="fas fa-minus"></i>
            </button>
            <input
                type="number"
                #input
                [ngModel]="percent"
                (ngModelChange)="updateValue($event)"
                class="hide-arrows w-36 text-center"
            />
            <button (click)="increase()" class="btn btn-sm btn-success">
                {{ percentStep }}
                <i class="fas fa-plus"></i>
            </button>
        </div>
        <nz-progress [nzPercent]="percent"></nz-progress>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProgressBarInputComponent),
            multi: true,
        },
    ],
})
export class ProgressBarInputComponent implements ControlValueAccessor {
    @Input() public percent: number | null = null;

    @Input() public percentStep: number = 10;

    private onChange: (value: number) => void;

    public increase(): void {
        const value = Math.min((this.percent ?? 0) + this.percentStep, 100);
        this.updateValue(value);
    }

    public decrease(): void {
        const value = Math.max((this.percent ?? 0) - this.percentStep, 0);
        this.updateValue(value);
    }

    public writeValue(value: any): void {
        this.percent = value == null ? null : +value;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(): void {
        return undefined;
    }

    public updateValue(value: number): void {
        this.percent = value;
        this.onChange(value);
    }
}
