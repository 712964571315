import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-items-are-unavailable',
    template: `
        <div class="py-2 text-center italic text-gray-400">
            {{ itemsAreUnavailableMessage | translate }}
        </div>
    `,
})
export class ItemsAreUnavailableComponent {
    @Input() public itemsAreUnavailableMessage =
        'translate_could_not_find_any_items';
}
