import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Item } from '@masar/common/models';
import { ItemTreeCacheService } from '../item-list-field/services/item-tree-cache.service';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-item-field',
    template: `
        <div class="flex flex-col flex-wrap items-stretch md:flex-row">
            <div
                *ngFor="let item of selectsItems; let idx = index"
                class="m-1"
                style="min-width: 25%"
            >
                <!--                [ngStyle]="{ flexBasis: 100 / selectsItems.length + '%' }"-->

                <ng-select
                    [items]="item.items"
                    bindLabel="name"
                    [(ngModel)]="item.selectedItem"
                    (change)="itemSelected($event, idx)"
                    [disabled]="isDisabled"
                ></ng-select>
            </div>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ItemFieldComponent),
        },
    ],
})
export class ItemFieldComponent
    implements OnInit, ControlValueAccessor, OnDestroy
{
    @Input() public maxNesting: number = -2;

    public item: Item;
    public selectsItems: {
        selectedItem?: Item;
        items: Item[];
    }[] = [];
    public isDisabled: boolean = false;

    private newLevelLoader = new Subject<{ cancel?: boolean; item?: Item }>();
    private unsubscribeAll = new Subject();

    private onChange: (item: Item) => void;

    public constructor(private itemTreeCacheService: ItemTreeCacheService) {
        this.newLevelLoader
            .asObservable()
            .pipe(takeUntil(this.unsubscribeAll))
            .pipe(
                switchMap(({ cancel = false, item = null } = {} as any) => {
                    if (cancel) return of([]);
                    return this.itemTreeCacheService.getItemChildren(item);
                })
            )
            .subscribe(items => {
                if (items.length == 0) return;
                this.selectsItems.push({ items });
            });
    }

    public ngOnInit(): void {
        // Load the items for the initial select.
        this.newLevelLoader.next();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public writeValue(item: Item): void {
        if (!item) {
            return;
        }

        this.item = item;

        // Reconstruct the tree.

        // Cancel any requests for new level
        this.newLevelLoader.next({ cancel: true });

        // Reset the selects.
        this.selectsItems = [];

        const fetch = (d: Item): void => {
            this.itemTreeCacheService.getItemParent(d).subscribe(parentData => {
                this.selectsItems.push({
                    selectedItem: d,
                    items: parentData.children,
                });

                if (parentData.parent) {
                    fetch(parentData.parent);
                } else {
                    this.selectsItems.reverse();
                    this.newLevelLoader.next({ item });
                }
            });
        };

        fetch(item);
    }

    public registerOnChange(fn: (item: Item) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(): void {
        return undefined;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public itemSelected(item: Item, level: number): void {
        // If there were no items selected at any level other than the
        // the first, then remove all fields after the select field. Otherwise,
        // keep at least one select field after the current level
        const threshold = item || level === 0 ? level + 1 : level;

        // remove all selects after the level.
        if (this.selectsItems.length > threshold) {
            this.selectsItems.splice(threshold);
        }

        if (!item && level !== 0) {
            item = this.selectsItems[level - 1].selectedItem;
        }

        if (this.onChange) {
            this.onChange(item);
        }

        if (!item) return;

        this.newLevelLoader.next({ item });
    }
}
