import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { miscFunctions, Result } from 'mnm-webapp';
import { environment } from '@masar/env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRequest, UserRequestComment } from '@masar/common/models';

@Injectable()
export class NewUserRequestDialogService {
    public constructor(private httpClient: HttpClient) {}

    public create(
        userRequest: UserRequest,
        id: string
    ): Observable<UserRequest> {
        return this.httpClient
            .post<Result<UserRequest>>(
                `${environment.apiUrl}/user-request/${id}`,
                miscFunctions.objectToURLParams({
                    request: JSON.stringify(userRequest),
                })
            )
            .pipe(map(res => res.extra));
    }

    public get(id: string, forEdit: boolean = false): Observable<UserRequest> {
        return this.httpClient
            .get<Result<UserRequest>>(
                environment.apiUrl + '/user-request/' + id,
                {
                    params: new HttpParams().append('forEdit', `${forEdit}`),
                }
            )
            .pipe(map(result => result.extra));
    }

    public update(userRequest: UserRequest): Observable<UserRequest> {
        return this.httpClient
            .put<Result<UserRequest>>(
                environment.apiUrl + '/user-request',
                miscFunctions.objectToURLParams({
                    request: JSON.stringify(userRequest),
                })
            )
            .pipe(map(result => result.extra));
    }

    public createComment(
        requestComment: UserRequestComment,
        requestId: string
    ): Observable<UserRequestComment> {
        return this.httpClient
            .post<Result<UserRequestComment>>(
                `${environment.apiUrl}/user-request/comment/${requestId}`,
                miscFunctions.objectToURLParams({
                    requestComment: JSON.stringify(requestComment),
                })
            )
            .pipe(map(res => res.extra));
    }

    public getComment(commentId: string): Observable<UserRequestComment> {
        return this.httpClient
            .get<Result<UserRequestComment>>(
                environment.apiUrl + '/user-request/comment/' + commentId
            )
            .pipe(map(result => result.extra));
    }

    public updateComment(
        requestComment: UserRequestComment
    ): Observable<UserRequestComment> {
        return this.httpClient
            .put<Result<UserRequestComment>>(
                environment.apiUrl + '/user-request/comment',
                miscFunctions.objectToURLParams({
                    requestComment: JSON.stringify(requestComment),
                })
            )
            .pipe(map(result => result.extra));
    }
}
