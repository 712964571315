<app-filter-result-box>
    <!-- Keyword field -->
    <input
        *ngIf="shownFilters.includes('name')"
        (keyup)="tableController?.filter$.next(true)"
        [(ngModel)]="tableController?.filter.data.keyword"
        placeholder="{{ 'translate_search_by_name' | translate }}"
        type="text"
    />

    <!-- Tags -->
    <ng-select
        *ngIf="shownFilters.includes('tag')"
        (change)="tableController?.filter$.next(true)"
        [(ngModel)]="tableController?.filter.data.tagIds"
        [items]="tags"
        [multiple]="true"
        bindLabel="name"
        bindValue="id"
        placeholder="{{ 'translate_tags' | translate }}"
    >
    </ng-select>

    <!-- File types -->
    <ng-select
        (change)="tableController?.filter$.next(true)"
        [(ngModel)]="tableController?.filter.data.contentTypes"
        [items]="contentTypes"
        [multiple]="true"
        bindLabel="name"
        bindValue="id"
        placeholder="{{ 'translate_file_type' | translate }}"
    >
        <ng-template ng-option-tmp let-item="item">
            <div class="flex items-center gap-4">
                <app-file-icon [icon]="item.name | lowercase"></app-file-icon>
                <span>{{ item.name }}</span>
            </div>
        </ng-template>
    </ng-select>

    <!-- Owner Ids -->
    <ng-select
        [items]="users"
        bindValue="id"
        bindLabel="name"
        [multiple]="true"
        placeholder="{{ 'translate_users' | translate }}"
        [(ngModel)]="tableController.filter.data.ownerIds"
        (change)="tableController.filter$.next(true)"
    >
    </ng-select>

    <!-- Only my files filter -->
    <div *ngIf="shownFilters.includes('is_owner')">
        <input
            (change)="tableController?.filter$.next(true)"
            [(ngModel)]="tableController?.filter.data.onlyMyFiles"
            class="custom-control-input"
            id="only-my-files"
            type="checkbox"
        />
        <label class="custom-control-label" for="only-my-files">
            {{ 'translate_only_my_files' | translate }}
        </label>
    </div>
</app-filter-result-box>

<app-list-loading [items]="tableController.items">
    <app-library-file-list
        [tableController]="tableController"
        (tagClick)="filterByTag($event.id)"
    ></app-library-file-list>
    <app-table-pagination
        [tableController]="tableController"
    ></app-table-pagination>
</app-list-loading>
