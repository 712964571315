import { Component, forwardRef, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'mnm-webapp';
import { LibraryFile } from '@masar/common/models';
import {
    NewFileDialogComponent,
    LibraryFileListFullComponent,
} from '@masar/features/masar/components';

@Component({
    selector: 'app-library-file-field',
    templateUrl: './library-file-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => LibraryFileFieldComponent),
        },
    ],
})
export class LibraryFileFieldComponent implements ControlValueAccessor {
    @ViewChild('selectButtonTemplate')
    private selectButtonTemplate: TemplateRef<any>;

    public libraryFile: LibraryFile;

    public isDisabled: boolean;

    private libraryFileListModal: LibraryFileListFullComponent;
    private onChange: (data: LibraryFile) => void;

    public constructor(
        private modalService: ModalService,
        private readonly translateService: TranslateService
    ) {}

    public writeValue(obj: LibraryFile): void {
        this.libraryFile = obj;
    }

    public registerOnChange(fn: (libraryFile: LibraryFile) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(): void {
        return undefined;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public updateFile(data: LibraryFile): void {
        this.libraryFile = data;
        this.onChange(data);
    }

    public async dismissLibraryFileListModal(): Promise<void> {
        if (!this.libraryFileListModal) return;

        await this.modalService.dismiss(this.libraryFileListModal);
        this.libraryFileListModal = null;
    }

    public async showLibraryDialog(): Promise<void> {
        this.libraryFileListModal = await this.modalService.show(
            LibraryFileListFullComponent,
            {
                size: { width: '70%' },
            }
        );

        this.libraryFileListModal.list.customFields = [
            {
                name: '',
                template: this.selectButtonTemplate,
            },
        ];
    }

    public async showCreateLibraryFileDialog(): Promise<void> {
        await NewFileDialogComponent.showAsCreateDialog(
            this.modalService,
            this.translateService,
            file => {
                this.updateFile(file);
            }
        );
    }
}
