import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { miscFunctions, Result } from 'mnm-webapp';
import { environment } from '@masar/env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibraryFile } from '@masar/common/models';

@Injectable()
export class NewFileDialogService {
    public constructor(private httpClient: HttpClient) {}

    public create(libraryFile: LibraryFile): Observable<LibraryFile> {
        return this.httpClient
            .post<Result<LibraryFile>>(
                `${environment.apiUrl}/library-file`,
                miscFunctions.objectToURLParams({
                    libraryFile: JSON.stringify(libraryFile),
                })
            )
            .pipe(map(res => res.extra));
    }

    public get(id: string, forEdit: boolean = false): Observable<LibraryFile> {
        return this.httpClient
            .get<Result<LibraryFile>>(
                environment.apiUrl + '/library-file/' + id,
                {
                    params: new HttpParams().append('forEdit', `${forEdit}`),
                }
            )
            .pipe(map(result => result.extra));
    }

    public update(libraryFile: LibraryFile): Observable<LibraryFile> {
        return this.httpClient
            .put<Result<LibraryFile>>(
                environment.apiUrl + '/library-file',
                miscFunctions.objectToURLParams({
                    libraryFile: JSON.stringify(libraryFile),
                })
            )
            .pipe(map(result => result.extra));
    }
}
