<!-- RESULTS -->
<app-content
    *appHasPermissionId="permissionList.kpiResultRead"
    contentTitle="{{ 'translate_results' | translate }}"
    class="mb-5"
>
    <!-- Tools -->
    <ng-container tools *appHasPermissionId="permissionList.kpiResultWrite">
        <a
            *ngIf="kpi"
            (click)="beforeNavigate.emit()"
            class="btn btn-sm btn-outline-white"
            [routerLink]="['', 'kpi', 'update-result', kpi?.id]"
            [queryParams]="{
                year: currentYear,
                departmentId: kpi.owningDepartment.id
            }"
        >
            <i class="fa-light fa-plus"></i>
            <span> {{ 'translate_add_new_year' | translate }}</span>
        </a>
    </ng-container>

    <!-- Content -->
    <app-list-loading content [items]="kpi?.results">
        <div class="mb-5 flex flex-col gap-2 md:flex-row md:justify-around">
            <label *ngFor="let item of resultViewModeList">
                <input
                    type="radio"
                    name="resultViewMode"
                    [value]="item"
                    [(ngModel)]="resultViewMode"
                    class="align-middle me-2"
                />
                <span class="align-middle">{{
                    'translate_result_view_mode_' + item | translate
                }}</span>
            </label>
        </div>

        <ng-container [ngSwitch]="resultViewMode">
            <!-- All results -->
            <ng-container
                *ngSwitchCase="'all'"
                [ngTemplateOutlet]="allResultsTemplate"
            ></ng-container>

            <!-- Grouped by departments -->
            <ng-container
                *ngSwitchCase="'by_department'"
                [ngTemplateOutlet]="resultsGroupedByDepartmentsTemplate"
            ></ng-container>

            <!-- Grouped by year -->
            <ng-container
                *ngSwitchCase="'by_year'"
                [ngTemplateOutlet]="resultsGroupedByYearTemplate"
            ></ng-container>
        </ng-container>
    </app-list-loading>
</app-content>

<!-- A template for listing all results -->
<ng-template #allResultsTemplate>
    <div *ngIf="kpi" class="table-responsive">
        <table>
            <thead>
                <ng-container
                    [ngTemplateOutlet]="resultsHeader"
                    [ngTemplateOutletContext]="{
                        showYear: true,
                        showDepartment: true
                    }"
                ></ng-container>
            </thead>
            <tbody>
                <ng-container
                    *ngFor="let item of kpi.results"
                    [ngTemplateOutlet]="resultTemplate"
                    [ngTemplateOutletContext]="{
                        result: item,
                        showDepartment: true,
                        showYear: true
                    }"
                >
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>

<!-- A template for listing all results grouped by department -->
<ng-template #resultsGroupedByDepartmentsTemplate>
    <div
        *ngFor="let item of resultsGroupedByDepartments"
        class="table-responsive mb-5"
    >
        <table>
            <thead>
                <!-- Department name header -->
                <tr>
                    <th colspan="8">
                        {{ item.department.name }}
                    </th>
                </tr>

                <!-- Regular header -->
                <ng-container
                    [ngTemplateOutlet]="resultsHeader"
                    [ngTemplateOutletContext]="{
                        showYear: true,
                        showDepartment: false
                    }"
                ></ng-container>
            </thead>

            <!-- Data -->
            <tbody>
                <ng-container
                    *ngFor="let x of item.results"
                    [ngTemplateOutlet]="resultTemplate"
                    [ngTemplateOutletContext]="{
                        result: x,
                        showDepartment: false,
                        showYear: true
                    }"
                >
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>

<!-- A template for listing all results grouped by year -->
<ng-template #resultsGroupedByYearTemplate>
    <div
        *ngFor="let item of resultsGroupedByYear"
        class="table-responsive mb-5"
    >
        <table>
            <thead>
                <!-- Year header -->
                <tr>
                    <th colspan="8">
                        {{ item.year }}
                    </th>
                </tr>

                <!-- Regular header -->
                <ng-container
                    [ngTemplateOutlet]="resultsHeader"
                    [ngTemplateOutletContext]="{
                        showYear: false,
                        showDepartment: true
                    }"
                ></ng-container>
            </thead>

            <!-- Data -->
            <tbody>
                <ng-container
                    *ngFor="let x of item.results"
                    [ngTemplateOutlet]="resultTemplate"
                    [ngTemplateOutletContext]="{
                        result: x,
                        showDepartment: true,
                        showYear: false
                    }"
                >
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>

<!-- A template of result header -->
<ng-template
    #resultsHeader
    let-showDepartment="showDepartment"
    let-showYear="showYear"
>
    <tr>
        <th></th>
        <th *ngIf="showYear">{{ 'translate_year' | translate }}</th>
        <th *ngIf="showDepartment">
            {{ 'translate_department' | translate }}
        </th>
        <th *ngIf="!kpi.isTrend">{{ 'translate_target' | translate }}</th>
        <th>{{ 'translate_result' | translate }}</th>
        <th *ngIf="!kpi.isTrend">{{ 'translate_achieved' | translate }}</th>
        <th></th>
        <th></th>
    </tr>
</ng-template>

<!-- A template that shows a single result -->
<ng-template
    #resultTemplate
    let-result="result"
    let-showDepartment="showDepartment"
    let-showYear="showYear"
>
    <!-- Result overview -->
    <tr
        class="full-table-row"
        [ngClass]="{
            'bg-gray-100': result.isOwningDepartment
        }"
    >
        <!-- Toggle result details button -->
        <td class="text-center">
            <button
                (click)="shownResultIds[result.id] = !shownResultIds[result.id]"
                class="btn btn-sm btn-primary"
            >
                <i
                    class="fa"
                    [ngClass]="{
                        'fa-plus': !shownResultIds[result.id],
                        'fa-minus': shownResultIds[result.id]
                    }"
                ></i>
            </button>
        </td>

        <!-- Year -->
        <td *ngIf="showYear" class="text-center">
            <div class="flex flex-row flex-nowrap items-center gap-2">
                {{ result.year }}
            </div>
        </td>

        <!-- Department -->
        <td *ngIf="showDepartment" class="text-center">
            {{ result.department.name }}
        </td>

        <!-- Target -->
        <td *ngIf="!kpi.isTrend" class="text-center">
            {{
                result.target
                    | formatKpiResult : result.units : result.unitsDescription
            }}
        </td>

        <!-- Result -->
        <td class="text-center">
            {{
                result.result
                    | round : 2
                    | formatKpiResult : result.units : result.unitsDescription
            }}
        </td>

        <!-- Achieved-->
        <td *ngIf="!kpi.isTrend" class="full-table-cell text-center">
            <app-achieved-container
                [achieved]="result.achieved"
            ></app-achieved-container>
        </td>
        <!-- Capability & file count -->

        <td class="text-center">
            <button
                type="button"
                (click)="showResultCapabilities(result)"
                class="badge badge-success"
            >
                {{
                    'translate_capabilities_count'
                        | translate
                            : {
                                  count: result.capabilityCount
                              }
                }}
            </button>
            <br />
            <button
                type="button"
                (click)="showResultAttachments(result)"
                class="badge badge-success"
            >
                {{
                    'translate_attachments_count'
                        | translate
                            : {
                                  count: result.libraryFileCount
                              }
                }}
            </button>
        </td>

        <td class="text-center">
            <!-- Edit button -->
            <a
                (click)="beforeNavigate.emit()"
                class="btn btn-sm btn-info"
                [routerLink]="['', 'kpi', 'update-result', kpi?.id]"
                [queryParams]="{
                    year: result.year,
                    departmentId: result.department.id
                }"
            >
                <i class="fa-light fa-edit"></i>
            </a>

            <button
                [appTooltip]="'translate_period_breakdown' | translate"
                (click)="showPeriodsBreakdownDialog(result)"
                class="btn btn-sm btn-primary mr-2 cursor-pointer"
            >
                <i class="fa-light fa-list-ul"></i>
            </button>
        </td>
    </tr>

    <!-- Results details -->
    <tr *ngIf="shownResultIds[result.id]">
        <td colspan="9">
            <div class="flex flex-col gap-5">
                <!-- Alert -->
                <div
                    class="flex flex-row items-center gap-5 rounded border border-amber-600 bg-secondary-100 p-4 text-amber-700"
                >
                    <i class="fa fa-lightbulb-on"></i>
                    <span>
                        {{
                            'translate_kpi_result_calculation_note' | translate
                        }}
                    </span>
                </div>

                <!-- Results periods -->
                <table class="table-fixed">
                    <!-- Header -->
                    <tr>
                        <td class="w-64">
                            {{ 'translate_target_description' | translate }}
                        </td>
                        <td
                            *ngFor="
                                let period of result.periods;
                                let idx = index
                            "
                            class="text-center"
                            [ngClass]="{
                                'bg-secondary-100': result.currentPeriod > idx
                            }"
                        >
                            {{
                                (result.measurementCycle === 'annual'
                                    ? 'translate_values'
                                    : result.measurementCycle === 'semi_annual'
                                    ? 'translate_half_year'
                                    : result.measurementCycle === 'quarter'
                                    ? 'translate_quarter'
                                    : 'translate_month'
                                ) | translate
                            }}
                            <ng-container
                                *ngIf="result.measurementCycle !== 'annual'"
                                >{{ period?.period + 1 }}
                            </ng-container>
                        </td>
                        <td class="text-center">
                            {{ 'translate_total' | translate }}
                        </td>
                    </tr>

                    <!-- Target -->
                    <tr *ngIf="!kpi.isTrend">
                        <td>
                            <div>{{ 'translate_target' | translate }}</div>
                            <div class="text-xs text-gray-500">
                                {{ result.targetSettingMethod.name }}
                            </div>
                        </td>
                        <td
                            *ngFor="
                                let period of result.periods;
                                let idx = index
                            "
                            class="text-center"
                            [ngClass]="{
                                'bg-secondary-100': result.currentPeriod > idx
                            }"
                        >
                            {{
                                period.target
                                    | formatKpiResult
                                        : result.units
                                        : result.unitsDescription
                            }}
                        </td>
                        <td class="text-center">
                            {{
                                result.target
                                    | formatKpiResult
                                        : result.units
                                        : result.unitsDescription
                            }}
                        </td>
                    </tr>

                    <ng-container
                        *ngIf="
                            result.units !== 'number' && result.formula !== 'A'
                        "
                    >
                        <!-- A -->
                        <tr>
                            <td>
                                {{ result.formulaDescriptionA + ' (A)' }}
                            </td>
                            <td
                                *ngFor="
                                    let period of result.periods;
                                    let idx = index
                                "
                                class="text-center"
                                [ngClass]="{
                                    'bg-secondary-100':
                                        result.currentPeriod > idx
                                }"
                            >
                                {{ period.a | round : 2 }}
                            </td>
                            <td class="text-center">
                                {{ result.aggregateA | round : 2 }}
                            </td>
                        </tr>

                        <!-- B -->
                        <tr>
                            <td>
                                {{ result.formulaDescriptionB + ' (B)' }}
                            </td>
                            <td
                                *ngFor="
                                    let period of result.periods;
                                    let idx = index
                                "
                                class="text-center"
                                [ngClass]="{
                                    'bg-secondary-100':
                                        result.currentPeriod > idx
                                }"
                            >
                                {{ period.b | round : 2 }}
                            </td>
                            <td class="text-center">
                                {{ result.aggregateB | round : 2 }}
                            </td>
                        </tr>
                    </ng-container>

                    <!-- Result -->
                    <tr>
                        <td>
                            {{ 'translate_result' | translate }}
                        </td>
                        <td
                            *ngFor="
                                let period of result.periods;
                                let idx = index
                            "
                            class="text-center"
                            [ngClass]="{
                                'bg-secondary-100': result.currentPeriod > idx
                            }"
                        >
                            {{
                                period.result
                                    | round : 2
                                    | formatKpiResult
                                        : result.units
                                        : result.unitsDescription
                            }}
                        </td>
                        <td class="text-center">
                            {{
                                result.result
                                    | round : 2
                                    | formatKpiResult
                                        : result.units
                                        : result.unitsDescription
                            }}
                        </td>
                    </tr>

                    <!-- Achieved -->
                    <tr *ngIf="!kpi.isTrend" class="full-table-row">
                        <td>
                            {{ 'translate_achieved' | translate }}
                        </td>
                        <td
                            *ngFor="let period of result.periods"
                            class="full-table-cell text-center"
                        >
                            <app-achieved-container
                                [achieved]="period.achieved"
                            ></app-achieved-container>
                        </td>
                        <td class="full-table-cell text-center">
                            <app-achieved-container
                                [achieved]="result.achieved"
                            ></app-achieved-container>
                        </td>
                    </tr>

                    <!-- Evaluate -->
                    <ng-container
                        *appIsOrigin="[
                            organizationOrigin.localhost,
                            organizationOrigin.staging,
                            organizationOrigin.police
                        ]"
                    >
                        <tr
                            *ngIf="
                                result.periods[0].evaluateActionAbility.canRead
                            "
                        >
                            <td>
                                {{ 'translate_evaluation_result' | translate }}
                            </td>

                            <ng-container
                                *ngIf="
                                    kpi?.isExemptFromEvaluation === false &&
                                    result?.isExemptFromEvaluation === false
                                "
                            >
                                <td
                                    *ngFor="
                                        let period of result.periods;
                                        let idx = index
                                    "
                                    class="p-3"
                                    [ngClass]="{
                                        'bg-secondary-100':
                                            result.currentPeriod > idx
                                    }"
                                    [style.background-color]="
                                        period.evaluationScoreDetail?.color
                                    "
                                >
                                    <div
                                        class="flex items-center justify-center gap-2"
                                    >
                                        <button
                                            (click)="
                                                showEvaluationPopup(
                                                    period,
                                                    result.year
                                                )
                                            "
                                            class="btn btn-sm btn-primary inline-flex items-center gap-2"
                                        >
                                            <i class="fa fa-star h-4 w-4"></i>
                                        </button>

                                        <span
                                            *ngIf="
                                                period.evaluationScoreDetail as evaluationScoreDetail
                                            "
                                            class="text-white"
                                        >
                                            {{
                                                evaluationScoreDetail.score *
                                                    100 | number : '1.2-2'
                                            }}%
                                        </span>
                                    </div>
                                </td>

                                <!-- Total Periods Evaluations -->
                                <td
                                    class="text-center text-white"
                                    [style.background-color]="
                                        result.totalPeriodsEvaluationScore
                                            ?.color
                                    "
                                >
                                    <span
                                        *ngIf="
                                            result.totalPeriodsEvaluationScore
                                        "
                                    >
                                        {{
                                            result.totalPeriodsEvaluationScore
                                                .score * 100 | number : '1.2-2'
                                        }}%
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    kpi?.isExemptFromEvaluation === true ||
                                    result?.isExemptFromEvaluation === true
                                "
                            >
                                <td
                                    [attr.colspan]="result.periods.length + 1"
                                    class="text-center italic text-gray-400"
                                >
                                    {{
                                        'translate_is_exempt_from_evaluation'
                                            | translate
                                    }}
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                    <!-- Result analysis -->
                    <tr>
                        <td>
                            {{ 'translate_result_analysis' | translate }}
                        </td>
                        <td
                            *ngFor="
                                let period of result.periods;
                                let idx = index
                            "
                            class="text-center"
                            [ngClass]="{
                                'bg-secondary-100': result.currentPeriod > idx
                            }"
                        >
                            {{ period.resultAnalysis }}
                        </td>
                        <td class="text-center"></td>
                    </tr>

                    <!-- Improvement procedures. -->
                    <tr>
                        <td>
                            {{ 'translate_improvement_procedures' | translate }}
                        </td>
                        <td
                            *ngFor="
                                let period of result.periods;
                                let idx = index
                            "
                            class="text-center"
                            [ngClass]="{
                                'bg-secondary-100': result.currentPeriod > idx
                            }"
                        >
                            {{ period.improvementProcedure }}
                        </td>
                        <td class="text-center"></td>
                    </tr>

                    <!-- Improvement procedures completion percentage -->
                    <tr>
                        <td>
                            {{
                                'translate_improvement_procedures_completion_percentage'
                                    | translate
                            }}
                        </td>
                        <td
                            *ngFor="
                                let period of result.periods;
                                let idx = index
                            "
                            class="text-center"
                            [ngClass]="{
                                'bg-secondary-100': result.currentPeriod > idx
                            }"
                        >
                            <span
                                *ngIf="
                                    period.improvementProcedureCompletionPercentage !==
                                    null
                                "
                            >
                                {{
                                    period.improvementProcedureCompletionPercentage
                                }}%
                            </span>
                        </td>
                        <td class="text-center"></td>
                    </tr>

                    <!-- Improvement procedures expected completion date -->
                    <tr>
                        <td>
                            {{
                                'translate_improvement_procedures_expected_completion_date'
                                    | translate
                            }}
                        </td>
                        <td
                            *ngFor="
                                let period of result.periods;
                                let idx = index
                            "
                            class="text-center"
                            [ngClass]="{
                                'bg-secondary-100': result.currentPeriod > idx
                            }"
                        >
                            {{
                                period.improvementProcedureExpectedCompletionDate
                                    | date
                            }}
                        </td>
                        <td class="text-center"></td>
                    </tr>
                </table>

                <!-- Leader directives and supervisor nots -->
                <app-period-tab-list
                    [periods]="result.periods"
                    [measurementCycle]="result.measurementCycle"
                    [contentTemplate]="directiveAndNoteTemplate"
                ></app-period-tab-list>
            </div>
        </td>
    </tr>
</ng-template>

<!-- A template of a period's directives and notes -->
<ng-template #directiveAndNoteTemplate let-period="period">
    <table>
        <thead>
            <tr>
                <th>
                    {{ 'translate_supervisor_notes' | translate }}
                </th>
                <th>
                    {{ 'translate_leadership_directives' | translate }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    {{
                        period.supervisorNote ||
                            ('translate_not_available' | translate)
                    }}
                </td>
                <td>
                    {{
                        period.leadershipDirective ||
                            ('translate_not_available' | translate)
                    }}
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>
