import { Component, Input } from '@angular/core';
import { HistoryItem } from './history-item.interface';

@Component({
    selector: 'app-history-list',
    templateUrl: './history-list.component.html',
})
export class HistoryListComponent {
    @Input() public items?: HistoryItem[];

    @Input() public useTranslate = true;
}
