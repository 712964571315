import { animate, style, transition, trigger } from '@angular/animations';

export const animations = [
    trigger('card', [
        transition(':enter', [
            style({
                transform: 'perspective(20cm) rotateY(-80deg)',
                opacity: 0.0,
            }),
            animate(
                '700ms cubic-bezier(.37,1.52,.73,.94)',
                style({
                    transform: 'perspective(20cm) rotateY(0)',
                    opacity: 1.0,
                })
            ),
        ]),

        transition(':leave', [
            style({
                transform: 'perspective(20cm) rotateY(0)',
                opacity: 1.0,
            }),
            animate(
                '300ms cubic-bezier(.37,1.52,.73,.94)',
                style({
                    transform: 'perspective(20cm) rotateY(-80deg)',
                    opacity: 0.0,
                })
            ),
        ]),
    ]),
];
