import { Component } from '@angular/core';
import { Item } from '@masar/common/models';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
})
export class SearchFormComponent {
    public standards: Item[] = undefined;

    public keyword: string = '';
    public selectedStandardIds: string[] = [];
    public domains = [
        {
            id: 'kpi',
            name: 'translate_kpis',
            selected: true,
        },
        {
            id: 'capability',
            name: 'translate_capabilities',
            selected: true,
        },
        {
            id: 'file',
            name: 'translate_files',
            selected: true,
        },
    ];

    public constructor(private router: Router, activatedRoute: ActivatedRoute) {
        activatedRoute.queryParams.pipe(first()).subscribe(params => {
            // Determine if there is a query.
            const hasQueryParam = 'keyword' in params;

            if (hasQueryParam) {
                this.keyword = params['keyword'];
                this.domains.forEach(x => {
                    x.selected = params[x.id] === 'true';
                });
            }

            // miscApiService.standards().subscribe(items => {
            //     this.standards = items;

            //     if (hasQueryParam) {
            //         this.selectedStandardIds = params.standardIds || [];
            //     } else {
            //         // Select all standards.
            //         this.addOrRemoveStandard('-1');
            //     }
            // });
        });
    }

    public addOrRemoveStandard(id: string): void {
        if (id === '-1') {
            if (this.standards.length === this.selectedStandardIds.length) {
                this.selectedStandardIds = [];
            } else {
                this.selectedStandardIds = this.standards.map(x => x.id);
            }
        } else {
            // Check if the item is in the selected
            // standards list.
            const idx = this.selectedStandardIds.findIndex(x => x === id);

            if (idx === -1) {
                this.selectedStandardIds.push(id);
            } else {
                this.selectedStandardIds.splice(idx, 1);
            }
        }
    }

    public performSearch(): void {
        this.router.navigate(['', 'search'], {
            queryParams: {
                keyword: this.keyword,
                standardIds: this.selectedStandardIds,
                kpi: this.domains.find(x => x.id === 'kpi').selected,
                capability: this.domains.find(x => x.id === 'capability')
                    .selected,
                file: this.domains.find(x => x.id === 'file').selected,
            },
        });
    }
}
