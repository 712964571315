import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumerate',
})
export class EnumeratePipe implements PipeTransform {
    public transform(value: number): Array<number> {
        return Array(Math.round(value))
            .fill(0)
            .map((_, i) => i);
    }
}
