import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaderDirective } from './directives/evader.directive';
import { EasterService } from './easter.service';
import { EasterAlertComponent } from './components/easter-alert/easter-alert.component';

export function startEasterService(easterService: EasterService): Function {
    return () => easterService.start();
}

@NgModule({
    imports: [CommonModule],
    declarations: [EvaderDirective, EasterAlertComponent],
    exports: [EvaderDirective, EasterAlertComponent],
})
export class EasterModule {
    public static forRoot(): ModuleWithProviders<EasterModule> {
        return {
            ngModule: EasterModule,
            providers: [
                EasterService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: startEasterService,
                    deps: [EasterService],
                    multi: true,
                },
            ],
        };
    }
}
