import { environment } from '@masar/env/environment';
import { locale as arLocale } from '@masar/common/i18n/app/ar';
import { locale as enLocale } from '@masar/common/i18n/app/en';
import { ar as dateFnsAr, enUS as dateFnsEnUs } from 'date-fns/locale';
import { TranslationConfigForRoot } from '@ng-omar/translation/translation/interfaces';

export const translationConfig: TranslationConfigForRoot = {
    type: 'endpoint',
    translationEndpoint: environment.apiUrl,
    localStorageKey: 'lang',
    defaultLanguage: 'ar',
    module: 'app',
    strings: [arLocale, enLocale],
    dateFnsLocales: [
        { code: 'ar', locale: dateFnsAr },
        { code: 'en', locale: dateFnsEnUs },
    ],
};
