<mnm-form *ngIf="formState" [state]="formState" [translateLabels]="true">
    <div class="mt-2 text-center">
        <button
            type="submit"
            class="btn-lg btn btn-primary"
            (click)="submit()"
            [disabled]="isSubmitting"
        >
            <app-loading-ring
                *ngIf="isSubmitting"
                class="me-2"
            ></app-loading-ring>
            <i class="fa-light fa-save me-2"></i>
            <span>{{ 'translate_save' | translate }}</span>
        </button>
    </div>
</mnm-form>
