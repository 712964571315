<div
    class="fixed left-1/2 top-0 flex -translate-x-1/2 flex-row text-xs uppercase opacity-70"
    style="z-index: 50000"
>
    <div class="px-2 py-1" [ngClass]="'websocket-status--' + signalrStatus">
        {{ signalrStatus }}
    </div>
</div>

<ng-container *ngIf="isThemeLoaded">
    <div
        [ngClass]="{ 'p-6': safeModeService.isEnabled }"
        class="relative h-screen bg-gray-600"
    >
        <router-outlet></router-outlet>

        <div
            *ngIf="safeModeService.isEnabled"
            class="absolute bottom-1 left-1/2 -translate-x-1/2 text-xs font-bold uppercase tracking-widest text-white"
        >
            safe mode
        </div>
    </div>
</ng-container>

<mnm-main></mnm-main>
<app-easter-alert></app-easter-alert>
