import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableOrderButton } from '@masar/common/types';

@Component({
    selector: 'app-table-order-button',
    templateUrl: './table-order-button.component.html',
})
export class TableOrderButtonComponent {
    @Input() public orderBy: TableOrderButton[string];

    @Output() public orderByChange = new EventEmitter<
        TableOrderButton[string]
    >();

    public onClickOrderButton(): void {
        const orderBy: TableOrderButton[string] = !this.orderBy
            ? 'ascending'
            : this.orderBy === 'ascending'
            ? 'descending'
            : undefined;

        this.orderByChange.emit(orderBy);
    }
}
