<app-content contentTitle="{{ 'translate_innovations' | translate }}">
    <!-- Tools -->
    <ng-container tools>
        <ng-container *appHasPermissionId="requiredPermissionForLinking">
            <button
                (click)="showInnovationDialog()"
                class="btn btn-sm btn-outline-white"
            >
                <i class="fa-light fa-plus"></i>
                <span> {{ 'translate_link_with_innovation' | translate }}</span>
            </button>
        </ng-container>
    </ng-container>

    <!-- Table -->
    <app-innovation-list
        content
        [tableController]="linkedInnovations.tableController"
        [mode]="'linked'"
        [unLinkingButtonTemplate]="unLinkingButtonTemplate"
    ></app-innovation-list>
</app-content>

<!-- Template for the unlinking button -->
<ng-template #unLinkingButtonTemplate let-item="item">
    <button
        *appHasPermissionId="requiredPermissionForUnlinking"
        class="btn btn-sm btn-danger"
        [disabled]="linkedInnovations.currentlyUnlinking.includes(item.id)"
        (confirm)="unlinkInnovation(item.id)"
        [swal]="{
            title: 'translate_delete_this_item_question_mark' | translate,
            confirmButtonText: 'translate_yes' | translate,
            cancelButtonText: 'translate_cancel' | translate,
            showCancelButton: true,
            showCloseButton: true
        }"
    >
        <i class="fas fa-trash"></i>
    </button>
</ng-template>
