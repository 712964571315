import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@masar/env/environment';
import { TableResult } from '@masar/common/misc/table';
import { KpiResult } from '@masar/common/models';

@Injectable()
export class SharedKpiResultService {
    public constructor(private httpClient: HttpClient) {}
    public listOwningResults(
        keyword: string = '',
        year: number = null,
        excludedIds: string[],
        pageNumber: number = 0,
        pageSize: number = 20
    ): Observable<TableResult<KpiResult>> {
        let params = new HttpParams()
            .append('keyword', keyword)
            .append('year', `${year}`)
            .append('pageNumber', `${pageNumber}`)
            .append('pageSize', `${pageSize}`);

        excludedIds?.forEach(x => (params = params.append('excludedIds', x)));

        return this.httpClient
            .get<Result<TableResult<KpiResult>>>(
                `${environment.apiUrl}/kpi-result/owning`,
                {
                    params,
                }
            )
            .pipe(map(res => res.extra));
    }
}
