import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { convertBlobToBase64 } from '@masar/common/utils';

@Component({
    selector: 'app-image',
    template: `
        <div *ngIf="!isImageLoaded" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <img
            [src]="imageToShow"
            *ngIf="isImageLoaded; else noImageFound"
            [ngStyle]="{
                'object-fit': isObjectFitContain ? 'contain' : 'cover'
            }"
        />
        <ng-template #noImageFound>
            <img
                *ngIf="fallbackImageUrl"
                [src]="fallbackImageUrl"
                alt="FallbackImage"
                [ngStyle]="{
                    'object-fit': isObjectFitContain ? 'contain' : 'cover'
                }"
            />
        </ng-template>
    `,
    styles: [
        ':host {display: block; overflow: hidden; position: relative;}',
        'img, div {height: 100%; width: 100%; object-fit: cover; position: absolute; left: 0; top: 0}',
    ],
})
export class ImageComponent implements OnInit {
    @Input() public imageObservable: Observable<Blob>;
    @Input() public fallbackImageUrl: string;
    @Input() public isObjectFitContain: boolean = true;

    public isImageLoaded = false;
    public imageToShow: any;

    public ngOnInit(): void {
        this.refreshImage();
    }

    public refreshImage(): void {
        this.imageObservable.subscribe(data => {
            this.createImageFromBlob(data).then();
        });
    }

    private async createImageFromBlob(image: Blob): Promise<void> {
        if (!image) return;
        this.imageToShow = await convertBlobToBase64(image);
        this.isImageLoaded = true;
    }
}
