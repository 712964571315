import {
    AfterContentInit,
    Component,
    ContentChildren,
    QueryList,
} from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
    selector: 'app-tab-list',
    templateUrl: './tab-list.component.html',
    styles: [':host { display: block }'],
})
export class TabListComponent implements AfterContentInit {
    @ContentChildren(TabComponent) public tabList: QueryList<TabComponent>;

    public ngAfterContentInit(): void {
        this.handleTabChanges();

        this.tabList.changes.subscribe(() => {
            this.handleTabChanges();
        });
    }

    public selectTab(tab: TabComponent = null): void {
        if (!this.tabList || this.tabList.length === 0) return;
        tab ??= this.tabList.get(0);
        this.tabList.forEach(x => (x.isActive = false));
        this.tabList.find(x => x === tab).isActive = true;
    }

    private handleTabChanges(): void {
        setTimeout(() => {
            this.selectTab();
        }, 0);
    }
}
