import {
    Component,
    EventEmitter,
    Input,
    NgModuleRef,
    Output,
    TemplateRef,
} from '@angular/core';
import { Item, LibraryFile } from '@masar/common/models';
import { ModalService } from 'mnm-webapp';
import { LibraryFileLinkedResourceListComponent } from './helpers';
import { TableController } from '@masar/common/misc/table';

@Component({
    selector: 'app-library-file-list',
    templateUrl: './library-file-list.component.html',
})
export class LibraryFileListComponent {
    @Input() public tableController: TableController<LibraryFile>;

    @Input() public customFields: {
        name: string;
        template: TemplateRef<any>;
    }[];

    @Output() public tagClick = new EventEmitter<Item>();

    public constructor(
        private modalService: ModalService,
        private moduleRef: NgModuleRef<any>
    ) {}

    public async showLinkedResourcesDialog(item: LibraryFile): Promise<void> {
        await this.modalService.show(LibraryFileLinkedResourceListComponent, {
            title: item.name,
            moduleRef: this.moduleRef,
            beforeInit: c => {
                c.item = item;
            },
        });
    }
}
