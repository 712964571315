import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { AfterSubmitNavigateTo } from '@masar/common/types';
import { Router } from '@angular/router';

@Injectable()
export class HelperService {
    public constructor(
        private readonly translateService: TranslateService,
        private readonly router: Router
    ) {}

    public afterSubmitNavigationHandler(
        afterSubmitNavigateTo: AfterSubmitNavigateTo,
        link: string[],
        id: string,
        hideCases?: AfterSubmitNavigateTo[]
    ): void {
        switch (afterSubmitNavigateTo) {
            case 'list':
                this.router.navigate(link).then();
                break;
            case 'details':
                this.router.navigate([...link, 'detail', id]).then();
                break;
            case 'new':
                this.router.navigate(link).then(() => {
                    setTimeout(() => {
                        this.router.navigate([...link, 'new']).then();
                    }, 10);
                });
                break;
            case 'ask':
                this.showWhereToGoDialog(link, id, hideCases);
                break;
        }
    }

    private showWhereToGoDialog(
        link: string[],
        id: string,
        hideCases?: AfterSubmitNavigateTo[]
    ): void {
        Swal.fire({
            title: this.translateService.instant(
                'translate_where_do_you_want_to_go'
            ),
            text: this.translateService.instant(
                'translate_the_page_will_be_automatically_redirect_if_no_action_taken'
            ),
            showConfirmButton: !hideCases?.includes('list'),
            confirmButtonText: this.translateService.instant('translate_list'),
            showDenyButton: !hideCases?.includes('new'),
            denyButtonText: this.translateService.instant('translate_add_new'),
            showCancelButton: !hideCases?.includes('details'),
            cancelButtonText:
                this.translateService.instant('translate_details'),
            allowOutsideClick: false,
            timer: 10_000,
            timerProgressBar: true,
        }).then(result => {
            const afterSubmitNavigateTo: AfterSubmitNavigateTo =
                result.isConfirmed
                    ? 'list'
                    : result.isDenied
                    ? 'new'
                    : 'details';

            this.afterSubmitNavigationHandler(afterSubmitNavigateTo, link, id);
        });
    }
}
