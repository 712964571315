<app-list-loading content [items]="items">
    <div class="table-responsive">
        <table>
            <thead>
                <tr>
                    <th>{{ 'translate_parameter' | translate }}</th>
                    <th>{{ 'translate_category' | translate }}</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <ng-container *ngFor="let item of items; let index = index">
                    <tr>
                        <!-- Parameter -->
                        <td class="text-center">
                            {{ item.parameter.toUpperCase() }}
                        </td>

                        <!-- Category -->
                        <td>
                            {{ item.category.name }}
                        </td>

                        <!-- Controls -->
                        <td class="text-center">
                            <div
                                class="flex flex-row items-center justify-center gap-2"
                            >
                                <!-- Expand button -->
                                <button
                                    class="btn btn-sm btn-primary"
                                    (click)="toggle(item)"
                                >
                                    <i class="fa fa-plus"> </i>
                                </button>

                                <!-- Chart button -->
                                <button
                                    class="btn btn-sm btn-primary"
                                    (click)="showChart(item)"
                                >
                                    <i class="fa fa-chart-line"> </i>
                                </button>

                                <!-- Update button -->
                                <button
                                    *appHasPermissionId="
                                        permissionList.kpiResultEntry
                                    "
                                    class="btn btn-sm btn-primary inline-flex flex-row items-center gap-2"
                                    (click)="
                                        showUpdateDialog(
                                            item.category.id,
                                            item.parameter
                                        )
                                    "
                                >
                                    {{ 'translate_update' | translate }}

                                    <i class="fa fa-edit"></i>
                                </button>
                            </div>
                        </td>
                    </tr>

                    <!-- Subcategories & values -->
                    <tr
                        *ngIf="
                            currentlyToggled.has(
                                item.parameter + item.category.id
                            )
                        "
                    >
                        <td colspan="100%" class="p-5">
                            <table>
                                <thead>
                                    <tr class="bg-primary-300">
                                        <th>
                                            {{
                                                'translate_subcategory'
                                                    | translate
                                            }}
                                        </th>
                                        <th>
                                            {{ 'translate_value' | translate }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let subcategory of item.values">
                                        <td>
                                            {{ subcategory.subcategory.name }}
                                        </td>
                                        <td>{{ subcategory.value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</app-list-loading>
