<app-content contentTitle="{{ 'translate_attachments' | translate }}">
    <!-- Tools -->
    <ng-container tools>
        <ng-container
            *appHasPermissionId="
                type === 'kpi-result'
                    ? permissionList.kpiResultEntry
                    : permissionList.kpiResultFlow
            "
        >
            <button
                *ngIf="mode === 'default' && !isPeriodApproved(period)"
                (click)="showCreateAttachmentDialog()"
                class="btn btn-sm btn-outline-white"
            >
                <i class="fa-light fa-plus"></i>
                <span> {{ 'translate_new_attachment' | translate }}</span>
            </button>
        </ng-container>
    </ng-container>

    <!-- Content -->
    <ng-container content>
        <!-- Table -->
        <app-list-loading [items]="items">
            <div class="table-responsive">
                <table class="table-bordered table-hover table">
                    <thead class="thead-dark">
                        <tr>
                            <th>{{ 'translate_type' | translate }}</th>
                            <th>{{ 'translate_name' | translate }}</th>
                            <th *ngIf="!period">
                                {{ 'translate_period' | translate }}
                            </th>
                            <ng-container
                                *appHasPermissionId="
                                    type === 'kpi-result'
                                        ? permissionList.kpiResultEntry
                                        : permissionList.kpiResultFlow
                                "
                            >
                                <th
                                    *ngIf="
                                        mode === 'default' &&
                                        !isPeriodApproved(period)
                                    "
                                ></th>
                            </ng-container>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let item of items; let idx = index">
                            <!-- Icon -->
                            <td>
                                <app-file-icon
                                    [contentType]="item.contentType"
                                ></app-file-icon>
                            </td>

                            <!-- File name -->
                            <td>
                                <a
                                    href="#"
                                    onclick="return false"
                                    (click)="
                                        download(item.id, item.contentType)
                                    "
                                >
                                    {{ item.name }}
                                </a>
                            </td>

                            <!-- Period -->
                            <td *ngIf="!period" class="text-center">
                                <app-kpi-result-attachment-period-label
                                    [period]="item.period"
                                    [periodType]="item.periodType"
                                ></app-kpi-result-attachment-period-label>
                            </td>

                            <!-- Control -->
                            <td
                                *ngIf="
                                    mode === 'default' &&
                                    !isPeriodApproved(period)
                                "
                                style="width: 1%; white-space: nowrap"
                            >
                                <button
                                    *appHasPermissionId="
                                        type === 'kpi-result'
                                            ? permissionList.kpiResultEntry
                                            : permissionList.kpiResultFlow
                                    "
                                    class="btn btn-sm btn-danger"
                                    [appTooltip]="
                                        'translate_delete' | translate
                                    "
                                    [disabled]="
                                        currentlyDeleting.includes(item.id)
                                    "
                                    (confirm)="delete(item)"
                                    [swal]="{
                                        title:
                                            'translate_delete_this_item_question_mark'
                                            | translate,
                                        confirmButtonText:
                                            'translate_yes' | translate,
                                        cancelButtonText:
                                            'translate_cancel' | translate,
                                        showCancelButton: true,
                                        showCloseButton: true
                                    }"
                                >
                                    <i class="fas fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-list-loading>
    </ng-container>
</app-content>
