import { Injectable, NgModuleRef } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    EvaluationInstance,
    EvaluationOverallStatistics,
    EvaluationScoreBandStatistics,
    EvaluationStandardRecord,
    EvaluationStandardRecordValue,
    EvaluationType,
} from '@masar/features/evaluate/types';
import { Observable, Subject } from 'rxjs';
import { miscFunctions, ModalService, Result } from 'mnm-webapp';
import { environment } from '@masar/env/environment';
import { map, takeUntil } from 'rxjs/operators';
import {
    Evaluation,
    EvaluationScoreBand,
    EvaluationStandard,
} from '@masar/common/models';
import { EvaluateItem } from '@masar/features/evaluate/interfaces';
import { EvaluationInstanceListComponent } from '@masar/features/evaluate/components';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class EvaluateService {
    public constructor(
        private readonly httpClient: HttpClient,
        private readonly modalService: ModalService,
        private readonly moduleRef: NgModuleRef<any>,
        private readonly translateService: TranslateService
    ) {}

    public listEvaluations(
        type: EvaluationType,
        savedEntityId?: string
    ): Observable<Evaluation[]> {
        let params = new HttpParams();

        if (savedEntityId) {
            params = params.append('savedEntityId', savedEntityId);
        }

        return this.httpClient
            .get<Result<Evaluation[]>>(
                `${environment.apiUrl}/evaluation/${type}`,
                { params }
            )
            .pipe(map(res => res.extra));
    }

    public getEvaluationStandards(
        type: EvaluationType,
        evaluationId: string
    ): Observable<EvaluationStandard[]> {
        return this.httpClient
            .get<Result<EvaluationStandard[]>>(
                `${environment.apiUrl}/evaluation/${type}/${evaluationId}/standard`
            )
            .pipe(map(res => res.extra));
    }

    public listInstances(
        itemId: string,
        type: EvaluationType
    ): Observable<EvaluationInstance[]> {
        return this.httpClient
            .get<Result<EvaluationInstance[]>>(
                `${environment.apiUrl}/evaluation/instance/${type}/${itemId}`
            )
            .pipe(map(res => res.extra));
    }

    public getInstance(
        id: string,
        type: EvaluationType
    ): Observable<EvaluationInstance> {
        return this.httpClient
            .get<Result<EvaluationInstance>>(
                `${environment.apiUrl}/evaluation/instance/${id}/${type}`
            )
            .pipe(map(res => res.extra));
    }

    public getRecords(
        id: string,
        type: EvaluationType
    ): Observable<EvaluationStandardRecord[]> {
        return this.httpClient
            .get<Result<EvaluationStandardRecord[]>>(
                `${environment.apiUrl}/evaluation/instance/${id}/${type}/record`
            )
            .pipe(map(res => res.extra));
    }

    public createInstance(
        entityId: string,
        type: EvaluationType,
        evaluationId: string,
        values: EvaluationStandardRecordValue[],
        note: string
    ): Observable<EvaluationInstance> {
        return this.httpClient
            .post<Result<EvaluationInstance>>(
                `${environment.apiUrl}/evaluation/${evaluationId}/instance/${type}/${entityId}`,
                miscFunctions.objectToURLParams({
                    note,
                    values: JSON.stringify(values),
                })
            )
            .pipe(map(res => res.extra));
    }

    public updateInstance(
        id: string,
        type: EvaluationType,
        values: EvaluationStandardRecordValue[],
        note: string
    ): Observable<EvaluationInstance> {
        return this.httpClient
            .put<Result<EvaluationInstance>>(
                `${environment.apiUrl}/evaluation/instance/${id}/${type}`,
                miscFunctions.objectToURLParams({
                    note,
                    values: JSON.stringify(values),
                })
            )
            .pipe(map(res => res.extra));
    }

    public deleteInstance(
        id: string,
        type: EvaluationType
    ): Observable<string> {
        return this.httpClient
            .delete<Result>(
                `${environment.apiUrl}/evaluation/instance/${id}/${type}`
            )
            .pipe(map(res => res.messages[0]));
    }

    public listScoreBands(
        evaluationId: string,
        type: EvaluationType
    ): Observable<EvaluationScoreBand[]> {
        return this.httpClient
            .get<Result<EvaluationScoreBand[]>>(
                `${environment.apiUrl}/evaluation/${evaluationId}/score-band/${type}`
            )
            .pipe(map(res => res.extra));
    }

    public statistics(
        evaluationId: string,
        type: EvaluationType
    ): Observable<EvaluationOverallStatistics> {
        return this.httpClient
            .get<Result<EvaluationOverallStatistics>>(
                `${environment.apiUrl}/evaluation/${evaluationId}/statistics/${type}`
            )
            .pipe(map(res => res.extra));
    }

    public scoreBandStatistics(
        id: string,
        type: EvaluationType
    ): Observable<EvaluationScoreBandStatistics[]> {
        return this.httpClient
            .get<Result<EvaluationScoreBandStatistics[]>>(
                `${environment.apiUrl}/evaluation/${id}/statistics/${type}/score-band`
            )
            .pipe(map(res => res.extra));
    }

    public async showEvaluationsList(
        item: EvaluateItem,
        type: EvaluationType,
        title?: string,
        savedEntityId?: string
    ): Promise<Observable<void>> {
        const subject = new Subject();

        title = title || this.translateService.instant('translate_evaluation');

        const component = await this.modalService.show(
            EvaluationInstanceListComponent,
            {
                title,
                beforeInit: c => {
                    c.item = item;
                    c.type = type;
                    c.title = title;
                    c.savedEntityId = savedEntityId;
                },
                moduleRef: this.moduleRef,
                onDismiss: () => {
                    subject.next();
                    subject.complete();
                },
                size: {
                    width: '50%',
                },
            }
        );

        return component.update.pipe(takeUntil(subject));
    }
}
