import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-ring',
    template: `
        <div
            [ngStyle]="{
                height: spinnerDim + 'px',
                width: spinnerDim + 'px'
            }"
        >
            <span class="spinner relative" role="status" aria-hidden="true">
                <span
                    class="spinner-before"
                    [ngStyle]="{ borderWidth: spinnerBorderWidth + 'px' }"
                ></span>
            </span>
        </div>
    `,
    styles: [
        `
            :host {
                @apply inline-block;
            }

            div {
                display: inline-block;
                vertical-align: middle;
            }

            @keyframes spinner {
                to {
                    transform: rotate(360deg);
                }
            }

            .spinner {
                position: relative;
                display: inline-block;
                width: 100%;
                height: 100%;
            }

            .spinner-before {
                content: '';
                box-sizing: border-box;
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 2px solid #ccc;
                border-top-color: #000;
                animation: spinner 0.6s linear infinite;
            }
        `,
    ],
})
export class LoadingRingComponent {
    @Input() public spinnerDim = 20;
    @Input() public spinnerBorderWidth = 2;
}
