import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { AppSettingFetcherService } from '@masar/core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-achieved-container',
    template: `
        <div
            style="
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 50px;
            min-height: 50px;
        "
            *ngIf="achieved === 0 || achieved"
            class="px-2 text-white"
            [ngStyle]="{ backgroundColor: color }"
        >
            {{ achieved * 100 | round : 2 }}%
        </div>
    `,
})
export class AchievedContainerComponent implements OnDestroy, OnChanges {
    @Input() public achieved: number;

    public color: string;
    private unsubscribeAll = new Subject();

    public constructor(
        private appSettingFetcherService: AppSettingFetcherService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['achieved']) this.refresh();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    private refresh(): void {
        this.unsubscribeAll.next();

        this.appSettingFetcherService.get$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ kpiSetting }) => {
                for (const coding of kpiSetting.achievementColorCodings) {
                    if (
                        (coding.min === null || coding.min <= this.achieved) &&
                        (coding.max === null || this.achieved < coding.max)
                    ) {
                        this.color = coding.color;

                        return;
                    }
                }

                this.color = '#aaaaaa';
            });
    }
}
