<div
    id="{{ uuid }}"
    class="relative flex flex-col gap-2"
    [ngClass]="[displayMode === 'light' ? ' bg-primary' : 'bg-white']"
>
    <div
        *ngIf="enabledFilter"
        class="sticky top-0 z-10 p-2 shadow"
        [ngClass]="[displayMode === 'light' ? ' bg-primary' : 'bg-white']"
    >
        <input
            class="w-full rounded-md !border-0 bg-transparent px-4 outline-none ring-0 focus:ring-0"
            placeholder="{{ 'translate_search' | translate }}"
            type="text"
            #search
            (keyup)="filterItems(search.value)"
        />
    </div>

    <ul class="px-2 pb-2">
        <ng-container
            [ngTemplateOutlet]="itemsListTemplate"
            [ngTemplateOutletContext]="{
                items: filteredItems
            }"
        ></ng-container>
    </ul>
</div>

<!-- The main items list template -->
<ng-template #itemsListTemplate let-items="items">
    <ng-container *ngFor="let item of items">
        <ng-container
            *ngIf="
                !item.isHidden &&
                (!item.isShownOnlyInSafeMode || safeModeService.isEnabled)
            "
            [ngTemplateOutlet]="
                item.type === 'item'
                    ? itemTemplate
                    : item.type === 'expandable'
                    ? expandableItemTemplate
                    : sectionItemTemplate
            "
            [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
    </ng-container>
</ng-template>

<!-- Expandable item template -->
<ng-template #expandableItemTemplate let-item="item">
    <li *appHasAnyPermissionId="item.permissionIdList" class="sidebar-item">
        <ng-container
            [ngTemplateOutlet]="linkItemTemplate"
            [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
        <ul
            #ul
            class="sidebar-children overflow-hidden"
            [@slide]="{
                value: item.isOpen,
                params: { height: ul.clientHeight }
            }"
            [ngClass]="{
                'h-auto': item.isOpen,
                'h-0': !item.isOpen
            }"
        >
            <div class="rounded-md bg-primary-800 p-2">
                <ng-container
                    [ngTemplateOutlet]="itemsListTemplate"
                    [ngTemplateOutletContext]="{
                        items: item.children
                    }"
                ></ng-container>
            </div>
        </ul>
    </li>
</ng-template>

<!-- Link item template -->
<ng-template #itemTemplate let-item="item">
    <li
        *appHasAnyPermissionId="item.permissionIdList"
        class="sidebar-item-child"
    >
        <ng-container
            [ngTemplateOutlet]="linkItemTemplate"
            [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
    </li>
</ng-template>

<!-- Section item template -->
<ng-template #sectionItemTemplate let-item="item">
    <ng-container *ngIf="!isSidebarCollapsed">
        <li
            *appHasAnyPermissionId="item.permissionIdList"
            class="p-3 text-sm opacity-40"
            [ngClass]="{
                'text-white': displayMode === 'light',
                'text-primary': displayMode === 'dark'
            }"
        >
            {{ item.title }}
        </li>
    </ng-container>
    <ng-container
        [ngTemplateOutlet]="itemsListTemplate"
        [ngTemplateOutletContext]="{
            items: item.children
        }"
    ></ng-container>
</ng-template>

<!-- Link item template -->
<ng-template #linkItemTemplate let-item="item">
    <a
        *ngIf="item.type === 'expandable'; else notExpandableTemplate"
        href="javascript:void(0)"
        [class.active]="item.isOpen || item.isActive"
        (click)="toggleExpandableItem(item)"
        [appTooltip]="item.title"
        class="relative flex items-center rounded-md p-2 no-underline transition-all hover:no-underline {{
            displayMode
        }}"
        [ngClass]="isSidebarCollapsed ? 'justify-center' : 'justify-between'"
    >
        <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
    </a>

    <ng-template #notExpandableTemplate>
        <a
            [class.active]="item.isOpen || item.isActive"
            [routerLink]="$any(item.type === 'item' ? item.link : null)"
            [appTooltip]="item.title"
            class="nav-item-link mb-0.5 relative flex items-center rounded-md p-2 no-underline transition-all hover:no-underline {{
                displayMode
            }}"
            [ngClass]="
                isSidebarCollapsed ? 'justify-center' : 'justify-between'
            "
        >
            <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
        </a>
    </ng-template>

    <ng-template #linkContent>
        <div>
            <em [ngClass]="item.icon || 'fa-light fa-circle-small'"></em>
            <span *ngIf="!isSidebarCollapsed" class="font-semibold ms-3">
                {{ item.title }}
            </span>
        </div>
        <div class="flex items-center gap-2">
            <span
                *ngIf="item.badgeCount"
                class="link-notification animate__animated animate__infinite animate__flash animate__slow flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs font-semibold text-white ms-2 dark:bg-green-200 dark:text-green-800"
            >
                {{ item.badgeCount }}
            </span>
            <em
                *ngIf="!isSidebarCollapsed && item.children?.length"
                class="fa-solid fa-angle-down block transform transition-all ms-auto"
                [class.rotate-180]="item.isOpen"
            ></em>
        </div>
    </ng-template>
</ng-template>
