import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { miscFunctions, Result } from 'mnm-webapp';
import { environment } from '@masar/env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibraryTag } from '@masar/common/models';
import { TableResult } from '@masar/common/misc/table';

@Injectable()
export class LibraryTagService {
    public constructor(private httpClient: HttpClient) {}

    public list(
        keyword?: string,
        notInIds?: string[],
        pageNumber: number = 0,
        pageSize: number = 20
    ): Observable<TableResult<LibraryTag>> {
        let params = new HttpParams();

        params = params.append('keyword', keyword);
        notInIds?.forEach(x => (params = params.append('notInIds', x)));
        params = params.append('pageNumber', `${pageNumber}`);
        params = params.append('pageSize', `${pageSize}`);

        return this.httpClient
            .get<Result<TableResult<LibraryTag>>>(
                environment.apiUrl + '/library-tag',
                { params }
            )
            .pipe(map(result => result.extra));
    }

    public create(libraryTag: LibraryTag): Observable<LibraryTag> {
        return this.httpClient
            .post<Result<LibraryTag>>(
                environment.apiUrl + '/library-tag',
                miscFunctions.objectToURLParams({
                    libraryTag: JSON.stringify(libraryTag),
                })
            )
            .pipe(map(result => result.extra));
    }

    public update(libraryTag: LibraryTag): Observable<LibraryTag> {
        return this.httpClient
            .put<Result<LibraryTag>>(
                environment.apiUrl + '/library-tag',
                miscFunctions.objectToURLParams({
                    libraryTag: JSON.stringify(libraryTag),
                })
            )
            .pipe(map(result => result.extra));
    }

    public get(id: string, forEdit: boolean = false): Observable<LibraryTag> {
        return this.httpClient
            .get<Result<LibraryTag>>(
                environment.apiUrl + '/library-tag/' + id,
                { params: new HttpParams().append('forEdit', `${forEdit}`) }
            )
            .pipe(map(result => result.extra));
    }

    public delete(id: string): Observable<string> {
        return this.httpClient
            .delete<Result<any>>(environment.apiUrl + '/library-tag/' + id)
            .pipe(map(result => result.messages[0]));
    }
}
