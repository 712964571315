import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Result } from 'mnm-webapp';
import { environment } from '@masar/env/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class SidebarNotificationService {
    public constructor(private httpClient: HttpClient) {}

    public getMyTasksCount(): Observable<number> {
        return this.httpClient
            .get<Result<number>>(`${environment.apiUrl}/standard/my-task-count`)
            .pipe(map(res => res.extra));
    }
}
