import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    NzFormatEmitEvent,
    NzTreeNode,
    NzTreeNodeOptions,
} from 'ng-zorro-antd/tree';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Item } from '@masar/common/models';
import { NzTreeSelectComponent } from 'ng-zorro-antd/tree-select';

// TODO: this class has got many issues, should be reviewed!!
@Component({
    selector: 'app-tree-select',
    templateUrl: './tree-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TreeSelectComponent),
            multi: true,
        },
    ],
})
export class TreeSelectComponent implements OnInit, ControlValueAccessor {
    @Input() public childrenFetcher: (parentId: any) => Observable<any>;

    @Input() public parentFetcher: (
        childId: string
    ) => Observable<{ parent: Item; children: Item[] }>;

    @Input() public nodes: NzTreeNodeOptions[] = [];

    @Input() public isMultiple: boolean = false;

    @ViewChild(NzTreeSelectComponent)
    private readonly treeSelectComponent: NzTreeSelectComponent;

    public expandKeys = [];

    public selectedNode: NzTreeNodeOptions | any;

    public onTouched: () => void;

    public ngOnInit(): void {
        if (this.selectedNode) {
            this.loadInitialData();
        }
    }

    public writeValue(selectedNode: NzTreeNodeOptions): void {
        if (!selectedNode) return this.loadInitialData();

        this.selectedNode = selectedNode['id'];

        this.loadParentHierarchy(this.selectedNode).subscribe(parents => {
            // filter of parent not null
            const parent = parents.filter(x => x.parent);

            if (parent.length > 0) {
                this.nodes = this.buildTree(parent);
            }
        });
    }

    public registerOnChange(
        fn: (selectedNode: NzTreeNodeOptions) => void
    ): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public onChange(newValue: any): void {
        this.selectedNode = newValue;
    }

    public reportChange(): void {
        const selections = this.treeSelectComponent.getSelectedNodeList();
        const data = !selections
            ? undefined
            : this.isMultiple
            ? selections.map(x => x.origin)
            : selections[0]?.origin;

        this.onChange(data);
    }

    public displayNodeName(node: NzTreeNode): string | undefined {
        return node.origin['name'];
    }

    public onExpandChange(event: NzFormatEmitEvent): void {
        const node = event.node;

        if (node && node.isExpanded && node.origin['childCount'] > 0) {
            this.loadChildNodes(node);
        }
    }

    private loadInitialData(): void {
        this.childrenFetcher(this.selectedNode ?? null).subscribe(data => {
            this.nodes = data.map(
                (node: { id: string; childCount: number }) => ({
                    ...node,
                    key: `${node.id}`, // Ensure each child has a unique key
                    isLeaf: node?.childCount <= 0,
                })
            );
        });
    }

    private loadParentHierarchy(
        childId: string
    ): Observable<{ parent: Item; children: Item[] }[]> {
        return this.parentFetcher(childId).pipe(
            switchMap(parentData =>
                parentData.parent?.id
                    ? this.loadParentHierarchy(parentData.parent.id).pipe(
                          switchMap(parentHierarchy => {
                              parentHierarchy.push(parentData);
                              return of(parentHierarchy);
                          })
                      )
                    : of([parentData])
            )
        );
    }

    private buildTree(
        parents: { parent: any; children: any }[]
    ): NzTreeNodeOptions[] {
        let root: NzTreeNodeOptions | null = null;
        let currentLevel: NzTreeNodeOptions[] = [];

        parents.forEach((level, index) => {
            this.expandKeys.push(level.parent?.id);
            const parentNode: NzTreeNodeOptions = {
                ...level.parent,
                key: `${level.parent?.id}`,
                isLeaf: level.parent?.childCount <= 0,
                children: level.children.map(
                    (child: { id: string; childCount: number }) => ({
                        ...child,
                        key: `${child?.id}`,
                        isLeaf: child?.childCount <= 0,
                    })
                ),
            };

            if (index === 0) {
                root = parentNode;
                currentLevel = root.children;
            } else {
                const parent = currentLevel.find(
                    node => node.key === `${level.parent.id}`
                );
                if (parent) {
                    parent.children = parentNode.children;
                    currentLevel = parent.children;
                }
            }
        });
        return root ? [root] : [];
    }

    private loadChildNodes(node: NzTreeNodeOptions): void {
        if (node.children.length === 0) {
            this.childrenFetcher(node.key).subscribe(data => {
                const children = data.map(
                    (child: { id: string; childCount: number }) => ({
                        ...child,
                        key: `${child.id}`, // Ensure each child has a unique key
                        isLeaf: child.childCount <= 0,
                    })
                );
                node.addChildren(children);
            });
        }
    }
}
