<div class="mb-4 rounded bg-secondary-50 p-4">
    <h4 class="mb-2 text-lg font-bold text-primary">
        {{ 'translate_filter_results' | translate }}
    </h4>

    <!-- Filter fields -->
    <div
        class="grid grid-cols-1 items-center gap-2 md:grid-cols-3 lg:grid-cols-4"
    >
        <ng-content></ng-content>
    </div>
</div>
