export const getNestedProperty = <T>(
    object: T,
    propertyName?: string
): T | any => {
    if (!propertyName) return object;

    const properties = propertyName.split('.');

    let propertyValue = object;

    for (const property of properties) {
        if (propertyValue) propertyValue = propertyValue[property];
        else return null;
    }

    return propertyValue;
};
