import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

@Directive({
    selector: '[appWaitUntilLoaded]',
})
export class WaitUntilLoadedDirective {
    @Input() public set appWaitUntilLoaded(data: any) {
        if (data) {
            this.viewContainerRef.clear();
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
        if (!data) {
            this.viewContainerRef.clear();
            this.viewContainerRef.createComponent(this.componentFactory);
        }
    }

    private componentFactory: ComponentFactory<WaitUntilLoadedLoadingComponent>;

    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        this.componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                WaitUntilLoadedLoadingComponent
            );
    }
}

@Component({
    selector: 'app-wait-until-loaded-loading',
    template: ` <app-loading></app-loading> `,
})
export class WaitUntilLoadedLoadingComponent {}
