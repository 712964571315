import {
    Directive,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PermissionService } from '@masar/core/services';

@Directive({
    selector: '[appHasAnyPermissionId]',
})
export class HasAnyPermissionIdDirective implements OnDestroy {
    @Input() public set appHasAnyPermissionId(permissionIdList: string[]) {
        // End previous subscription.
        this.permissionChangesSubscription?.unsubscribe();

        // A callback to hide or show the element.
        const callback = (
            hasPermission: boolean,
            alternateTemplate?: TemplateRef<any>
        ): void => {
            if (hasPermission && this.mode !== 'visible') {
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.mode = 'visible';
            } else if (
                !hasPermission &&
                alternateTemplate &&
                this.mode !== 'alternate'
            ) {
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(alternateTemplate);
                this.mode = 'alternate';
            } else if (
                !hasPermission &&
                !alternateTemplate &&
                this.mode !== 'invisible'
            ) {
                this.viewContainer.clear();
                this.mode = 'invisible';
            }
        };

        // If the array is empty, then return
        // assume the user has permission
        if (!permissionIdList || permissionIdList.length === 0) {
            callback(true);
            return;
        }

        // otherwise check if the user has any
        // the permissions inside the `any` field.
        this.permissionChangesSubscription = combineLatest([
            this.alternateTemplate$,
            ...permissionIdList.map(x =>
                this.permissionService.ensureUserHasPermission(x)
            ),
        ])
            .pipe(
                takeUntil(this.unsubscribeAll),
                map(result => {
                    return {
                        alternativeTemplate: result[0] as TemplateRef<any>,
                        hasPermission: result.slice(1).some(x => x),
                    };
                })
            )
            .subscribe(({ hasPermission, alternativeTemplate }) => {
                callback(hasPermission, alternativeTemplate);
            });
    }

    @Input() public set appHasAnyPermissionIdElse(
        templateRef: TemplateRef<any>
    ) {
        this.alternateTemplate$.next(templateRef);
    }

    private permissionChangesSubscription: Subscription;

    // A fallback template subject in case the user
    // does not have the permission.
    private alternateTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);

    private unsubscribeAll = new Subject();

    // Visible: the highlighted element is shown.
    // Invisible: the highlighted element and alternate
    // template are not shown.
    // Alternate: the alternative template when the the
    // user does not have permissions is shown.
    private mode: 'visible' | 'invisible' | 'alternate' = 'invisible';

    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.permissionChangesSubscription?.unsubscribe();
        this.alternateTemplate$.complete();
    }
}
