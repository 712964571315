import { Component, Input } from '@angular/core';
import { LibraryFile } from '@masar/common/models';

@Component({
    selector: 'app-library-file-icon',
    template: `
        <app-file-icon
            [contentType]="
                !libraryFile.hasFile && libraryFile.link
                    ? 'url'
                    : libraryFile.contentType
            "
        ></app-file-icon>
    `,
})
export class LibraryFileIconComponent {
    @Input() public libraryFile: LibraryFile;
}
