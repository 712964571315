import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { miscFunctions } from 'mnm-webapp';

interface WrappedItem<T> {
    id: string;
    item: T;
}

@Component({
    selector: 'app-list-field',
    templateUrl: './list-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ListFieldComponent),
        },
    ],
})
export class ListFieldComponent<T> implements ControlValueAccessor {
    @Input() public itemTemplate: TemplateRef<any>;

    // Used to determine which of the items are filled
    // correctly
    @Input() public validItems: (items: T[]) => T[];

    // At least one property in an item is filled
    // to be considered as valid.
    @Input() public useDefaultValidItems: boolean = false;

    public wrappedItems: WrappedItem<T>[] = [];

    public isDisabled: boolean = false;

    public onChange: (items: T[]) => void;

    public constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public emitChange = (): void => {
        if (!this.onChange) return;

        const items = this.validItems
            ? this.validItems(this.wrappedItems.map(x => x.item))
            : this.useDefaultValidItems
            ? this.wrappedItems
                  .map(x => x.item)

                  // Default valid items: filters out
                  // items that all of its properties are
                  // undefined or null
                  .filter(x => {
                      for (const prop in x) {
                          if (Object.prototype.hasOwnProperty.call(x, prop)) {
                              if (x[prop]) {
                                  return true;
                              }
                          }
                      }

                      return false;
                  })
            : this.wrappedItems.map(x => x.item);

        this.onChange(items);
    };

    public addItem(): void {
        this.wrappedItems.push({
            id: miscFunctions.uuid(),
            item: {} as any,
        } as any);
        this.emitChange();
    }

    public removeItem(wrappedItem: WrappedItem<T>): void {
        const idx = this.wrappedItems.findIndex(x => x.id === wrappedItem.id);
        if (idx !== -1) {
            this.wrappedItems.splice(idx, 1);
        }
        this.emitChange();
    }

    public writeValue(obj: T[]): void {
        this.wrappedItems =
            obj?.map(x => ({ id: miscFunctions.uuid(), item: x })) ?? [];
        if (this.wrappedItems.length === 0) {
            this.addItem();
        }

        this.changeDetectorRef.detectChanges();

        this.emitChange();
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
        this.emitChange();
    }

    public registerOnTouched(): void {
        return undefined;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
