<div
    class="rounded-b border-t-4 px-4 py-3 shadow-md"
    [ngClass]="{
        'border-primary-500 bg-primary-100 text-primary-900':
            mode === 'default',
        'border-green-500 bg-green-100 text-green-900': mode === 'success',
        'border-blue-500 bg-blue-100 text-blue-900': mode === 'info',
        'border-amber-500 bg-amber-100 text-amber-900': mode === 'warning',
        'border-red-500 bg-red-100 text-red-900': mode === 'danger'
    }"
    role="alert"
>
    <div class="flex items-center gap-2">
        <div class="py-1">
            <i
                class="fa text-2xl"
                [ngClass]="{
                    'fa-lightbulb-on': mode === 'default',
                    'fa-circle-check': mode === 'success',
                    'fa-circle-info': mode === 'info',
                    'fa-circle-exclamation': mode === 'warning',
                    'fa-circle-xmark': mode === 'danger'
                }"
            ></i>
        </div>

        <div class="flex-1">
            <p class="font-bold">
                {{ label | translate }}
            </p>
            <p class="text-sm" *ngIf="description">
                {{ description | translate }}
            </p>
        </div>

        <div class="flex flex-row items-center gap-2">
            <ng-content select="[tools]"></ng-content>
        </div>
    </div>
</div>
