import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class YearService {
    private static localStorageKey: string = 'year';

    private valueSubject$: BehaviorSubject<number>;

    public constructor() {
        // Load value from cache.
        const year =
            parseInt(localStorage.getItem(YearService.localStorageKey)) ||
            new Date().getFullYear();

        // Set it!.
        this.valueSubject$ = new BehaviorSubject<number>(year);
    }

    public get(): number {
        return this.valueSubject$.value;
    }

    public set(year: number): void {
        // Ensure that the year is not
        // the same.
        if (year === this.valueSubject$.value) {
            return;
        }

        // Cache value to local storage.
        localStorage.setItem(YearService.localStorageKey, `${year}`);

        this.valueSubject$.next(year);
    }

    public get changes$(): Observable<number> {
        return this.valueSubject$.asObservable();
    }
}
