import {
    Component,
    EventEmitter,
    Input,
    NgModuleRef,
    OnInit,
    Output,
} from '@angular/core';
import { ModalService, NotificationService } from 'mnm-webapp';
import { LibraryFileLinkerComponent } from '@masar/features/masar/components';
import { permissionList } from '@masar/common/constants';
import { TableResult } from '@masar/common/misc/table';
import { KpiResultCapability, LibraryFile } from '@masar/common/models';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { KpiResultCapabilityService } from '../../../kpi-result-capability.service';
import { KpiResultDataEntryCapabilityService } from '../../../kpi-result-data-entry-capability.service';
import { CapabilityNewComponent } from '../capability-new/capability-new.component';

@Component({
    selector: 'app-kpi-capability-list',
    templateUrl: './capability-list.component.html',
})
export class CapabilityListComponent implements OnInit {
    @Input() public periodId: string;
    @Input() public mode: 'default' | 'view' = 'default';
    @Input() public type!: 'kpi-result' | 'data-entry-response';

    @Output() public update = new EventEmitter<void>();

    public items: KpiResultCapability[];

    public currentlyDeleting: string[] = [];

    public mainService:
        | KpiResultCapabilityService
        | KpiResultDataEntryCapabilityService;

    public permissionList = permissionList;

    public constructor(
        private capabilityService: KpiResultCapabilityService,
        private kpiResultDataEntryCapabilityService: KpiResultDataEntryCapabilityService,
        private notificationService: NotificationService,
        private modalService: ModalService,
        private moduleRef: NgModuleRef<any>
    ) {}

    public ngOnInit(): void {
        this.mainService =
            this.type === 'kpi-result'
                ? this.capabilityService
                : this.kpiResultDataEntryCapabilityService;

        this.mainService
            .list(this.periodId)
            .subscribe(items => (this.items = items));
    }

    public delete(item: KpiResultCapability): void {
        // add the id of the item to the being deleted array
        // to disable the delete button in the list.
        this.currentlyDeleting.push(item.id);
        this.mainService
            .delete(item.id)
            .pipe(
                finalize(() => {
                    // remove the deleted item id from the being deleted
                    // list when the deletion is complete.
                    this.currentlyDeleting = this.currentlyDeleting.filter(
                        x => x !== item.id
                    );
                })
            )
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.items = this.items.filter(x => x.id !== item.id);
                this.update.emit();
            });
    }

    public addCapability(item: KpiResultCapability): void {
        this.items.push(item);
    }

    public updateCapability(item: KpiResultCapability): void {
        const index = this.items.findIndex(x => x.id === item.id);
        if (index === -1) {
            return;
        }
        this.items.splice(index, 1, item);
    }

    // LIBRARY FILE METHODS
    public listLinkedLibraryFiles(
        item: KpiResultCapability
    ): (
        keyword: string,
        pageNumber: number,
        pageSize: number
    ) => Observable<TableResult<LibraryFile>> {
        return (keyword: string, pageNumber: number, pageSize: number) =>
            this.mainService.listLinkedLibraryFiles(
                item.id,
                keyword,
                pageNumber,
                pageSize
            );
    }

    public linkLibraryFile(
        item: KpiResultCapability
    ): (libraryFileId: string) => Observable<string> {
        return (libraryFileId: string) =>
            this.mainService.linkLibraryFile(item.id, libraryFileId);
    }

    public unlinkLibraryFile(
        item: KpiResultCapability
    ): (libraryFileId: string) => Observable<string> {
        return (libraryFileId: string) =>
            this.mainService.unlinkLibraryFile(item.id, libraryFileId);
    }

    public async showCreateCapabilityDialog(
        capabilityId: string = null
    ): Promise<void> {
        const subject = new Subject();
        const component = await this.modalService.show(CapabilityNewComponent, {
            moduleRef: this.moduleRef,
            onDismiss: () => {
                subject.next();
                subject.complete();
            },
            beforeInit: c => {
                c.periodId = this.periodId;
                c.mode = capabilityId ? 'edit' : 'new';
                c.capabilityId = capabilityId;
                c.type = this.type;
            },
        });

        component.capabilityCreated.pipe(takeUntil(subject)).subscribe(c => {
            this.addCapability(c);
            this.modalService.dismiss(component);
            this.update.emit();
        });

        component.capabilityUpdated.pipe(takeUntil(subject)).subscribe(c => {
            this.updateCapability(c);
            this.modalService.dismiss(component);
            this.update.emit();
        });
    }

    public async showLinkedLibrary(
        selectedItem: KpiResultCapability
    ): Promise<void> {
        await this.modalService.show(LibraryFileLinkerComponent, {
            beforeInit: c => {
                c.listLinkedFilesCallback =
                    this.listLinkedLibraryFiles(selectedItem).bind(c);

                c.linkingCallback = this.linkLibraryFile(selectedItem).bind(c);

                c.unlinkingCallback =
                    this.unlinkLibraryFile(selectedItem).bind(c);
                c.mode = this.mode;
            },
        });
    }
}
