import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    AssignedEntityComponent,
    ContentComponent,
    FieldsetComponent,
    FileIconComponent,
    FileViewerComponent,
    FilterResultBoxComponent,
    ItemFieldComponent,
    ItemListFieldComponent,
    KpiCodeBadgeComponent,
    KpiLinkComponent,
    KpiResultAchievedChartComponent,
    LibraryFileFieldComponent,
    LibraryFileIconComponent,
    LibraryFileLinkedResourceListComponent,
    LibraryFileLinkerComponent,
    LibraryFileListComponent,
    LibraryFileListFullComponent,
    LibraryFileNameComponent,
    ListFieldComponent,
    NewFileDialogComponent,
    NewUserRequestDialogComponent,
    PageComponent,
    PermissionFieldComponent,
    SearchFormComponent,
    PeriodButtonComponent,
    KpiResultTargetChartComponent,
} from './components';
import { SharedModule } from '@masar/shared/shared.module';
import {
    ExtractKpiResultPipe,
    FormatKpiResultPipe,
    TranslateItemPipe,
} from './pipes';
import { MnmWebappModule } from 'mnm-webapp';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgSelectModule } from '@ng-select/ng-select';
import { InnovationListComponent } from './components/innovation-list/innovation-list.component';
import { InnovationLinkerComponent } from './components/innovation-linker/innovation-linker.component';
import { KpiResultsComponent } from './components/kpi-results/kpi-results.component';
import { AchievedContainerComponent } from './components/achieved-container/achieved-container.component';
import { PeriodTabListComponent } from './components/period-tab-list/period-tab-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslationModule } from '@ng-omar/translation';
import { KpiResultTargetPeriodsChartComponent } from './components/kpi-result-target-periods-chart/kpi-result-target-periods-chart.component';
import {
    SharedCapabilityService,
    SharedKpiResultService,
    SharedKpiService,
    SharedLibraryFileService,
    SharedInnovationService,
} from '@masar/shared/services';
import { FormatDistanceToNowPipeModule } from 'ngx-date-fns';
import { ServiceLinkComponent } from './components/service-link/service-link.component';

const sharedComponents = [
    NewFileDialogComponent,
    LibraryFileNameComponent,
    LibraryFileLinkedResourceListComponent,
    LibraryFileListComponent,
    SearchFormComponent,
    LibraryFileIconComponent,
    LibraryFileLinkerComponent,
    KpiResultAchievedChartComponent,
    KpiResultTargetChartComponent,
    LibraryFileFieldComponent,
    ItemFieldComponent,
    ItemListFieldComponent,
    ListFieldComponent,
    PermissionFieldComponent,
    ContentComponent,
    FilterResultBoxComponent,
    PageComponent,
    FieldsetComponent,
    FileIconComponent,
    NewUserRequestDialogComponent,
    InnovationListComponent,
    InnovationLinkerComponent,
    KpiResultsComponent,
    AchievedContainerComponent,
    PeriodTabListComponent,
    LibraryFileListFullComponent,
    AssignedEntityComponent,
    KpiCodeBadgeComponent,
    PeriodButtonComponent,
    KpiLinkComponent,
    KpiResultTargetPeriodsChartComponent,
    ServiceLinkComponent,
];

const sharedPipes = [
    ExtractKpiResultPipe,
    TranslateItemPipe,
    FormatKpiResultPipe,
];

@NgModule({
    imports: [
        CommonModule,
        TranslationModule,
        FormsModule,
        SharedModule,
        MnmWebappModule,
        RouterModule,
        SweetAlert2Module,
        NgSelectModule,
        DragDropModule,
        FormatDistanceToNowPipeModule,
    ],
    declarations: [FileViewerComponent, sharedComponents, sharedPipes],
    exports: [sharedComponents, sharedPipes],
})
export class MasarModule {
    public static forRoot(): ModuleWithProviders<MasarModule> {
        return {
            ngModule: MasarModule,
            providers: [
                SharedKpiService,
                SharedCapabilityService,
                SharedLibraryFileService,
                SharedKpiResultService,
                SharedInnovationService,
            ],
        };
    }
}
