<ng-container
    *ngIf="items; then itemsDidLoad; else itemsAreLoading"
></ng-container>

<ng-template #itemsAreLoading>
    <app-loading class="block text-center text-lg"></app-loading>
</ng-template>

<ng-template #itemsDidLoad>
    <ng-container
        *ngIf="!items.length; then itemsAreUnavailable; else itemsAreAvailable"
    ></ng-container>
</ng-template>

<ng-template #itemsAreUnavailable>
    <app-items-are-unavailable
        [itemsAreUnavailableMessage]="itemsAreUnavailableMessage"
    ></app-items-are-unavailable>
</ng-template>

<ng-template #itemsAreAvailable>
    <ng-content></ng-content>
</ng-template>
