<div class="table-responsive">
    <table>
        <thead>
            <tr>
                <!-- Periods -->
                <th>{{ 'translate_periods' | translate }}</th>

                <!-- Target -->
                <th *ngIf="!kpiResult.kpi.isTrend">
                    <p>{{ 'translate_target' | translate }}</p>
                    <p
                        class="mx-auto max-w-xs text-xs text-gray-400"
                        [ngSwitch]="kpiResult.kpi.direction"
                    >
                        <span *ngSwitchCase="'decrease'">
                            {{ 'translate_decrease_is_best' | translate }}
                        </span>
                        <span *ngSwitchCase="'increase'">
                            {{ 'translate_decrease_is_best' | translate }}
                        </span>
                    </p>
                </th>

                <!-- A -->
                <th>
                    <p>{{ 'translate_a_value' | translate }}</p>
                    <p
                        class="mx-auto max-w-xs whitespace-normal text-xs text-gray-400"
                    >
                        {{ kpiResult.formulaDescriptionA }}
                    </p>
                    <small
                        *ngIf="kpiResult.kpi.dataEntryMethod === 'dynamic'"
                        class="text-xs text-gray-400"
                    >
                        {{
                            'translate_data_entry_disabled_dynamic_data_entry_only'
                                | translate
                        }}
                    </small>
                </th>

                <!-- B -->
                <th *ngIf="formula?.includes('B')">
                    <p>{{ 'translate_b_value' | translate }}</p>
                    <p
                        class="mx-auto max-w-xs whitespace-normal text-xs text-gray-400"
                    >
                        {{ kpiResult.formulaDescriptionB }}
                    </p>
                    <small
                        *ngIf="kpiResult.kpi.dataEntryMethod === 'dynamic'"
                        class="text-xs text-gray-400"
                    >
                        {{
                            'translate_data_entry_disabled_dynamic_data_entry_only'
                                | translate
                        }}
                    </small>
                </th>

                <ng-container *ngIf="mode === 'edit' && periods[0]?.id">
                    <!-- Result -->
                    <th>
                        {{ 'translate_result' | translate }}
                    </th>

                    <!-- Achieved -->
                    <th *ngIf="!kpiResult.kpi.isTrend">
                        {{ 'translate_achieved' | translate }}
                    </th>

                    <!-- Actions -->
                    <th></th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let period of periods; trackBy: trackByIndex"
                [class.bg-secondary-100]="
                    kpiResult.currentPeriod > period.period
                "
            >
                <!-- Periods -->
                <td class="text-center font-bold">
                    <ng-container [ngSwitch]="measurementCycle">
                        <ng-container *ngSwitchCase="'semi_annual'">
                            {{ 'translate_year_half' | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'quarter'">
                            {{ 'translate_quarter' | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'month'">
                            {{ 'translate_month' | translate }}
                        </ng-container>
                    </ng-container>
                    {{ period.period + 1 }}
                </td>

                <!-- Target -->
                <td *ngIf="!kpiResult.kpi.isTrend">
                    <input
                        dir="ltr"
                        class="w-full text-center"
                        type="number"
                        [disabled]="
                            type === 'data-entry-response' ||
                            period.isApproved ||
                            (permissionList.kpiResultEntry
                                | hasPermissionId
                                | async) !== true
                        "
                        [(ngModel)]="period.target"
                        (ngModelChange)="period.target = $event"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </td>

                <!-- A -->
                <td>
                    <input
                        dir="ltr"
                        type="number"
                        [(ngModel)]="period.a"
                        [ngModelOptions]="{ standalone: true }"
                        class="w-full text-center"
                        [disabled]="
                            type === 'data-entry-response'
                                ? currentTransferAssignee !== 'data_entry' ||
                                  readOnly
                                : period.isApproved ||
                                  inputModeA === 'auto' ||
                                  (permissionList.kpiResultEntry
                                      | hasPermissionId
                                      | async) !== true ||
                                  kpiResult.kpi.dataEntryMethod === 'dynamic'
                        "
                    />
                </td>

                <!-- B -->
                <td *ngIf="formula.includes('B')">
                    <input
                        dir="ltr"
                        type="number"
                        [(ngModel)]="period.b"
                        [ngModelOptions]="{ standalone: true }"
                        class="w-full text-center"
                        [disabled]="
                            type === 'data-entry-response'
                                ? currentTransferAssignee !== 'data_entry' ||
                                  readOnly
                                : period.isApproved ||
                                  inputModeB === 'auto' ||
                                  (permissionList.kpiResultEntry
                                      | hasPermissionId
                                      | async) !== true ||
                                  kpiResult.kpi.dataEntryMethod === 'dynamic'
                        "
                    />
                </td>

                <ng-container *ngIf="mode === 'edit' && periods[0]?.id">
                    <!-- Result -->
                    <td class="text-center">
                        {{
                            period.result
                                | round : 2
                                | formatKpiResult : units : unitsDescription
                        }}
                    </td>

                    <!-- Achieved -->
                    <td *ngIf="!kpiResult.kpi.isTrend">
                        <app-achieved-container
                            [achieved]="period.achieved"
                        ></app-achieved-container>
                    </td>

                    <!-- Actions -->
                    <td>
                        <div class="flex items-center justify-center gap-2">
                            <!-- Result Analysis -->
                            <app-period-button
                                title="translate_result_analysis"
                                icon="fa-magnifying-glass-chart"
                                [isDone]="!!period.resultAnalysis"
                                [isDisabled]="isSubmitting"
                                [callbackFunction]="
                                    showResultAnalysisDialog.bind(this, period)
                                "
                            ></app-period-button>

                            <!-- Improvement Procedures -->
                            <app-period-button
                                title="translate_improvement_procedures"
                                icon="fa-arrow-up-right-dots"
                                [isDone]="!!period.improvementProcedure"
                                [isDisabled]="isSubmitting"
                                [callbackFunction]="
                                    showImprovementProceduresDialog.bind(
                                        this,
                                        period
                                    )
                                "
                                [progress]="
                                    period.improvementProcedureCompletionPercentage
                                "
                            ></app-period-button>

                            <!-- Attachments -->
                            <app-period-button
                                title="translate_attachments"
                                icon="fa-paperclip"
                                [isDone]="period.attachmentCount > 0"
                                [isDisabled]="isSubmitting"
                                [callbackFunction]="
                                    showAttachmentListDialog.bind(this, period)
                                "
                                [count]="period.attachmentCount"
                            ></app-period-button>

                            <!-- Capabilities -->
                            <app-period-button
                                title="translate_capabilities"
                                icon="fa-screwdriver-wrench"
                                [isDone]="period.capabilityCount > 0"
                                [isDisabled]="isSubmitting"
                                [callbackFunction]="
                                    showCapabilityListDialog.bind(this, period)
                                "
                                [count]="period.capabilityCount"
                            ></app-period-button>

                            <ng-container *ngIf="type === 'kpi-result'">
                                <!-- Period Breakdown -->
                                <app-period-button
                                    *ngIf="isBreakdownEnabled"
                                    title="translate_period_breakdown"
                                    icon="fa-list-ul"
                                    [isDisabled]="isSubmitting"
                                    [callbackFunction]="
                                        showBreakdownDialog.bind(this, period)
                                    "
                                ></app-period-button>

                                <!-- Approval and Suggestions -->
                                <app-period-button
                                    *appHasPermissionId="
                                        permissionList.kpiResultApprove
                                    "
                                    title="translate_approval_and_suggestions"
                                    [icon]="
                                        period.isApproved
                                            ? 'fa-lock'
                                            : 'fa-unlock'
                                    "
                                    [isDone]="period.isApproved"
                                    [isDisabled]="isSubmitting"
                                    [callbackFunction]="
                                        showApprovalDialog.bind(this, period)
                                    "
                                ></app-period-button>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>
            </tr>

            <tr class="font-bold" *ngIf="type === 'kpi-result'">
                <!-- Result Header -->
                <th class="text-center">
                    {{ 'translate_total' | translate }}
                </th>

                <!-- Target -->
                <td class="text-center" *ngIf="!kpiResult.kpi.isTrend">
                    {{ target | formatKpiResult : units : unitsDescription }}
                </td>

                <!-- Aggregate A -->
                <td class="text-center">
                    {{ kpiResult.aggregateA | round : 2 }}
                </td>

                <!-- Aggregate B -->
                <td *ngIf="formula.includes('B')" class="text-center">
                    {{ kpiResult.aggregateB | round : 2 }}
                </td>

                <ng-container *ngIf="mode === 'edit' && periods[0]?.id">
                    <!-- Result -->
                    <td class="text-center">
                        {{
                            kpiResult.result
                                | round : 2
                                | formatKpiResult : units : unitsDescription
                        }}
                    </td>

                    <!-- Achieved -->
                    <td class="full-table-cell" *ngIf="!kpiResult.kpi.isTrend">
                        <app-achieved-container
                            [achieved]="kpiResult.achieved"
                        ></app-achieved-container>
                    </td>

                    <!-- Actions -->
                    <td></td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>
