import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
})
export class AlertComponent {
    @Input() public mode:
        | 'default'
        | 'success'
        | 'info'
        | 'warning'
        | 'danger' = 'default';

    @Input() public label!: string;

    @Input() public description?: string;
}
