import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { OauthService } from 'mnm-webapp';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthorizeGuard implements CanActivate, CanActivateChild {
    public constructor(
        protected router: Router,
        protected oauthService: OauthService
    ) {}

    public canActivate(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.canActivateHandler(state);
    }

    public canActivateChild(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivateHandler(state);
    }

    private canActivateHandler(
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.oauthService.userInfo$.pipe(
            map(x => {
                if (!x.isLoggedIn) {
                    const extras = { queryParams: { returnUrl: state.url } };
                    this.router.navigate(['', 'auth', 'login'], extras).then();
                }

                return x.isLoggedIn;
            })
        );
    }
}
