import { AbstractControl } from '@angular/forms';

export const functions = {
    getFilenameFromContentDisposition: (contentDisposition: string): string => {
        const regex = /filename=(?<filename>[^,;]+);/g;
        const match = regex.exec(contentDisposition);
        return match.groups.filename;
    },

    convertToValidFilename(fileName): string {
        return fileName.replace(/[\/|\\:*?"<>]/g, ' ');
    },

    downloadBlobIntoFile: (blob: Blob, fileName: string = ''): void => {
        const anchorElement = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        anchorElement.href = url;
        anchorElement.style.display = 'none';
        anchorElement.download = functions.convertToValidFilename(fileName);
        anchorElement.target = '_blank';
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(url);
    },

    roundNumber: (value: number, precision: number = 0): number => {
        if (!value || isNaN(value)) {
            return value;
        }
        const multiplier = Math.pow(10, precision);
        value *= multiplier;
        value = Math.round(value);
        value /= multiplier;
        return value;
    },

    groupBy: <T, K>(
        list: T[],
        keyGetter: (item: T) => K,
        keyCompare: (a: K, b: K) => boolean = (a, b) => a === b
    ): { key: K; items: T[] }[] => {
        return list.reduce<{ key: K; items: T[] }[]>((acc, x) => {
            const key = keyGetter(x);
            let group = acc.find(a => keyCompare(a.key, key));

            if (!group) {
                group = { key, items: [] };
                acc.push(group);
            }

            group.items.push(x);

            return acc;
        }, []);
    },

    // cspell:disable
    /**
     * @description To guarantee type safety of "string-ly typed" property names like name-of in C#
     * If the names of the innovation properties ever change, TypeScript will error on compile.
     * @link https://schneidenbach.gitbooks.io/typescript-cookbook/content/nameof-operator.html
     * @author @omar-elsayed97
     * @param name the name of the property
     * @returns
     */
    // cspell:enable
    nameOf: <T>(name: keyof T): keyof T => name,

    /**
     * @description This method is a utility function that recursively checks if an `AbstractControl` object or its
     * nested controls have the `required` validator, and returns `true` if any are found, and `false` otherwise.
     * This method does the same as the deprecated `hasValidator` method used in previous versions of Angular.
     * @author @omar-elsayed97
     * @param abstractControl the control field
     * @returns boolean
     */
    hasRequiredField: (abstractControl: AbstractControl): boolean => {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        if (abstractControl['controls']) {
            for (const controlName in abstractControl['controls']) {
                if (abstractControl['controls'][controlName]) {
                    if (
                        functions.hasRequiredField(
                            abstractControl['controls'][controlName]
                        )
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    },
};
