import { Component, Input } from '@angular/core';
import { Department, Team, User } from '@masar/common/models';

@Component({
    selector: 'app-assigned-entity',
    template: `
        <ng-container *ngIf="item">
            <app-department-link
                *ngIf="item.assignedDepartment"
                [item]="item.assignedDepartment"
            ></app-department-link>
            <a
                [routerLink]="['', 'team', 'detail', item.assignedTeam.id]"
                *ngIf="item.assignedTeam"
            >
                {{ item.assignedTeam.name }}
            </a>
            <span *ngIf="item.assignedUser"> {{ item.assignedUser.name }}</span>
        </ng-container>
    `,
})
export class AssignedEntityComponent {
    @Input() public item: {
        assignedDepartment: Department;
        assignedTeam: Team;
        assignedUser: User;
    };
}
