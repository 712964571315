import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Filter, TableController, TableResult } from '@masar/common/misc/table';
import { Item, LibraryFile } from '@masar/common/models';
import { MiscApiService } from '@masar/core/services';
import { LibraryFileListComponent } from '../library-file-list/library-file-list.component';
import { Observable, Subject } from 'rxjs';
import { SharedLibraryFileService } from '@masar/shared/services';
import { getOrderByAsString } from '@masar/common/utils';

interface FilterData {
    keyword: string;
    teamIds: string[];
    tagIds: string[];
    onlyMyFiles: boolean;
    ownerIds?: string[];
    contentTypes?: string[];
}

type ShownFilter = 'name' | 'is_owner' | 'tag';

@Component({
    selector: 'app-library-file-list-full',
    templateUrl: './library-file-list-full.component.html',
})
export class LibraryFileListFullComponent
    implements OnInit, OnChanges, OnDestroy
{
    @ViewChild(LibraryFileListComponent) public list: LibraryFileListComponent;

    @Input() public shownFilters: ShownFilter[] = ['name', 'is_owner', 'tag'];

    @Input() public alternateListCallback: (
        filter: Filter<FilterData>
    ) => Observable<TableResult<LibraryFile>>;

    public tableController: TableController<LibraryFile, FilterData>;

    public tags: Item[] = [];
    public contentTypes: Item[] = [];
    public users: Item[];

    private unsubscribeAll = new Subject();

    public constructor(
        private sharedLibraryFileService: SharedLibraryFileService,
        miscApiService: MiscApiService
    ) {
        miscApiService
            .getList('user', undefined, true)
            .subscribe(items => (this.users = items));
        miscApiService
            .getList('library-tag', undefined, true)
            .subscribe(items => (this.tags = items));
        miscApiService
            .getList('content-type')
            .subscribe(items => (this.contentTypes = items));
    }

    public ngOnInit(): void {
        this.reinitializeTableController();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const alternateListCallbackChange = changes['alternateListCallback'];
        if (!alternateListCallbackChange) return;

        this.reinitializeTableController();
    }

    public ngOnDestroy(): void {
        this.tableController.stop();
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public filterByTag(id: string): void {
        this.tableController.filter.data.tagIds = [id];
        this.tableController.filter$.next(true);
    }

    public refreshItems(resetPageNumber: boolean = false): void {
        this.tableController.filter$.next(resetPageNumber);
    }

    public reinitializeTableController(): void {
        this.tableController?.stop();
        this.tableController = null;

        const callback: (
            filter: Filter<FilterData>
        ) => Observable<TableResult<LibraryFile>> =
            this.alternateListCallback ??
            (filter =>
                this.sharedLibraryFileService.list(
                    filter.data.keyword,
                    filter.data.teamIds,
                    filter.data.tagIds,
                    filter.data.onlyMyFiles,
                    filter.pageNumber,
                    filter.pageSize,
                    filter.data.ownerIds,
                    filter.data.contentTypes,
                    getOrderByAsString(filter.orderBy)
                ));

        this.tableController = new TableController<LibraryFile, FilterData>(
            callback,
            {
                data: {
                    keyword: '',
                    teamIds: [],
                    tagIds: [],
                    onlyMyFiles: false,
                },
            }
        );
        this.tableController.start();
    }
}
