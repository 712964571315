import { Pipe, PipeTransform } from '@angular/core';
import { PermissionService } from '@masar/core/services';
import { Observable } from 'rxjs';
import { PermissionValue } from '@masar/common/types';

@Pipe({ name: 'hasPermissionId' })
export class HasPermissionIdPipe implements PipeTransform {
    public constructor(private readonly permissionService: PermissionService) {}

    public transform(value: PermissionValue): Observable<boolean> {
        return this.permissionService.ensureUserHasPermission(value);
    }
}
