import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Renderer2,
} from '@angular/core';

@Directive({ selector: '[appHighlight]' })
export class HighlightDirective implements AfterViewInit {
    @Input() public appHighlight: string;

    public constructor(private el: ElementRef, private renderer: Renderer2) {}

    public ngAfterViewInit(): void {
        const text = this.el.nativeElement.textContent;
        const regex = new RegExp(this.appHighlight, 'gi');
        const highlightedText = text.replace(
            regex,
            `<span style="background-color: #ffff00">${this.appHighlight}</span>`
        );
        this.renderer.setProperty(
            this.el.nativeElement,
            'innerHTML',
            highlightedText
        );
    }
}
