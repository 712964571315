import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    template: `
        <p
            *ngIf="title"
            class="mb-1 text-center text-sm font-semibold text-gray-400"
        >
            {{ title | translate }}
        </p>

        <div
            class="relative mb-2 h-6 overflow-hidden rounded-2xl bg-gray-300 shadow"
        >
            <span
                class="relative block h-full overflow-hidden rounded-2xl"
                [style.width]="progress * 100 + '%'"
                [ngClass]="{
                    'bg-gray-400': progress === null,
                    'bg-red-500': progress !== null && progress < 0.6,
                    'bg-yellow-500': 0.6 <= progress && progress < 0.9,
                    'bg-green-500': 0.9 <= progress
                }"
            ></span>

            <span
                class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 font-bold text-primary drop-shadow"
            >
                {{ progress * 100 | number : '1.0-0' }}%
            </span>
        </div>

        <div
            class="flex items-center justify-between text-sm font-semibold text-gray-400"
            *ngIf="expectedProgress"
        >
            <p>
                <span class="me-0.5">
                    {{ 'translate_expected_progress' | translate }}
                </span>
                <span> {{ expectedProgress * 100 | number : '1.0-0' }}%</span>
            </p>

            <p *ngIf="difference !== 0">
                <span
                    [ngClass]="
                        difference > 0 ? 'text-red-500' : 'text-green-500'
                    "
                >
                    <i
                        class="fa-solid"
                        [ngClass]="
                            difference > 0 ? ' fa-arrow-down' : 'fa-arrow-up'
                        "
                    ></i>
                    {{ difference | mathAbs }}%
                </span>
            </p>
        </div>
    `,
})
export class ProgressBarComponent {
    @Input() public title?: string;

    @Input() public progress!: number;

    @Input() public expectedProgress?: number;

    public get difference(): number {
        const diff =
            (this.expectedProgress || 0) * 100 - (this.progress || 0) * 100;
        return +diff.toFixed();
    }
}
