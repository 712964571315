import { Validators } from '@angular/forms';
import { CustomValidators } from 'mnm-webapp';
import { MnmFormField } from '@masar/shared/components';

export const fields: () => MnmFormField[] = () => [
    {
        name: 'id',
        hide: true,
    },

    {
        name: 'period',
        label: 'translate_period',
        validators: [Validators.required],
        hide: true,
    },

    {
        name: 'nameAr',
        label: 'translate_name_in_arabic',
        type: 'text',
        validators: [Validators.required],
    },

    {
        name: 'nameEn',
        label: 'translate_name_in_english',
        type: 'text',
    },

    {
        name: 'file',
        label: 'translate_attachment',
        type: 'file',
        note: 'translate_max_file_size_is_150_mb',
        validators: [
            Validators.required,
            CustomValidators.maxFileSize(150 * 1024 * 1024, '150MB'),
        ],
    },
];
