import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { TableResult } from './models/table-result.model';
import { Filter } from './models/filter.model';
import { ActivatedRoute, Router } from '@angular/router';
import { mnmHttpInterceptorRouterParams } from 'mnm-webapp';
import { moveItemInArray } from '@angular/cdk/drag-drop';

type RefreshCommand = 'filter' | 'order';

export interface RoutingControls {
    router: Router;
    activatedRoute: ActivatedRoute;
}

interface RefreshData<T> {
    command: RefreshCommand;
    data: TableResult<T> | any;
}

export type OrderCallback<T, S> = (
    orderedItems: T[],
    filter: Filter<S>
) => Observable<any>;

export class TableController<T, S = any> {
    public filter$ = new Subject<boolean>();
    public filter: Filter<S>;
    public items: T[];
    public count: number;
    public filteredCount: number;
    public isLoading = false;

    private stoppingSignal: Subject<any> = null;

    // Ordering stuff.
    private order$ = new Subject<{
        orderedItems: T[];
    }>();
    private readonly orderCallback: OrderCallback<T, S>;

    private refresh$ = new Subject<{
        command: RefreshCommand;
        order?: { orderedItems: T[] };
    }>();

    private readonly routingControls: RoutingControls;

    public constructor(
        private callback: (filter: Filter<S>) => Observable<TableResult<T>>,
        {
            data = {} as S,
            orderBy = {},
            pageNumber = 0,
            pageSize = 10,
        }: Partial<Filter<S>> = {},
        {
            routingControls = null as RoutingControls,
            orderCallback = null as OrderCallback<T, S>,
        } = {}
    ) {
        this.filter = { data, orderBy, pageNumber, pageSize };
        this.items = undefined;
        this.count = 0;
        this.filteredCount = 0;

        this.routingControls = routingControls;
        this.orderCallback = orderCallback;
    }

    public start(): void {
        if (this.stoppingSignal) return;

        this.stoppingSignal = new Subject();

        this.order$
            .asObservable()
            .pipe(takeUntil(this.stoppingSignal))
            .subscribe(({ orderedItems }) => {
                this.refresh$.next({
                    command: 'order',
                    order: { orderedItems },
                });
            });

        // In case the routing controls are available, then
        // the controller is going to persist the filter in the
        // url. For this to work the workflow of loading the data
        // is going to be as follows:
        // 1. Any change in the filter would initiate a change in the
        //    query parameter to update the filter.
        // 2. The controller subscribes to the route events and upon
        //    a change in the route params, the controller would fire
        //    the request to load the items according to the updated filter.
        if (this.routingControls !== null) {
            this.filter$
                .asObservable()
                .pipe(takeUntil(this.stoppingSignal))
                .subscribe(async shouldResetPageNumber => {
                    this.isLoading = true;

                    if (shouldResetPageNumber) {
                        this.filter.pageNumber = 0;
                    }

                    // Update the query params with the
                    // new filter.
                    if (this.routingControls !== null) {
                        await this.routingControls.router.navigate([], {
                            relativeTo: this.routingControls.activatedRoute,
                            queryParams: {
                                filter: JSON.stringify({
                                    ...this.filter,

                                    // To force a navigation trigger when filter is unchanged.
                                    ...{
                                        trigger: new Date().getTime(),
                                    },
                                }),
                            },
                            queryParamsHandling: 'merge', // remove to replace all query params by provided
                            state: {
                                [mnmHttpInterceptorRouterParams.resumeRequests]:
                                    true,
                            },
                        });
                    }
                });

            // Change in the query params would fire the
            // filter request.
            this.routingControls.activatedRoute.queryParamMap
                .pipe(takeUntil(this.stoppingSignal))
                .subscribe(params => {
                    const filterJson = params.get('filter');

                    if (!filterJson) {
                        this.refresh$.next({ command: 'filter' });
                        return;
                    }

                    const tmpFilter = JSON.parse(filterJson);

                    Object.assign(this.filter.data, tmpFilter.data ?? {});
                    Object.assign(this.filter.orderBy, tmpFilter.orderBy ?? {});

                    this.refresh$.next({ command: 'filter' });
                });
        } else {
            this.filter$
                .asObservable()
                .pipe(takeUntil(this.stoppingSignal))
                .subscribe(shouldResetPageNumber => {
                    this.isLoading = true;
                    if (shouldResetPageNumber) this.filter.pageNumber = 0;
                    this.refresh$.next({ command: 'filter' });
                });
        }

        this.refresh$
            .asObservable()
            .pipe(
                takeUntil(this.stoppingSignal),
                switchMap(data => {
                    switch (data.command) {
                        case 'filter':
                            return this.callback(this.filter).pipe(
                                map(
                                    x =>
                                        ({
                                            command: 'filter',
                                            data: x,
                                        } as RefreshData<T>)
                                )
                            );

                        case 'order':
                            const { orderedItems } = data.order;
                            return this.orderCallback(
                                orderedItems,
                                this.filter
                            ).pipe(
                                map(
                                    x =>
                                        ({
                                            command: 'order',
                                            data: x,
                                        } as RefreshData<T>)
                                )
                            );
                    }
                })
            )
            .subscribe(result => {
                switch (result.command) {
                    case 'filter':
                        this.isLoading = false;

                        const data = result.data as TableResult<T>;

                        this.count = data.count;
                        this.items = data.items;
                        this.filteredCount = data.filteredCount;
                        break;
                    case 'order':
                        // do nothing.
                        break;
                }
            });

        this.filter$.next(false);
    }

    public stop(): void {
        this.stoppingSignal?.next();
        this.stoppingSignal?.complete();
        this.stoppingSignal = null;

        this.items = null;
        this.count = null;
        this.filteredCount = null;
    }

    public get isStarted(): boolean {
        return this.stoppingSignal !== null;
    }

    public totalPages(): number {
        return Math.ceil(
            Math.min(this.count, this.filteredCount) / this.filter.pageSize
        );
    }

    public order(previousIndex: number, currentIndex: number): void {
        if (!this.orderCallback) return;
        moveItemInArray(this.items, previousIndex, currentIndex);

        this.order$.next({ orderedItems: this.items });
    }
}
