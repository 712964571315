import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tab',
    template: `<ng-container *ngIf="isActive"
        ><ng-content></ng-content
    ></ng-container>`,
    styles: [':host { display: block }'],
})
export class TabComponent {
    @Input() public tabTitle: string;

    public isActive: boolean = false;

    public setIsActive(isActive: boolean): void {
        this.isActive = isActive;
    }

    public getIsActive(): boolean {
        return this.isActive;
    }
}
