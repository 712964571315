import { Component, Input, TemplateRef } from '@angular/core';
import {
    KpiResultDataEntryResponsePeriod,
    KpiResultPeriod,
} from '@masar/common/models';
import { KpiMeasurementCycleType } from '@masar/common/types';

@Component({
    selector: 'app-period-tab-list',
    templateUrl: './period-tab-list.component.html',
})
export class PeriodTabListComponent {
    @Input() public periods: (
        | KpiResultPeriod
        | KpiResultDataEntryResponsePeriod
    )[];
    @Input() public measurementCycle: KpiMeasurementCycleType;
    @Input() public contentTemplate: TemplateRef<any>;
}
