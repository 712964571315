<app-list-loading [items]="linkedResources">
    <div class="table-responsive">
        <table>
            <thead>
                <tr>
                    <th>{{ 'translate_name' | translate }}</th>
                    <th>{{ 'translate_resource_type' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let resource of linkedResources">
                    <td>{{ resource.name }}</td>
                    <td class="text-center">
                        <span class="badge badge-info">
                            {{
                                resource.type
                                    | translateItem : 'resource-type'
                                    | async
                            }}
                        </span>
                    </td>
                    <td class="text-center">
                        <a
                            *ngIf="resourcesLinks.get(resource.id)"
                            [routerLink]="resourcesLinks.get(resource.id)"
                            [queryParams]="
                                resourcesLinksQueryParams.get(resource.id)
                            "
                            target="_blank"
                            class="btn btn-sm btn-primary"
                        >
                            <i class="fa fa-up-right-from-square"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</app-list-loading>
