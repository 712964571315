import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@masar/env/environment';
import { miscFunctions, mnmHttpInterceptorParams, Result } from 'mnm-webapp';
import { map } from 'rxjs/operators';
import { Kpi, KpiResult, KpiResultAttachment } from '@masar/common/models';

@Injectable()
export class KpiResultService {
    public constructor(private httpClient: HttpClient) {}

    public get(
        kpiId: string,
        year: number,
        departmentId: string,
        hideNotifications: boolean = false
    ): Observable<{ item: KpiResult; isItemNew: boolean }> {
        return this.httpClient
            .get<Result<{ item: KpiResult; isItemNew: boolean }>>(
                environment.apiUrl + '/kpi-result',
                {
                    params: new HttpParams()
                        .append('kpiId', kpiId)
                        .append('year', `${year}`)
                        .append('departmentId', departmentId)
                        .append(
                            mnmHttpInterceptorParams.hideNotifications,
                            `${hideNotifications}`
                        ),
                }
            )
            .pipe(map(res => res.extra));
    }

    public getKpi(kpiId: string): Observable<Kpi> {
        return this.httpClient
            .get<Result<Kpi>>(environment.apiUrl + '/kpi-result/kpi', {
                params: new HttpParams().append('kpiId', kpiId),
            })
            .pipe(map(res => res.extra));
    }

    public create(kpiResult: KpiResult): Observable<KpiResult> {
        return this.httpClient
            .post<Result<KpiResult>>(
                environment.apiUrl + '/kpi-result',
                miscFunctions.objectToURLParams({
                    kpiResult: JSON.stringify(kpiResult),
                })
            )
            .pipe(map(result => result.extra));
    }

    public update(kpiResult: KpiResult): Observable<KpiResult> {
        return this.httpClient
            .put<Result<KpiResult>>(
                environment.apiUrl + '/kpi-result',
                miscFunctions.objectToURLParams({
                    kpiResult: JSON.stringify(kpiResult),
                })
            )
            .pipe(map(result => result.extra));
    }

    public delete(id: string): Observable<string> {
        return this.httpClient
            .delete<Result<any>>(environment.apiUrl + '/kpi-result', {
                params: new HttpParams().append('id', id),
            })
            .pipe(map(result => result.messages[0]));
    }

    public yearDepartmentInfo(kpiId: string): Observable<Kpi> {
        return this.httpClient
            .get<Result<Kpi>>(
                environment.apiUrl + '/kpi/year-department-info/' + kpiId
            )
            .pipe(map(res => res.extra));
    }

    // Attachment methods.
    public listAttachments(
        id: string,
        period?: number
    ): Observable<KpiResultAttachment[]> {
        let params = new HttpParams();

        if (period !== undefined) params = params.set('period', `${period}`);

        return this.httpClient
            .get<Result<KpiResultAttachment[]>>(
                `${environment.apiUrl}/kpi-result/attachment/${id}`,
                { params }
            )
            .pipe(map(res => res.extra));
    }

    public getAttachment(
        id: string,
        forEdit: boolean = false
    ): Observable<KpiResultAttachment> {
        console.log(id, forEdit);
        return null;
    }

    public createAttachment(
        attachment: KpiResultAttachment,
        sendCopyToLibrary: boolean = false
    ): Observable<KpiResultAttachment> {
        return this.httpClient
            .post<Result<KpiResultAttachment>>(
                `${environment.apiUrl}/kpi-result/attachment`,
                miscFunctions.objectToURLParams({
                    attachment: JSON.stringify(attachment),
                    sendCopyToLibrary,
                })
            )
            .pipe(map(res => res.extra));
    }

    public updateAttachment(
        attachment: KpiResultAttachment
    ): Observable<KpiResultAttachment> {
        console.log(attachment);
        return null;
    }

    public deleteAttachment(attachmentId: string): Observable<string> {
        return this.httpClient
            .delete<Result<any>>(
                `${environment.apiUrl}/kpi-result/attachment?attachmentId=${attachmentId}`
            )
            .pipe(map(res => res.messages[0]));
    }

    public downloadAttachment(attachmentId: string): Observable<Blob> {
        return this.httpClient
            .get(
                `${environment.apiUrl}/kpi-result/attachment/download?attachmentId=${attachmentId}`,
                {
                    responseType: 'blob',
                }
            )
            .pipe(map(res => res));
    }
}
