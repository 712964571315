import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-list-loading',
    templateUrl: './list-loading.component.html',
    styles: [':host {display: block}'],
})
export class ListLoadingComponent {
    @Input() public items: any[];
    @Input() public itemsAreUnavailableMessage =
        'translate_could_not_find_any_items';
    @Input() public loadingStyles = {};
    @Input() public loadingClasses = '';
    @Input() public itemsAreUnavailableStyles = {};
    @Input() public itemsAreUnavailableClasses = '';
}
