import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    EvaluationOverallStatistics,
    EvaluationScoreBandStatistics,
    EvaluationType,
} from '@masar/features/evaluate/types';
import { EvaluateService } from '@masar/features/evaluate/services/evaluate.service';
import { flippingCard, verticalFlippingCard } from '@masar/common/animations';
import { Evaluation } from '@masar/common/models';

@Component({
    selector: 'app-evaluation-statistics',
    templateUrl: './evaluation-statistics.component.html',
    animations: [...flippingCard, ...verticalFlippingCard],
})
export class EvaluationStatisticsComponent implements OnInit {
    @Input() public type: EvaluationType;
    @Output() public evaluationChange = new EventEmitter<Evaluation>();

    public overallStatistics: EvaluationOverallStatistics;
    public scoreBandStatistics: EvaluationScoreBandStatistics[];
    public evaluations: Evaluation[] = null;
    public selectedEvaluation: Evaluation = null;

    public constructor(private evaluateService: EvaluateService) {}

    public ngOnInit(): void {
        this.evaluateService.listEvaluations(this.type).subscribe(items => {
            this.evaluations = items;
            if (items.length === 0) return;

            this.updateSelectedEvaluation(
                this.evaluations.find(x => x.isDefault) ?? items[0]
            );
        });
    }

    public updateSelectedEvaluation(evaluation: Evaluation): void {
        this.selectedEvaluation = evaluation;
        this.evaluationChange.emit(evaluation);
        this.reload();
    }

    public reload(): void {
        this.evaluateService
            .statistics(this.selectedEvaluation.id, this.type)
            .subscribe(data => (this.overallStatistics = data));
        this.evaluateService
            .scoreBandStatistics(this.selectedEvaluation.id, this.type)
            .subscribe(data => (this.scoreBandStatistics = data));
    }
}
