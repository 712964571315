import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Input,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs/operators';
import { TableController } from '@masar/common/misc/table';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-empty-function
const noop = () => {};

@Component({
    selector: 'app-search-input',
    template: `
        <input
            class="w-full"
            #searchInput
            [type]="type"
            [placeholder]="placeholder | translate"
            [(ngModel)]="value"
            aria-label="Search"
        />
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SearchInputComponent),
        },
    ],
})
export class SearchInputComponent
    implements AfterViewInit, ControlValueAccessor, OnDestroy
{
    @ViewChild('searchInput') public searchInput: ElementRef<HTMLInputElement>;

    @Input() public tableController: TableController<unknown>;
    @Input() public placeholder = 'translate_search_by_name';
    @Input() public type = 'text';

    public onTouchedCallback: () => void = noop;
    public onChangeCallback: (_: any) => void = noop;

    private innerValue: string = '';
    private unsubscribeAll = new Subject<void>();

    public ngAfterViewInit(): void {
        this.initSearchSubscription();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public get value(): string {
        return this.innerValue;
    }

    public set value(v: string) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    public writeValue(value: string): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    public registerOnChange(fn: (_: string) => void): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouchedCallback = fn;
    }

    private initSearchSubscription(): void {
        const inputElement = this.searchInput?.nativeElement;

        if (!inputElement) return;

        fromEvent(inputElement, 'keyup')
            .pipe(
                map((event: Event) => (event.target as HTMLInputElement).value),
                debounceTime(500),
                distinctUntilChanged(),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe(() => this.tableController.filter$.next(true));
    }
}
