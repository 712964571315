<nz-tree-select
    class="block w-full"
    [nzShowSearch]="true"
    [nzDropdownMatchSelectWidth]="true"
    [nzExpandedKeys]="expandKeys"
    [nzNodes]="nodes"
    [ngModel]="selectedNode"
    (ngModelChange)="selectedNode = $event; reportChange()"
    [nzDropdownStyle]="{ 'max-height': '300px' }"
    [nzAsyncData]="true"
    (nzExpandChange)="onExpandChange($event)"
    [nzTreeTemplate]="nzTreeTemplate"
    [nzDisplayWith]="displayNodeName"
    [nzMultiple]="isMultiple"
></nz-tree-select>

<ng-template #nzTreeTemplate let-node>
    <span>{{ node.origin.name }}</span>
</ng-template>
