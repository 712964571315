import { Injectable } from '@angular/core';
import { Result, miscFunctions } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { KpiResultCapability, LibraryFile } from '@masar/common/models';
import { environment } from '@masar/env/environment';
import { TableResult } from '@masar/common/misc/table';

@Injectable()
export class KpiResultCapabilityService {
    public constructor(private httpClient: HttpClient) {}

    public list(periodId: string): Observable<KpiResultCapability[]> {
        let params = new HttpParams();
        params = params.append('periodId', periodId);

        return this.httpClient
            .get<Result<KpiResultCapability[]>>(
                environment.apiUrl + '/kpi-result-capability',
                {
                    params,
                }
            )
            .pipe(map(result => result.extra));
    }

    public create(
        capability: KpiResultCapability,
        periodId: string
    ): Observable<KpiResultCapability> {
        return this.httpClient
            .post<Result<KpiResultCapability>>(
                environment.apiUrl + '/kpi-result-capability',
                miscFunctions.objectToURLParams({
                    capability: JSON.stringify(capability),
                    periodId,
                })
            )
            .pipe(map(result => result.extra));
    }

    public update(
        capability: KpiResultCapability
    ): Observable<KpiResultCapability> {
        return this.httpClient
            .put<Result<KpiResultCapability>>(
                environment.apiUrl + '/kpi-result-capability',
                miscFunctions.objectToURLParams({
                    capability: JSON.stringify(capability),
                })
            )
            .pipe(map(result => result.extra));
    }

    public get(
        id: string,
        forEdit: boolean = false
    ): Observable<KpiResultCapability> {
        return this.httpClient
            .get<Result<KpiResultCapability>>(
                environment.apiUrl + '/kpi-result-capability/' + id,
                {
                    params: new HttpParams().append('forEdit', `${forEdit}`),
                }
            )
            .pipe(map(result => result.extra));
    }

    public delete(id: string): Observable<string> {
        return this.httpClient
            .delete<Result<any>>(
                environment.apiUrl + '/kpi-result-capability/' + id
            )
            .pipe(map(result => result.messages[0]));
    }

    // Library Files Linking
    public listLinkedLibraryFiles(
        id: string,
        keyword: string,
        pageNumber: number = 0,
        pageSize: number = 20
    ): Observable<TableResult<LibraryFile>> {
        return this.httpClient
            .get<Result<TableResult<LibraryFile>>>(
                `${environment.apiUrl}/kpi-result-capability/library-file/${id}`,
                {
                    params: new HttpParams()
                        .append('keyword', keyword)
                        .append('pageNumber', `${pageNumber}`)
                        .append('pageSize', `${pageSize}`),
                }
            )
            .pipe(map(res => res.extra));
    }

    public listUnlinkedLibraryFiles(
        id: string,
        keyword: string
    ): Observable<LibraryFile[]> {
        return this.httpClient
            .get<Result<LibraryFile[]>>(
                `${environment.apiUrl}/kpi-result-capability/library-file/unlinked/${id}?keyword=${keyword}`
            )
            .pipe(map(res => res.extra));
    }

    public linkLibraryFile(
        id: string,
        libraryFileId: string
    ): Observable<string> {
        return this.httpClient
            .post<Result<any>>(
                `${environment.apiUrl}/kpi-result-capability/library-file/${id}`,
                miscFunctions.objectToURLParams({ libraryFileId })
            )
            .pipe(map(res => res.messages[0]));
    }

    public unlinkLibraryFile(
        id: string,
        libraryFileId: string
    ): Observable<string> {
        return this.httpClient
            .delete<Result<any>>(
                `${environment.apiUrl}/kpi-result-capability/library-file/${id}?libraryFileId=${libraryFileId}`
            )
            .pipe(map(res => res.messages[0]));
    }
}
