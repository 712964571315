import { Pipe, PipeTransform } from '@angular/core';
import { functions } from '@masar/common/misc/functions';

@Pipe({
    name: 'round',
})
export class RoundPipe implements PipeTransform {
    public transform(value: number, precision: number = 0): number {
        return functions.roundNumber(value, precision);
    }
}
