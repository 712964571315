import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';

@Directive({
    selector: '[appScrollPosition]',
})
export class ScrollPositionDirective implements AfterViewInit {
    @Input() public appScrollPosition = 0;
    @Output() public appScrollPositionChange = new EventEmitter();

    public constructor(private elemRef: ElementRef) {}

    @HostListener('scroll')
    public onScroll(): void {
        this.appScrollPosition = this.elemRef.nativeElement.scrollTop;
        this.appScrollPositionChange.emit(this.appScrollPosition);
    }

    public ngAfterViewInit(): void {
        this.elemRef.nativeElement.scrollTop = this.appScrollPosition;
    }
}
