<app-period-tab-list
    [periods]="periods"
    [measurementCycle]="measurementCycle"
    [contentTemplate]="periodTemplate"
></app-period-tab-list>

<ng-template #periodTemplate let-period="period">
    <app-kpi-capability-list
        [periodId]="period.id"
        [mode]="'view'"
        type="kpi-result"
        class="mb-5 block"
    ></app-kpi-capability-list>
</ng-template>
