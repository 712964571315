import { MiscApiService } from './misc-api.service';
import { ImageApiService } from './image-api.service';
import { SignalrService } from './signalr.service';
import { PermissionService } from './permission.service';
import { YearService } from './year.service';
import { NotificationUnreadCountService } from './notification-unread-count.service';
import { ThemeService } from './theme.service';
import { SafeModeService } from './safe-mode.service';
import { SidebarNotificationService } from './sidebar-notification.service';
import { AppSettingFetcherService } from './app-setting-fetcher.service';
import { ToastrNotificationHandler } from './toastr.notification-handler.service';
import { MNM_NOTIFICATION_HANDLER } from 'mnm-webapp';
import { APP_INITIALIZER } from '@angular/core';
import { HelperService } from './helper.service';

export const serviceProviders = [
    {
        provide: APP_INITIALIZER,
        useFactory: (x: AppSettingFetcherService) => () => x.get.toPromise(),
        deps: [AppSettingFetcherService],
        multi: true,
    },
    AppSettingFetcherService,
    ImageApiService,
    MiscApiService,
    NotificationUnreadCountService,
    PermissionService,
    SafeModeService,
    SidebarNotificationService,
    SignalrService,
    ThemeService,
    {
        provide: MNM_NOTIFICATION_HANDLER,
        useClass: ToastrNotificationHandler,
        multi: false,
    },
    YearService,
    HelperService,
];
