import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@masar/env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LibraryFileNameService {
    public constructor(private httpClient: HttpClient) {}

    public download(id: string): Observable<Blob> {
        return this.httpClient
            .get(`${environment.apiUrl}/library-file/download/${id}`, {
                responseType: 'blob',
            })
            .pipe(map(res => res));
    }
}
