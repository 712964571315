import {
    Component,
    EventEmitter,
    Input,
    NgModuleRef,
    OnInit,
    Output,
} from '@angular/core';
import {
    EvaluationInstance,
    EvaluationType,
} from '@masar/features/evaluate/types';
import { EvaluateService } from '@masar/features/evaluate/services/evaluate.service';
import { Subject } from 'rxjs';
import { ModalService, NotificationService } from 'mnm-webapp';
import { EvaluationInstanceDetailComponent } from '@masar/features/evaluate/components';
import { finalize, takeUntil } from 'rxjs/operators';
import { EvaluateItem } from '@masar/features/evaluate/interfaces';

@Component({
    selector: 'app-evaluation-instance-list',
    templateUrl: './evaluation-instance-list.component.html',
})
export class EvaluationInstanceListComponent implements OnInit {
    @Input() public title: string;

    @Input() public savedEntityId?: string;

    @Input() public type: EvaluationType;

    @Input() public item: EvaluateItem;

    @Output() public update = new EventEmitter<void>();

    public instances: EvaluationInstance[];

    public currentlyProcessing = new Set<string>();

    public constructor(
        private readonly evaluateService: EvaluateService,
        private readonly modalService: ModalService,
        private readonly moduleRef: NgModuleRef<any>,
        private readonly notificationService: NotificationService
    ) {}

    public ngOnInit(): void {
        this.reload();
    }

    public delete(item: EvaluationInstance): void {
        this.currentlyProcessing.add(item.id);
        this.evaluateService
            .deleteInstance(item.id, this.type)
            .pipe(finalize(() => this.currentlyProcessing.delete(item.id)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.update.emit();
                this.reload();
            });
    }

    public async showDetailDialog(id?: string): Promise<void> {
        const subject = new Subject();
        const component = await this.modalService.show(
            EvaluationInstanceDetailComponent,
            {
                title: this.title,
                moduleRef: this.moduleRef,
                beforeInit: c => {
                    c.type = this.type;
                    c.entityId = this.item.id;
                    c.itemId = id;
                    c.mode = this.item.evaluateActionAbility.canWrite
                        ? 'default'
                        : 'view';
                    c.savedEntityId = this.savedEntityId;
                },

                onDismiss: () => {
                    subject.next();
                    subject.complete();
                },

                size: {
                    width: '80%',
                },
            }
        );

        component.update.pipe(takeUntil(subject)).subscribe(() => {
            this.modalService.dismiss(component);
            this.update.next();
            this.reload();
        });
    }

    private reload(): void {
        this.evaluateService
            .listInstances(this.item.id, this.type)
            .subscribe(items => (this.instances = items));
    }
}
