import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    ViewChild,
} from '@angular/core';
import { Gauge } from 'gaugeJS';

@Component({
    selector: 'app-gauge',
    template: ` <div class="relative">
        <div
            #textTarget
            [ngClass]="{
                'text-white': appearance === 'light',
                'text-black': appearance === 'dark'
            }"
            [ngStyle]="{
                fontSize: 0.08 * radius + 'pt',
                marginTop: 0.05 * radius + 'px'
            }"
            class="
            absolute
            top-1/2 
            left-1/2 
            mx-auto 
            inline-block 
            -translate-x-1/2 
            -translate-y-1/2 
            transform 
            font-bold"
        ></div>
        <canvas
            [width]="radius"
            [height]="radius + -0.42 * radius"
            #target
        ></canvas>
    </div>`,
})
export class GaugeComponent implements AfterViewInit, OnChanges {
    @ViewChild('target') public targetRef: ElementRef<HTMLCanvasElement>;
    @ViewChild('textTarget') public textTargetRef: ElementRef<HTMLDivElement>;

    @Input() public value: number = 0;
    @Input() public radius: number = 150;
    @Input() public labels: number[] = [];
    @Input() public zones: { strokeStyle: string; min: number; max: number }[];
    @Input() public appearance: 'light' | 'dark' = 'dark';
    @Input() public tickColor = '#333333';
    @Input() public subTickColor = '#666666';

    public ngAfterViewInit(): void {
        this.update();
    }

    public ngOnChanges(): void {
        this.update();
    }

    private update(): any {
        if (!this.targetRef) return;

        const target = this.targetRef.nativeElement;
        const opts = this.getOptions();

        const demoGauge = new Gauge(target);
        demoGauge.setOptions(opts);
        demoGauge.minValue = 0;
        demoGauge.maxValue = 140;
        demoGauge.setTextField(this.textTargetRef.nativeElement);
        demoGauge.set(this.value);
    }

    private getOptions(): any {
        return {
            angle: 0.07,
            lineWidth: 0.2,
            radiusScale: 0.9,
            pointer: {
                length: 0.6,
                strokeWidth: 0.022,
                color: this.appearance === 'dark' ? '#000000' : '#FFFFFF',
            },
            staticLabels: {
                font: `${0.04 * this.radius + 4}px sans-serif`,
                color: this.appearance === 'dark' ? '#000000' : '#FFFFFF',
                labels: this.labels,
                fractionDigits: 0,
            },
            staticZones: this.zones,
            limitMax: false,
            limitMin: false,
            highDpiSupport: true,
            renderTicks: {
                divisions: 11,
                divWidth: 0.9,
                divLength: 0.4,
                divColor: this.tickColor,
                subDivisions: 3,
                subLength: 0.24,
                subWidth: 0.6,
                subColor: this.subTickColor,
            },
        };
    }
}
