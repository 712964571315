import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@masar/env/environment';
import { miscFunctions, Result } from 'mnm-webapp';
import { map } from 'rxjs/operators';

@Injectable()
export class KpiResultPeriodService {
    public constructor(private httpClient: HttpClient) {}
    // Approval.
    public approve(periodId: string): Observable<string> {
        return this.httpClient
            .post<Result<any>>(
                `${environment.apiUrl}/kpi-result/period/approve`,
                miscFunctions.objectToURLParams({ periodId })
            )
            .pipe(map(res => res.messages[0]));
    }

    public disapprove(periodId: string): Observable<string> {
        return this.httpClient
            .post<Result<any>>(
                `${environment.apiUrl}/kpi-result/period/disapprove`,
                miscFunctions.objectToURLParams({ periodId })
            )
            .pipe(map(res => res.messages[0]));
    }

    public supervisorNote(periodId: string, notes: string): Observable<string> {
        return this.httpClient
            .post<Result<any>>(
                `${environment.apiUrl}/kpi-result/period/supervisor-note`,
                miscFunctions.objectToURLParams({
                    periodId,
                    notes,
                })
            )
            .pipe(map(res => res.messages[0]));
    }

    public leadershipDirective(
        periodId: string,
        directives: string
    ): Observable<string> {
        return this.httpClient
            .post<Result<any>>(
                `${environment.apiUrl}/kpi-result/period/leadership-directive`,
                miscFunctions.objectToURLParams({
                    periodId,
                    directives,
                })
            )
            .pipe(map(res => res.messages[0]));
    }
}
