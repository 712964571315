import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-go-back-button',
    template: `
        <button
            dir="ltr"
            (click)="goBack()"
            [appTooltip]="'translate_back' | translate"
            class="btn btn-sm btn-white flex items-center gap-1"
        >
            <i class="fas fa-left"></i>

            <span class="hidden md:inline">
                {{ 'translate_back' | translate }}
            </span>
        </button>
    `,
})
export class GoBackButtonComponent {
    public constructor(private location: Location, private router: Router) {}

    public goBack(): void {
        if (
            this.location.getState() &&
            this.location.getState()['navigationId'] > 1
        ) {
            // If history state is available and the navigationId is greater than 1,
            // it means there is a previous page within your Angular application.
            this.location.back();
        } else {
            // If the previous page is not within your application, navigate to the home page.
            this.router.navigate(['/']).then(); // Update this to the actual path of your home page
        }
    }
}
