import { Component, Input } from '@angular/core';
import { animations } from './animations';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    animations: [animations],
})
export class PageComponent {
    @Input() public pageTitle: string = '';
    @Input() public pageSubtitle: string = '';
}
