import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-value-loading',
    template: `
        <ng-container *ngIf="isLoading; else valueTemplate">
            <app-loading-ring class="me-2"></app-loading-ring>
            <span>{{ 'translate_loading' | translate }}</span>
        </ng-container>
        <ng-template #valueTemplate>
            {{ value }}
        </ng-template>
    `,
})
export class ValueLoadingComponent {
    @Input() public isLoading = false;
    @Input() public value: any;
}
