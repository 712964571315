import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LangInterceptor } from './lang.interceptor';
import { YearInterceptor } from './year.interceptor';
import { TimezoneInterceptor } from './timezone.interceptor';

export const interceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LangInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: YearInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TimezoneInterceptor,
        multi: true,
    },
];
