import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-period-button',
    templateUrl: './period-button.component.html',
})
export class PeriodButtonComponent {
    @Input() public title: string;
    @Input() public icon: string;
    @Input() public isDisabled: boolean;
    @Input() public isDone?: boolean;
    @Input() public progress?: number;
    @Input() public count?: number;
    @Input() public callbackFunction?: () => void;

    @Output() public afterClick = new EventEmitter<void>();

    public onClick(): void {
        this.callbackFunction?.();
        this.afterClick.emit();
    }
}
