import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    BroadcasterService,
    LoadingService,
    ModalService,
    NotificationService,
    OauthService,
} from 'mnm-webapp';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import {
    AppSettingFetcherService,
    ThemeService,
    SafeModeService,
    SignalrService,
} from '@masar/core/services';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { forkJoin, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { NgSelectConfig } from '@ng-select/ng-select';
import { isMobile } from 'mobile-device-detect';
import { ThemeSetting } from '@masar/common/models';

@Component({ selector: 'app-root', templateUrl: './app.component.html' })
export class AppComponent implements OnInit, OnDestroy {
    public signalrStatus = 'disconnected';

    // Use it to hide the content until the
    // theme is loaded.
    public isThemeLoaded = false;

    private appName = '';
    private currentTitle = '';

    private isFirstTimeLoadingThePage = true;

    private unsubscribeAll = new Subject();

    public constructor(
        public safeModeService: SafeModeService,
        private broadcasterService: BroadcasterService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private oauthService: OauthService,
        private loadingService: LoadingService,
        private notificationService: NotificationService,
        private themeService: ThemeService,
        private modalService: ModalService,
        appSettingFetcherService: AppSettingFetcherService,
        signalrService: SignalrService,
        ngSelectConfig: NgSelectConfig
    ) {
        // Ng Select
        forkJoin([
            this.translateService.get('translate_type_to_search'),
            this.translateService.get('translate_clear_all'),
            this.translateService.get('translate_loading'),
            this.translateService.get('translate_not_found'),
            this.translateService.get('translate_add_item'),
        ]).subscribe(
            ([
                typeToSearchStr,
                clearAllStr,
                loadingStr,
                notFoundStr,
                addStr,
            ]) => {
                ngSelectConfig.typeToSearchText = typeToSearchStr;
                ngSelectConfig.clearAllText = clearAllStr;
                ngSelectConfig.loadingText = loadingStr;
                ngSelectConfig.notFoundText = notFoundStr;
                ngSelectConfig.addTagText = addStr;
            }
        );

        // Init SignalR service:
        // signalrService.start();

        signalrService.status$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(status => (this.signalrStatus = status));

        signalrService
            .messages()
            .pipe(filter(message => message.type === 'notification'))
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(message => {
                this.translateService
                    .get(
                        message.payload.text,
                        message.payload.translationParameters
                    )
                    .subscribe(str => {
                        if (message.payload.channel === 'success') {
                            this.notificationService.notifySuccess(str);
                        } else if (message.payload.channel === 'info') {
                            this.notificationService.notifyInfo(str);
                        } else if (message.payload.channel === 'warning') {
                            this.notificationService.notifyWarn(str);
                        } else if (message.payload.channel === 'danger') {
                            this.notificationService.notifyError(str);
                        }
                    });
            });

        appSettingFetcherService.get$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(setting => {
                this.refreshTheme(setting.theme);
                this.refreshAppName(setting.appName);
            });

        safeModeService.monitorHotKey();

        this.set100Vh();
    }

    public ngOnInit(): void {
        this.oauthService.userInfo$
            .pipe(first())
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(userInfo => {
                if (userInfo.isLoggedIn) {
                    this.broadcasterService.broadcast('claims-changed');
                }
            });

        // scroll to the top of the screen when navigating
        // to a new page.
        this.router.events
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(async evt => {
                if (evt instanceof NavigationStart) {
                    this.loadingService.showBlockingLoading();

                    // Close all opened modals.
                    await this.modalService.dismissAll();
                } else if (evt instanceof NavigationCancel) {
                    this.loadingService.hideBlockingLoading();
                } else if (
                    evt instanceof NavigationEnd ||
                    evt instanceof NavigationError
                ) {
                    this.loadingService.hideBlockingLoading();

                    // get all the data associated with each url segment
                    let data: any = {};
                    let current = this.route.root;
                    while (current) {
                        const d = current.snapshot.data;
                        data = Object.assign(data, d);
                        current = current.firstChild;
                    }

                    const title = data.title || 'translate_untitled';

                    setTimeout(
                        () => {
                            this.currentTitle =
                                this.translateService.instant(title);
                            this.refreshTitle();
                        },
                        this.isFirstTimeLoadingThePage ? 1000 : 0
                    );

                    this.isFirstTimeLoadingThePage = false;
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    private refreshTheme(themeSetting: ThemeSetting): void {
        this.themeService.apply(themeSetting);
        this.isThemeLoaded = true;
    }

    private refreshAppName(appName: string): void {
        this.appName = appName;
        this.refreshTitle();
    }

    private refreshTitle(): void {
        this.titleService.setTitle(this.currentTitle + ' | ' + this.appName);
    }

    private set100Vh(): void {
        if (isMobile) {
            // First we get the viewport height, and we multiply it by 1% to get a value for a vh unit
            const vh = window.innerHeight;

            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        } else {
            document.documentElement.style.setProperty('--vh', '100vh');
        }
    }
}
