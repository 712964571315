import { Component } from '@angular/core';
import { EasterService } from '../../easter.service';

@Component({
    selector: 'app-easter-alert',
    templateUrl: './easter-alert.component.html',
    styleUrls: ['./easter-alert.component.scss'],
})
export class EasterAlertComponent {
    public constructor(public easterService: EasterService) {}
}
