import { Component, Input } from '@angular/core';
import { KpiResultPeriod } from '@masar/common/models';
import { KpiMeasurementCycleType } from '@masar/common/types';

@Component({
    selector: 'app-kpi-result-attachment-dialog',
    templateUrl: './period-capability-dialog.component.html',
})
export class PeriodCapabilityDialogComponent {
    @Input() public periods: KpiResultPeriod[];
    @Input() public measurementCycle: KpiMeasurementCycleType;
}
