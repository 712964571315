<form (ngSubmit)="submit()">
    <textarea
        dir="auto"
        class="w-full"
        [disabled]="
            type === 'kpi-result'
                ? isApproved ||
                  (permissionList.kpiResultEntry | hasPermissionId | async) !==
                      true
                : currentTransferAssignee !== 'data_entry' || readOnly
        "
        [(ngModel)]="resultAnalysis"
        [ngModelOptions]="{ standalone: true }"
    ></textarea>

    <div
        *ngIf="
            type === 'kpi-result'
                ? !isApproved &&
                  (permissionList.kpiResultEntry | hasPermissionId | async)
                : currentTransferAssignee === 'data_entry' && !readOnly
        "
        class="mt-2 text-center"
    >
        <ng-container *ngIf="type === 'kpi-result'">
            <ng-container
                *appHasPermissionId="
                    mode === 'new'
                        ? permissionList.kpiResultWrite
                        : permissionList.kpiResultEntry
                "
            >
                <ng-container
                    [ngTemplateOutlet]="buttonTemplate"
                ></ng-container>
            </ng-container>
        </ng-container>

        <ng-container
            *ngIf="type === 'data-entry-response'"
            [ngTemplateOutlet]="buttonTemplate"
        ></ng-container>
    </div>
</form>

<ng-template #buttonTemplate>
    <button type="submit" class="btn-lg btn btn-primary">
        <i class="fa-light fa-save me-2"></i>
        <span>{{ 'translate_save' | translate }}</span>
    </button>
</ng-template>
