import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-content-loading',
    template: `
        <ng-container *ngIf="isLoading; else loadedTemplate">
            <div
                class="font-weight-bold text-center text-lg"
                [ngClass]="{
                    'text-center': isCentered,
                    'font-weight-bold': isBold
                }"
            >
                <app-loading></app-loading>
            </div>
        </ng-container>
        <ng-template #loadedTemplate>
            <ng-content></ng-content>
        </ng-template>
    `,
})
export class ContentLoadingComponent {
    @Input() public isLoading = true;
    @Input() public isBold = true;
    @Input() public isCentered = true;
}
