import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
} from '@angular/core';
import { NotificationService } from 'mnm-webapp';
import { finalize } from 'rxjs/operators';
import { TableController } from '@masar/common/misc/table';
import { Innovation } from '@masar/common/models';
import { SharedInnovationService } from '@masar/shared/services';

@Component({
    selector: 'app-innovation-list',
    templateUrl: 'innovation-list.component.html',
})
export class InnovationListComponent implements OnDestroy {
    @Input() public mode: 'default' | 'selection' | 'linked' = 'default';
    @Input() public tableController: TableController<Innovation, any>;
    @Input() public unLinkingButtonTemplate: TemplateRef<any>;

    @Output() public innovationSelected = new EventEmitter<Innovation>();

    public currentlyDeleting: string[] = [];

    public constructor(
        private innovationService: SharedInnovationService,
        private notificationService: NotificationService
    ) {}

    public delete(item: Innovation): void {
        this.currentlyDeleting.push(item.id);
        this.innovationService
            .delete(item.id)
            .pipe(
                finalize(() => {
                    // remove the deleted item id from the being deleted
                    // list when the deletion is complete.
                    this.currentlyDeleting = this.currentlyDeleting.filter(
                        x => x !== item.id
                    );
                })
            )
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.tableController.filter$.next(false);
            });
    }

    public ngOnDestroy(): void {
        this.tableController.stop();
    }
}
