import { AuthorizeGuard } from './authorize.guard';
import { NotAuthorizeGuard } from './not-authorize.guard';
import { PermissionGuard } from './permission.guard';
import { MandatoryPasswordChangeGuard } from './mandatory-password-change.guard';

export const guardProviders = [
    AuthorizeGuard,
    NotAuthorizeGuard,
    PermissionGuard,
    MandatoryPasswordChangeGuard,
];
