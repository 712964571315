import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-fieldset',
    template: `
        <fieldset class="mb-4 rounded border border-gray-200 bg-gray-100 p-5">
            <legend
                class="
                w-auto
                rounded border
                border-gray-400
                bg-gray-300
                py-1
                px-2
                text-gray-900
            "
            >
                {{ legend }}
            </legend>

            <ng-content></ng-content>
        </fieldset>
    `,
})
export class FieldsetComponent {
    @Input() public legend: string;
}
