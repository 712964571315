import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'mnm-webapp';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
    UserRequest,
    UserRequestComment,
    UserRequestMode,
} from '@masar/common/models';
import { MnmFormState } from '@masar/shared/components';
import { fields } from './fields';
import { NewUserRequestDialogService } from './new-user-request-dialog.service';

@Component({
    selector: 'app-new-user-request-dialog',
    templateUrl: './new-user-request-dialog.component.html',
    providers: [NewUserRequestDialogService],
})
export class NewUserRequestDialogComponent implements OnInit {
    @Input() public mode: UserRequestMode;
    @Input() public itemId: string;
    @Input() public type: string;

    @Output()
    public requestCreated: EventEmitter<UserRequest> =
        new EventEmitter<UserRequest>();

    @Output()
    public requestUpdated: EventEmitter<UserRequest> =
        new EventEmitter<UserRequest>();

    @Output()
    public commentCreated: EventEmitter<UserRequestComment> =
        new EventEmitter<UserRequestComment>();

    @Output()
    public commentUpdated: EventEmitter<UserRequestComment> =
        new EventEmitter<UserRequestComment>();

    public formState: MnmFormState;
    public isSubmitting = false;

    public constructor(
        private newUserRequestDialogService: NewUserRequestDialogService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        fb: FormBuilder
    ) {
        this.formState = new MnmFormState(fields(), fb);
    }

    public ngOnInit(): void {
        this.formState.group.controls['type'].setValue(this.type);
        switch (this.mode) {
            case 'edit_request':
                this.newUserRequestDialogService
                    .get(this.itemId, true)
                    .subscribe(item => this.fillForm(item));
                break;
            case 'edit_comment':
                this.newUserRequestDialogService
                    .getComment(this.itemId)
                    .subscribe(item => this.fillForm(item));
                break;
        }
    }

    public reset(): void {
        this.formState.reset();
        this.itemId = '';
    }

    public submit(): boolean | Promise<boolean> {
        this.formState.setTriedToSubmit();

        if (this.formState.group.invalid) {
            return false;
        }

        this.isSubmitting = true;

        const observable =
            this.mode === 'new_request'
                ? this.newUserRequestDialogService.create(
                      this.formState.group.getRawValue(),
                      this.itemId
                  )
                : this.mode === 'edit_request'
                ? this.newUserRequestDialogService.update(
                      this.formState.group.getRawValue()
                  )
                : this.mode === 'new_comment'
                ? this.newUserRequestDialogService.createComment(
                      this.formState.group.getRawValue(),
                      this.itemId
                  )
                : this.mode === 'edit_comment'
                ? this.newUserRequestDialogService.updateComment(
                      this.formState.group.getRawValue()
                  )
                : of(null);

        return observable
            .pipe(finalize(() => (this.isSubmitting = false)))
            .toPromise()
            .then(item => {
                this.translateService
                    .get('translate_item_added_successfully')
                    .subscribe(str => {
                        this.notificationService.notifySuccess(str);
                    });
                if (this.mode === 'new_request') {
                    this.requestCreated.emit(item);
                } else if (this.mode === 'edit_request') {
                    this.requestUpdated.emit(item);
                } else if (this.mode === 'edit_comment') {
                    this.commentUpdated.emit(item);
                } else if (this.mode === 'new_comment') {
                    this.commentCreated.emit(item);
                }
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    private fillForm(item: UserRequest | UserRequestComment): void {
        for (const key of Object.keys(this.formState.group.controls)) {
            this.formState.group.controls[key].setValue(item[key]);
        }
    }
}
