import {
    Component,
    forwardRef,
    Input,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Item } from '@masar/common/models';
import { Observable } from 'rxjs';
import { ItemTreeCacheService } from './services/item-tree-cache.service';

@Component({
    selector: 'app-item-list-field',
    template: `
        <div class="w-full">
            <div
                *ngFor="let item of itemFields; let idx = index"
                class="mb-1 flex flex-row"
            >
                <app-item-field
                    class="flex-grow"
                    [maxNesting]="maxNesting"
                    [ngModel]="itemFields[idx].value"
                    (ngModelChange)="
                        itemFields[idx].value = $event; reportChange()
                    "
                    [disabled]="isDisabled"
                >
                </app-item-field>

                <button
                    *ngIf="isMulti && !isDisabled"
                    (click)="removeField(idx)"
                    class="btn btn-sm btn-danger me-1"
                >
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </div>

        <div *ngIf="isMulti && !isDisabled" class="mt-3 text-center">
            <button (click)="addNewField()" class="btn btn-primary">
                <i class="fa-light fa-plus"></i>
            </button>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ItemListFieldComponent),
        },
        ItemTreeCacheService,
    ],
})
export class ItemListFieldComponent implements OnInit, ControlValueAccessor {
    @Input() public parentFetcher: (
        childId: string
    ) => Observable<{ parent: Item; children: Item[] }>;
    @Input() public childrenFetcher: (parentId: string) => Observable<Item[]>;

    @Input() public maxNesting: number = -2;
    @Input() public isMulti: boolean = true;

    @Output() public valueChange = new EventEmitter<Item[] | Item>();

    public itemFields: {
        value?: Item;
    }[] = [];
    public isDisabled: boolean = false;

    private onChange: (items: Item[] | Item) => void;

    public constructor(private itemTreeCacheService: ItemTreeCacheService) {
        this.addNewField();
        this.reportChange();
    }

    public ngOnInit(): void {
        this.itemTreeCacheService.setParentFetcher(this.parentFetcher);
        this.itemTreeCacheService.setChildrenFetcher(this.childrenFetcher);
    }

    public writeValue(data: Item[] | Item): void {
        if (data instanceof Array) {
            this.itemFields = data?.map(x => ({ value: x })) || [{}];
        } else if (data) {
            this.itemFields = [{ value: data }];
        } else {
            this.itemFields = [{}];
        }

        if (!this.itemFields || this.itemFields.length === 0) {
            this.addNewField();
            this.reportChange();
        }
    }

    public registerOnChange(fn: (data: Item[] | Item) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(): void {
        return undefined;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public addNewField(): void {
        this.itemFields.push({ value: null });
    }

    public removeField(index: number): void {
        if (this.itemFields.length === 1) {
            return;
        }
        this.itemFields.splice(index, 1);
        this.reportChange();
    }

    public reportChange(): void {
        const data = this.isMulti
            ? this.itemFields.filter(x => x.value).map(x => x.value)
            : this.itemFields[0].value;
        this.valueChange.emit(data);
        if (this.onChange) {
            this.onChange(data);
        }
    }
}
