import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-ring',
    template: `
        <svg [attr.width]="radius * 2" [attr.height]="radius * 2">
            <circle
                class="stroke-primary"
                [attr.stroke-width]="strokeWidth"
                fill="transparent"
                [attr.r]="radius - strokeWidth / 2"
                [attr.cx]="radius"
                [attr.cy]="radius"
                style="opacity: 0.3"
            />
            <circle
                class="stroke-primary"
                [attr.stroke-width]="strokeWidth"
                fill="transparent"
                [attr.r]="radius - strokeWidth / 2"
                [attr.cx]="radius"
                [attr.cy]="radius"
                [style.stroke-dasharray]="circumference + ' ' + circumference"
                [style.stroke-dashoffset]="
                    circumference *
                    (1 - (value > 1 ? 1 : value < 0 ? 0 : value))
                "
                [attr.transform]="'rotate(-90,' + radius + ',' + radius + ')'"
            />
            <text
                [attr.font-size]="textSize + 'px'"
                [attr.text-anchor]="'middle'"
                [attr.x]="radius"
                [attr.y]="radius + textSize / 2"
                class="font-bold"
            >
                {{ this.value * 100 | round }}%
            </text>
        </svg>
    `,
    styles: [':host {display: inline-block}'],
})
export class ProgressRingComponent {
    @Input() public value = 0.25;
    @Input() public radius = 25;
    @Input() public strokeWidth = 1;
    @Input() public textSize = 16;

    public get circumference(): number {
        return 2 * Math.PI * (this.radius - this.strokeWidth / 2);
    }
}
