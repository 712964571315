<div content class="flex flex-col gap-4">
    <app-alert
        *ngIf="!itemId"
        label="translate_evaluation_alert_info_title"
        description="translate_evaluation_alert_info_description"
        mode="info"
    ></app-alert>

    <div
        class="flex flex-col gap-1 rounded border bg-gray-100 p-4"
        *ngIf="!itemId && evaluations.length > 1"
    >
        <label>{{ 'translate_select_the_evaluation' | translate }}</label>
        <ng-select
            [items]="evaluations"
            [ngModel]="selectedEvaluation"
            (ngModelChange)="setSelectedEvaluation($event)"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
        ></ng-select>
    </div>

    <!-- Records -->
    <app-list-loading [items]="item?.records">
        <div class="table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>{{ 'translate_standard' | translate }}</th>
                        <th class="w-80">
                            {{ 'translate_target' | translate }}
                        </th>
                        <th class="w-80">
                            {{ 'translate_value' | translate }}
                        </th>
                        <th class="w-80">
                            {{ 'translate_notes' | translate }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let record of item?.records; let idx = index">
                        <!-- Name -->
                        <td>{{ record.name }}</td>

                        <!-- Target -->
                        <td class="text-center">{{ record.target }}</td>

                        <!-- Value -->
                        <td>
                            <select
                                class="w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                [disabled]="mode === 'view'"
                                [(ngModel)]="item?.records[idx].value"
                                (ngModelChange)="checkForEmptyValues()"
                            >
                                <option [value]="0">{{ 0 }}</option>
                                <option [value]="record.target * 0.5">
                                    {{ record.target * 0.5 }}
                                </option>
                                <option [value]="record.target">
                                    {{ record.target }}
                                </option>
                            </select>
                        </td>

                        <!-- Note -->
                        <td>
                            <textarea
                                class="w-full"
                                [disabled]="mode === 'view'"
                                [(ngModel)]="item?.records[idx].note"
                            ></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </app-list-loading>

    <!-- General notes -->
    <div class="flex flex-col gap-1">
        <label>{{ 'translate_general_notes' | translate }}</label>
        <textarea
            *appWaitUntilLoaded="item"
            [disabled]="mode === 'view'"
            [(ngModel)]="item.note"
        ></textarea>
    </div>

    <!-- Save button -->
    <button
        *ngIf="mode !== 'view' && (selectedEvaluation || itemId)"
        (click)="processUpdate()"
        [disabled]="isSubmitting || hasEmptyValue"
        class="btn btn-primary flex flex-row items-center gap-2 self-center"
    >
        <app-loading-ring *ngIf="isSubmitting"></app-loading-ring>

        <i class="fa fa-save"></i>

        <span>{{ 'translate_save' | translate }}</span>
    </button>
</div>
