import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mnmHttpInterceptorParams } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { environment } from '@masar/env/environment';

@Injectable()
export class ImageApiService {
    public constructor(private httpClient: HttpClient) {}

    public user(userId?: string, employeeNumber?: string): Observable<Blob> {
        return this.httpClient.get(environment.apiUrl + '/image/user', {
            responseType: 'blob',
            params: new HttpParams()
                .append(mnmHttpInterceptorParams.stealth, 'true')
                .append(mnmHttpInterceptorParams.sustainOnNavigation, 'true')
                .append('userId', userId)
                .append('employeeNumber', employeeNumber ? employeeNumber : ''),
        });
    }

    public tournament(tournamentId: string): Observable<Blob> {
        return this.httpClient.get(
            environment.apiUrl + '/image/tournament/' + tournamentId,
            {
                responseType: 'blob',
                params: new HttpParams().append(
                    mnmHttpInterceptorParams.stealth,
                    'true'
                ),
            }
        );
    }
}
