import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { OauthService } from 'mnm-webapp';
import { environment } from '@masar/env/environment';

export interface SignalrMessage {
    type: 'notification' | 'other';
    payload: any;
}

@Injectable()
export class SignalrService {
    private connection: signalR.HubConnection;

    // A subject for publish/subscribe pattern.

    // A subject for general messages.
    private messages$: Subject<SignalrMessage> = null;

    // A subject to monitor the connection status.
    private theStatus$: BehaviorSubject<
        'disconnected' | 'connected' | 'connecting'
    > = null;

    public constructor(private oauthService: OauthService) {
        oauthService.status$.subscribe(() => {
            this.refresh();
        });

        this.start();
    }

    // Starts a new connection: (1) Initialize the subjects,
    // (2) Build the connection object, (3) Add necessary callbacks, and
    // (4) Finally, connect to the SignalR server.
    public start(): void {
        // Initialize the necessary subjects. Those subjects
        // have nothing to do whatsoever with the SignalR connection.
        // If the connection goes down, these subjects will not complete.
        // They are basically used to publish the whatever messages received
        // from the server.
        this.initializeSubjects();

        // Do whatever should happen before starting the connection.
        this.performOnConnecting();

        // Determine whether to perform an authenticated connection or
        // an anonymous connection.
        this.oauthService.userInfo$.pipe(first()).subscribe(info => {
            if (info.isLoggedIn) {
                this.oauthService.auth$.pipe(first()).subscribe(accessToken => {
                    if (!accessToken.isValid) {
                        this.oauthService
                            .refreshAccessToken()
                            .pipe(first())
                            .subscribe(newAccessToken => {
                                this.buildAndStart(newAccessToken.value);
                            });
                    } else {
                        this.buildAndStart(accessToken.value);
                    }
                });
            } else {
                this.buildAndStart();
            }
        });
    }

    // Stops the current connection and restarts the connection
    // again. It does *NOT* interfere with the current subjects.
    public refresh(): void {
        if (this.connection !== null) {
            this.connection.stop();
        }
        this.start();
    }

    // This will stop the connection and complete & nullify all subscriptions,
    // Basically shuts the service off completely.
    public stop(): void {
        this.messages$.complete();
        this.messages$ = null;

        this.theStatus$.complete();
        this.theStatus$ = null;
    }

    // General Messages
    public messages(): Observable<SignalrMessage> {
        return this.messages$.asObservable();
    }

    public get status$(): Observable<
        'disconnected' | 'connected' | 'connecting'
    > {
        return this.theStatus$.asObservable();
    }

    private initializeSubjects(): void {
        if (this.messages$ === null) {
            this.messages$ = new Subject();
        }
        if (this.theStatus$ === null) {
            this.theStatus$ = new BehaviorSubject('disconnected');
        }
    }

    // Builds the connection and starts it.
    private buildAndStart(accessToken: string = ''): void {
        this.connection = new signalR.HubConnectionBuilder()
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.None)
            .withUrl(environment.apiUrl + '/hub/default', {
                accessTokenFactory: () => accessToken,
            })
            .build();

        this.prepareCallbacks();

        this.connection
            .start()
            .then(async () => {
                await this.performOnConnected();
            })
            .catch(error => {
                this.performOnDisconnected();
                this.log(error);
            });
    }

    // Monitors the connection status and the functions that
    // will be called later on by the SignalR server.
    private prepareCallbacks(): void {
        this.connection.onclose(error => {
            this.performOnDisconnected();
            if (error) {
                this.log(error.toString());
                return;
            }
        });

        // cspell:disable-next
        this.connection.onreconnecting(error => {
            if (error) {
                this.performOnDisconnected();
                this.log(error.toString());
                return;
            }
            this.performOnConnecting();
        });

        // cspell:disable-next
        this.connection.onreconnected(async () => {
            await this.performOnConnected();
        });

        this.connection.on('message', message => {
            this.messages$.next(message);
        });
    }

    // Things to do when disconnected.
    private performOnDisconnected(): void {
        this.theStatus$.next('disconnected');
    }

    // Things to do when connecting.
    private performOnConnecting(): void {
        this.theStatus$.next('connecting');
    }

    // things to do once connected
    private async performOnConnected(): Promise<void> {
        this.theStatus$.next('connected');
        await this.restoreState();
    }

    private async restoreState(): Promise<void> {
        return undefined;
    }

    private log(message: string): void {
        console.log(`SignalR: ${message}`);
    }
}
