import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ModalService, NotificationService } from 'mnm-webapp';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ListComponent } from '@masar/pages/innovation/list/list.component';
import { TableController, TableResult } from '@masar/common/misc/table';
import { Innovation } from '@masar/common/models';

@Component({
    selector: 'app-innovation-linker',
    templateUrl: 'innovation-linker.component.html',
    styles: [':host {display: block}'],
})
export class InnovationLinkerComponent implements OnInit, OnDestroy {
    @ViewChild('unLinkingButtonTemplate')
    public unLinkingButtonTemplate: TemplateRef<any>;

    @Input() public listLinkedInnovationCallback: (
        title: string,
        pageNumber: number,
        pageSize: number
    ) => Observable<TableResult<Innovation>>;

    @Input() public linkingCallback: (itemId: string) => Observable<string>;
    @Input() public unlinkingCallback: (fileId: string) => Observable<string>;

    @Input() public requiredPermissionForLinking: string;
    @Input() public requiredPermissionForUnlinking: string;

    public linkedInnovations: {
        currentlyUnlinking: string[];
        isLinking: boolean;
        tableController: TableController<Innovation, { title: string }>;
    } = {
        currentlyUnlinking: [],
        isLinking: false,
        tableController: undefined,
    };

    public constructor(
        private modalService: ModalService,
        private notificationService: NotificationService
    ) {}

    public ngOnInit(): void {
        this.linkedInnovations.tableController = new TableController<
            Innovation,
            { title: string }
        >(
            filter =>
                this.listLinkedInnovationCallback(
                    filter.data.title,
                    filter.pageNumber,
                    filter.pageSize
                ),
            { data: { title: '' } }
        );

        this.linkedInnovations.tableController.start();
    }

    public ngOnDestroy(): void {
        this.linkedInnovations.tableController.stop();
    }

    public async showInnovationDialog(): Promise<void> {
        let subscription: Subscription;

        // FIXME: Move this list component outside innovation module
        const component = await this.modalService.show(ListComponent, {
            onDismiss: () => subscription.unsubscribe(),
        });

        component.mode = 'selection';
        subscription = component.innovationSelected.subscribe(innovation => {
            this.linkInnovation(innovation.id);
            this.modalService.dismiss(component);
        });
    }

    public linkInnovation(innovationId: string): void {
        this.linkedInnovations.isLinking = true;

        this.linkingCallback(innovationId)
            .pipe(finalize(() => (this.linkedInnovations.isLinking = false)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.linkedInnovations.tableController.filter$.next(true);
            });
    }

    public unlinkInnovation(innovationId: string): void {
        this.linkedInnovations.currentlyUnlinking.push(innovationId);

        this.unlinkingCallback(innovationId)
            .pipe(
                finalize(
                    () =>
                        (this.linkedInnovations.currentlyUnlinking =
                            this.linkedInnovations.currentlyUnlinking.filter(
                                x => x !== innovationId
                            ))
                )
            )
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.linkedInnovations.tableController.filter$.next(true);
            });
    }
}
