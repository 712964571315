import { Inject, Injectable } from '@angular/core';
import { ThemeSetting } from '@masar/common/models';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ThemeService {
    public constructor(@Inject(DOCUMENT) private document: Document) {}

    public apply(theme: ThemeSetting): void {
        const levels = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

        levels.forEach((level, index) => {
            this.document.documentElement.style.setProperty(
                `--primary-${level}`,
                theme.primary[index]
            );
            this.document.documentElement.style.setProperty(
                `--secondary-${level}`,
                theme.secondary[index]
            );
        });
    }
}
