import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { YearService } from '../services/year.service';

@Injectable()
export class YearInterceptor implements HttpInterceptor {
    public constructor(private yearService: YearService) {}

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const year = this.yearService.get();

        req = req.clone({
            headers: req.headers.set('year', `${year}`),
        });

        return next.handle(req);
    }
}
