import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class EasterService {
    // cspell:ignore estrmsr
    private static shortcut = 'estrmsr';
    private static collectionIntervalInMs = 1000;
    private lastKeyUpTime = 0;
    private callback: any;
    private userInput = '';
    private isEasterEnabled = false;

    public constructor(@Inject(DOCUMENT) private document: Document) {}

    public start(): void {
        this.callback = this.collectUserInput.bind(this);
        this.document.addEventListener('keyup', this.callback);
    }

    public stop(): void {
        this.document.removeEventListener('keyup', this.callback);
    }

    public get isEnabled(): boolean {
        return this.isEasterEnabled;
    }

    private collectUserInput(event: KeyboardEvent): void {
        const currentTime = Date.now();
        const delta = currentTime - this.lastKeyUpTime;
        this.lastKeyUpTime = currentTime;

        if (delta >= EasterService.collectionIntervalInMs) {
            this.userInput = '';
        }

        this.userInput += event.key;

        if (!this.userInput.includes(EasterService.shortcut)) return;

        this.userInput = '';
        this.toggle();
    }

    private toggle(): void {
        this.isEasterEnabled = !this.isEasterEnabled;
    }
}
