import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@masar/env/environment';
import { TableResult } from '@masar/common/misc/table';
import { Kpi, KpiStatus } from '@masar/common/models';

@Injectable()
export class SharedKpiService {
    public constructor(private httpClient: HttpClient) {}

    public list(
        keyword: string,
        tagIds: string[],
        departmentIds: string[],
        goalIds: string[],
        cycles: string[],
        typeIds: string[],
        // year: number,
        progresses: string[],
        kpiNumber: string,
        status: KpiStatus = 'active',
        hasBenchmarks: -1 | 0 | 1,
        standardIds: string[],
        evaluationStatus: -1 | 0 | 1,
        evaluationScoreBandIds: string[],
        pageNumber: number,
        pageSize: number = 20
    ): Observable<TableResult<Kpi>> {
        let params = new HttpParams();
        params = params.append('keyword', keyword);
        tagIds.forEach(item => (params = params.append('tagIds', item)));
        departmentIds.forEach(
            item => (params = params.append('departmentIds', item))
        );
        goalIds.forEach(item => (params = params.append('goalIds', item)));
        cycles.forEach(item => (params = params.append('cycles', item)));
        typeIds.forEach(item => (params = params.append('typeIds', item)));

        // params = params.append('year', `${year}`);

        progresses.forEach(
            item => (params = params.append('progresses', item))
        );

        standardIds?.forEach(
            item => (params = params.append('standardIds', item))
        );

        evaluationScoreBandIds?.forEach(
            item => (params = params.append('evaluationScoreBandIds', item))
        );

        params = params.append('kpiNumber', kpiNumber);
        params = params.append('status', status);
        params = params.append('hasBenchmarks', `${hasBenchmarks}`);
        params = params.append('evaluationStatus', `${evaluationStatus}`);
        params = params.append('pageNumber', `${pageNumber}`);
        params = params.append('pageSize', `${pageSize}`);

        return this.httpClient
            .get<Result<TableResult<Kpi>>>(environment.apiUrl + '/kpi', {
                params,
            })
            .pipe(map(result => result.extra));
    }
}
